// eslint-disable-next-line banned-modules
'use strict';

import BaseModel from '@/classes/base.model';
import BaseCollection from '@/classes/base.collection';
import TravellerModel from '@/blocks/elements/b-train/b-train-traveller/model';

const BookingModel = BaseModel.extend({

	travellersCount: {
		ADULT: 0,
		CHILD: 0,
		INFANT: 0,
		UNKNOWN: 0,
	},

	defaults() {
		return {
			customer: new BaseModel({
				phone: {
					cityCode: '',
					countryCode: STATE.getDefaultCountryCode(),
					number: '',
				},
			}),
			travellers: new BaseCollection({
				model: TravellerModel,
			}),
		};
	},

	toJSON() {
		const data = BaseModel.prototype.toJSON.call(this);
		if (data.routeInfoBack === null) {
			delete data.routeInfoBack;
		}

		// Fix IBECORP-1864
		_.each(data.travellers, (traveller, i) => {
			traveller.passport.withoutMiddleName = (traveller.withoutMiddleName === true);
			if (traveller.passport != null && _.isObject(traveller.passport.type)) {
				traveller.passport.type.uid = traveller.passport.type.uid.split('.')[0];
			}

			if (_.isObject(traveller.phone) && (!traveller.phone.number || !traveller.phone.cityCode)) {
				delete traveller.phone;
			}

			delete traveller.withoutMiddleName;
			delete traveller.deletable;
			delete traveller[`selectedTariff${i}`];
		});

		if (!_.isEmpty(data.customer)) {
			if (data.customer.overrideCustomer === true) {
				data.overrideCustomer = true;
			}

			delete data.customer.disabled;
			delete data.customer.overrideCustomer;
		}

		return {
			parameters: data,
		};
	},

	parse(resp) {
		let temp = resp;
		while (temp && temp.parameters) {
			temp = temp.parameters;
		}
		const result = Object.assign(
			{},
			temp,
			{
				travellers: new BaseCollection(temp.travellers, {
					model: TravellerModel,
					parse: true,
				}),
			},
		);
		this.countTravellers(result.travellers);
		return result;
	},

	countTravellers(travellers) {
		const travellersCount = {
			ADULT: 0,
			CHILD: 0,
			INFANT: 0,
			UNKNOWN: 0,
		};
		if (travellers != null) {
			travellers.forEach(traveller => {
				if (traveller.get('type') != null) {
					switch (traveller.get('type').uid) {
						case 'ADULT': {
							travellersCount.ADULT++;
							break;
						}
						case 'CHILD': {
							travellersCount.CHILD++;
							break;
						}
						case 'INFANT': {
							travellersCount.INFANT++;
							break;
						}
						default:
							travellersCount.UNKNOWN++;
							break;
					}
				}
			});
		}
		this.travellersCount = travellersCount;
	},

	isCanAddInfant(bookingInfo = {}) {
		const carriagies = _.compact([
			bookingInfo.routeInfo && bookingInfo.routeInfo.carriage,
			bookingInfo.routeInfoBack && bookingInfo.routeInfoBack.carriage]);

		const totalTravellersCount = _.reduce(_.values(this.travellersCount), (sum, t) => sum + t, 0);
		const extraChildOnly = _.some(carriagies, (c) => c.extraChildOnly === true);
		const result = totalTravellersCount < 4;

		if (_.every(carriagies, (c) => _.find(c.availableTariffs, (t) => t != null && t.uid === 'BABY') == null)) {
			return false;
		}

		if (extraChildOnly === true) {
			return result && this.travellersCount.ADULT >= this.travellersCount.INFANT;
		}

		return result && this.travellersCount.ADULT > this.travellersCount.INFANT;
	},

	addTraveller(traveller) {
		const travellerModel = new TravellerModel(_.extend({
			deletable: true,
		}, traveller));

		this.get('travellers').add(travellerModel);
		this.countTravellers(this.get('travellers'));
		return travellerModel;
	},

	handleValidation(...args) {
		if (args[0].travellers) {
			_.each(args[0].travellers, (t) => {
				if (t.passport != null) {
					if (t.passport.birthDate != null) {
						// t.birthDate = t.passport.birthDate;
						// delete t.passport.birthDate;
					}
				}
			});
		}

		BaseModel.prototype.handleValidation.apply(this, args);
	},

});

export default BookingModel;
