// eslint-disable-next-line banned-modules
'use strict';

import './style.less';
import BaseView from '@/classes/base.view';
import axios from 'axios';
import SelectVariantView from './select';
import template from './template.ejs';
import CarriagesView from './b-train-carriages/index';
import TrainRouteView from './b-train-route/index';
import MobileOfferPreview from './b-mobile-offer-preview';
import SeatsView from './b-train-seats/index';
import SeatsWidget from '@/widgets/w-seats/index';
import SeatsModel from '@/widgets/w-seats/seat-model';
import Backbone from 'backbone';

import $ from 'jquery';

export default BaseView.extend({

	template,

	seatsMap: null,

	buttonsView: null,
	selectedVariant: null,
	selectedGender: null,
	specialServiceClasses: null,

	events: {
		'click .b-route__info-route-link': 'showTrainRoute',
	},

	ui: {
		seats: '.b-train__carriage-seats',
		selectedVariant: '.b-carriage__selected-variant',
	},

	initialize(options) {
		this.approvalAllowed = this.options.approvalAllowed;
		this.issueAllowed = this.options.issueAllowed;

		this.parent = options.parent;
		this.seatsMap = {};
		this.selectedVariant = {
			carriageView: null,
			carriage: null,
			numbers: [],
		};
		this.railPricingRequestMap = {};

		this.seatsAmount = this.parent.seatsAmount;
		this.passengersTypes = this.parent.passengersTypes;

		this.model.get('_train').carriageInfosLength = this.model.get('carriageInfos').length;
		this.currencyCode = options.currencyCode;

		this.specialServiceClasses = options.specialServiceClasses;
		this.selectedCarriage = options.selectedCarriage;

		try {
			this.render();
			this.renderCarriages();
		} catch (e) {
			if (window.ErrorHandler != null) {
				const error = window.ErrorHandler.getLineException(e.stack);
				window.ErrorHandler.callbackErrorHandler(_.extend({}, error, {
					message: e.message,
					error: {
						stack: e.stack,
						isSilentError: true,
					},
				}));
			}
			return;
		}

		this.listenTo(this.model, 'change:visibility', (model, value) => {
			if (value) {
				this.$el.addClass('b-train-ticket__visible');
			} else {
				this.$el.removeClass('b-train-ticket__visible');
			}
		});
		this.listenTo(this.model, 'change:renderCarriages', () => {
			this.renderCarriages();

			if (this.openedCarriageType != null) {
				if (!_.some(this.model.get('carriageInfos'), (el) => el.type != null && el.type.uid === this.openedCarriageType)) {
					this.toggleMapContainer(false);
				} else {
					this.$el.find(`.b-train__carriages .b-train__carriages-type[data-type="${this.openedCarriageType}"]`).addClass('open');
				}
			}
		});

		this.listenTo(this.model, 'change:_compartmentRequest', () => {
			if (this.model.get('_compartmentRequest')) {
				const currentSeat = this.selectedVariant.numbers[0];
				const availableSeats = currentSeat.coupeSeats.map((seat) => {
					return {...currentSeat, number: seat.number.number, position: seat.type};
				});
				this.selectedVariant.numbers = availableSeats;
				this.selectedVariant.carriageView.numbers = availableSeats;
				this.selectedVariant.fullCompartment = this.model.get('_compartmentRequest');

				const maps = Object.values(this.seatsMap).filter((map) => {
					return map.view.openedMap !== null;
				});

				if (maps.length === 1) {
					const map = maps[0].view;

					const mapView = map.maps[map.openedMap.groupId][map.openedMap.mapId];
					mapView.updateAvailablePlaces(availableSeats[0]);
					this.selectPlace(this.selectedVariant);
				} else {
					throw new Error('Wrong array length');
				}

				$('.b-carriage__selector-seats').empty().hide();
			}
		});

		/* select variant buttons */
		this.buttonsView = new SelectVariantView({
			parent: this,
			selectedVariant: this.selectedVariant,
			seatsAmount: this.seatsAmount,
			approvalAllowed: this.approvalAllowed,
			issueAllowed : this.issueAllowed,
		});
		this.ui.selectedVariant.append(this.buttonsView.$el);

		if (this.selectedCarriage) {
			this.test(this.selectedCarriage.uid, this.selectedCarriage.type, this.selectedCarriage.flag);
		}
	},

	adjustMobileTemplate(matches) {
		const $tooltips = this.$('[data-toggle="tooltip"]');

		if (matches) {
			_.each($tooltips, (el) => el && this.$(el).tooltip('disable'));
			if (this.$('.b-train__carriage.open').length > 0 && this.lastOpenedCarriages != null) {
				this.$('.js-select-carriage.open').removeClass('open').click();
			}
		} else {
			_.each($tooltips, (el) => el && this.$(el).tooltip('enable'));
			if (this.mobileOfferPreview != null && this.mobileOfferPreview.visibility === true) this.mobileOfferPreview.closePreview();
		}
	},

	renderCarriages() {
		if (this.carriagesView != null) {
			this.carriagesView.remove();
		}
		this.carriagesView = new CarriagesView({
			parent: this,
			currencyCode: this.currencyCode,
			specialServiceClasses: this.specialServiceClasses,
		});
		this.model.get('_train').carriageInfosLength = this.model.get('carriageInfos').length;
		this.$('.b-train-ticket').after(this.carriagesView.$el.addClass('b-train-ticket__carriages'));
	},

	toggleMapContainer(open, type) {
		const $seats = this.$el.find('.b-train__carriage-seats');

		if (open) {
			$seats.addClass('open');
		} else {
			$seats.removeClass('open');
		}
		this.openedCarriageType = type;
	},

	hideAllMapContainers() {
		return new Promise((resolve) => {
			this.options.parent.trainViews.forEach((trainView) => {
				const trainViewKeys = Object.keys(trainView.seatsMap);
				if (!trainViewKeys.length) {
					resolve();
					return;
				}
				trainViewKeys.forEach((key, i) => {
					const map = trainView.seatsMap[key];
					map.view.showToggleMap({}, false, true).then(() => {
						if (i === trainViewKeys.length - 1) {
							resolve();
						}
					});
				});
			});
		});
	},

	getSelectedVariantNumbersCarPlaceDataPrices() {
		return this.selectedVariant?.carriage &&
			this.selectedVariant?.carriage.carPlaceData.reduce((arr, item) => {
				let include = false;
				_.each(this.selectedVariant.numbers, (n) => {
					if (item.availableSeats.includes(n.number)) include = true;
				});

				if (include) {
					arr.push(item.price.price);
				}
				return arr;
			}, []);
	},

	railPricingRequest($container, noSeatSelection = false) {
		const number = this.model.get('number');
		const seatsRequest = this.buildSeatsRequest(
			this.selectedVariant.numbers,
			noSeatSelection,
			this.selectedVariant?.carriage?.beddingEnabled,
		);

		if (this.selectedVariant.numbers.length < seatsRequest.seatsAmount) {
			$container.hide();
			return Promise.resolve();
		}

		const mapKey = this.getSelectedVariantNumbersCarPlaceDataPrices().join('_');
		if (!_.isEmpty(this.railPricingRequestMap[`${number}_${(this.selectedVariant.carriage || {}).number}_${mapKey}`])) {
			return Promise.resolve(this.railPricingRequestMap[`${number}_${(this.selectedVariant.carriage || {}).number}_${mapKey}`]);
		}

		STATE.showLoader();

		const parameters = {
			parameters: {
				routeInfo: {
					train: this.model.toJSON(),
					carriage: this.selectedVariant?.carriage,
					seatsRequest,
				},
			},
		};

		const travellers = [];

		const passengers = STORE.get(
			STATE.ROUTES.MAIN_SEARCH).passengers;

		Object.keys(this.passengersTypes).forEach(k => {
			for (let i = 0; i < this.passengersTypes[k]; i++) {
				const pass = passengers[i];
				if (k === 'ADULT') {
					travellers.push({
						type: {
							uid: 'ADULT',
						},
						tariff: 'FULL',
						uid: pass ? pass.uid : '',
					});
				} else if (k === 'CHILD') {
					travellers.push({
						type: {
							uid: 'CHILD',
						},
						tariff: 'CHILD',
						uid: pass ? pass.uid : '',
					});
				} else if (k === 'INFANT') {
					travellers.push({
						type: {
							uid: 'INFANT',
						},
						tariff: 'BABY',
						uid: pass ? pass.uid : '',
					});
				}
			}
		});

		parameters.parameters.travellers = travellers;

		const railPricing = STORE.get(STATE.ROUTES.RAILWAYS_TICKETS_PRICING);
		const isSecondRoute = Backbone.history.getFragment() === STATE.ROUTES.RAILWAYS_TICKETS_2;

		if (railPricing != null && isSecondRoute) {
			parameters.parameters.routeInfoBack = parameters.parameters.routeInfo;
			parameters.parameters.routeInfo = railPricing.request.routeInfo;
		}

		return axios.post('/midoffice/ibecorp-b2b/rail/pricing', parameters)
			.then(res => {
				const result = res.data.result;
				STATE.hideLoader();
				this.railPricingRequestMap[`${number}_${(this.selectedVariant.carriage || {}).number}_${mapKey}`] = result;

				STORE.set(STATE.ROUTES.RAILWAYS_TICKETS_PRICING, {request: parameters.parameters, response: result}, 4400);

				return result;
			});
	},

	select(uid, type, groupByServiceClass) {
		return this.hideAllMapContainers().then(() => {
			STATE.showLoader();
			const request = {
				parameters: {
					carriageVariantUid: uid,
					train: this.model.toJSON(),
				},
			};
			const gdsAccount = this.model.get('gdsAccount');
			const gds = this.model.get('gds');
			const isMobile = STATE.checkViewport('(max-width: 768px)');

			return new Promise(resolve => {
				let seatsMapKey = uid;
				if (groupByServiceClass) {
					seatsMapKey = `${uid}_${type}`;
				}
				if (seatsMapKey in this.seatsMap || (groupByServiceClass && Object.keys(this.seatsMap).length > 0)) {
					resolve({
						carriages: this.seatsMap[seatsMapKey],
						key: seatsMapKey,
					});
				} else {
					axios.post('/midoffice/ibecorp-b2b/rail/train/seats', request).then(result => {
						let filtered = false;
						let carriages = result.data.result.carriages;
						const currencyCode = result.data.result.currencyCode;

						/* round trip filter for back carriages (amount limits) */
						if (this.seatsAmount != null && this.seatsAmount > 0) {
							carriages = carriages.filter(c => {
								let include = true;
								if (c.seatsAvailable.length < this.seatsAmount) {
									include = false;
								}
								if (c.seatsAvailable.length === 0 && c.carriageCardId === 'EMPTY_MAP') {
									include = true;
								}
								if (c.placeQuantity > 0) {
									include = true;
									if (c.seatsAvailable.length === 0) {
										c.noSeatSelection = true;
									}
								}
								return include;
							});
							if (carriages.length === 0) {
								filtered = true;
							}
						}
						/* EOF filter */
						carriages.forEach((c, i) => {
							c._id = i;
						});

						if (groupByServiceClass) {
							const carriagesByServiceClass = carriages.reduce((map, c) => {
								const key = c.serviceClass && c.serviceClass.uid;
								if (!map[key]) {
									map[key] = [];
								}
								map[key].push(c);
								return map;
							}, {});

							Object.keys(carriagesByServiceClass).forEach(key => {
								seatsMapKey = `${uid}_${key}`;
								this.seatsMap[seatsMapKey] = {
									injected: false,
									view: new SeatsView({
										parent: this,
										gdsAccount,
										gds,
										carriages: carriagesByServiceClass[key],
										currencyCode,
										filtered,
									}),
								};
							});
							seatsMapKey = `${uid}_${type}`;
						} else {
							this.seatsMap[seatsMapKey] = {
								injected: false,
								view: new SeatsView({
									parent: this,
									gdsAccount,
									gds,
									carriages,
									currencyCode,
									filtered,
								}),
							};
						}

						// Remove seats with higher price, IBECORP-3262
						if (this.filterCarriages) {
							this.filterCarriages(this.seatsMap[seatsMapKey]);
						}

						resolve({
							carriages: this.seatsMap[seatsMapKey],
							key: seatsMapKey,
						});
					});
				}
			}).then((data => {
				const carriages = data.carriages;
				this.lastOpenedCarriages = data;

				STATE.hideLoader();

				if (carriages != null) {
					this.toggleMapContainer(true, type);
					this.ui.seats.find('.b-train__seats').hide();
					if (!carriages.injected) {
						if (isMobile) {
							this.ui.seats.find('.b-train__seats').show();
							this.showMobileOfferPreview(carriages, data.key);
						} else {
							this.ui.seats.append(carriages.view.$el);
						}
						carriages.injected = true;
					} else {
						carriages.view.$el.show();
						if (isMobile) {
							this.ui.seats.find('.b-train__seats').show();
							this.showMobileOfferPreview(carriages, data.key);
						}
					}
				} else {
					const $carriage = this.$el.find(`.b-train__carriages [data-uid="${uid}"][data-type="${type}"]`);
					$carriage.addClass('sold_out');
					$carriage.removeClass('js-select-carriage');
					$carriage.removeClass('open');
					$carriage.find('.carriage-type__quantity').html(L10N.get('trains.noSeats'));
					$carriage.find('.carriage-type__price').html('');
					this.ui.seats.find('.b-train__seats').hide();
					const noTicketsPopup = new Widgets.Popup({
						content: L10N.get('trains.noSeatsFound'),
						type: 'danger',
						actions: [{
							label: L10N.get('cabinet.orders.close'),
							action: () => {
								noTicketsPopup.hide();
							},
						}],
						onClose: () => {
							noTicketsPopup.hide();
						},
					});
					noTicketsPopup.show();
				}
			}));
		});
	},

	unSelectPlace(data) {
		if (data != null && data.carriageView != null) {
			data.carriageView.updateMap();
			this.selectPlace(null);
		}
	},

	selectPlace(data, noSeatSelection = false) {
		this.selectedVariant = _.extend({
			carriageView: null,
			carriage: null,
			numbers: [],

		}, data != null ? data : {}, {});
		let $container = this.ui.selectedVariant;
		const $selectedCarriage = this.$selectedCarriage ? this.$selectedCarriage : this.$('.b-carriage-maps__container.open');
		const isMobile = STATE.checkViewport('(max-width: 768px)');

		if ($selectedCarriage.length > 0) {
			$container = $selectedCarriage.parent().find('.b-carriage__selected-variant');
		}

		if (this.selectedVariant.carriageView != null &&
			this.selectedVariant.numbers != null &&
			this.selectedVariant.numbers.length > 0) {
			this.showSelectorSeats(isMobile);
			this.railPricingRequest($container, noSeatSelection).then((res) => {
				if (res == null) return;
				this.selectedVariant.railPricing = res;
				this.buttonsView.update(this.selectedVariant, isMobile);

				$container.html(this.buttonsView.$el);
				$container.show();

				const firstNumber = this.selectedVariant.numbers[0];
				const sameCoupe = this.selectedVariant.numbers.every((number) => {
					return number.coupe && firstNumber.coupe && (number.coupe === firstNumber.coupe);
				});
				if (sameCoupe && this.seatsAmount < 4 &&
					(firstNumber && firstNumber.coupeSeats && firstNumber.coupeSize > 1) && firstNumber.coupeSize === firstNumber.coupeSeats.length) {
					if (!this.model.get('_compartmentRequest')) {
						this.showToggleCompartment();
					}
				} else {
					$('.b-carriage__selector-compartment').empty();
					this.model.set('_compartmentRequest', false);
				}
				if (isMobile && this.mobileOfferPreview) {
					this.mobileOfferPreview.updateCarInfo(this.selectedVariant.carriage);
					this.mobileOfferPreview.renderButtonsView(this.buttonsView.$el);
				}
			});
		} else if (noSeatSelection && this.selectedVariant.carriageView != null) {
			this.railPricingRequest($container, noSeatSelection).then((res) => {
				if (res == null) return;
				this.selectedVariant.railPricing = res;
				this.buttonsView.update(this.selectedVariant, isMobile, noSeatSelection);

				$container.html(this.buttonsView.$el);
				$container.show();
			});
		} else {
			$('.b-carriage__selector-compartment').empty();
			this.model.set('_compartmentRequest', false);
			$('.b-carriage__selector-seats').empty().hide();
			if (isMobile && this.mobileOfferPreview) this.mobileOfferPreview.clearCarInfo();
			$container.hide();
		}
	},

	showToggleCompartment(selected, isMobile) {
		const $container = $('.b-carriage__selector-compartment');
		$container.empty();

		this.model.set('_compartmentRequest', selected);
		const options = {
			bindingProperty: '_compartmentRequest',
			label: L10N.get('trains.toBookCompartment'),
		};
		const toggle = new Widgets.ToggleButton(options);
		if (isMobile && this.mobileOfferPreview) this.mobileOfferPreview.renderCompartmentToggle(options, this.model);
		toggle.applyBinding(this.model);
		$container.html(toggle.$el);
	},

	showSelectorSeats(isMobile) {
		const { carriage, numbers } = this.selectedVariant;
		const $container = $('.b-carriage__selector-seats');
		$container.empty();

		if (!carriage || !['P', 'K'].includes(carriage.type.uid) || this.seatsAmount > _.size(numbers)) {
			this.model.set('_seatsRequest', new SeatsModel({
				UPPER: null,
				LOWER: null,
			}));
			return;
		}

		if (!this.$el.find('.b-message-warning') || this.$el.find('.b-message-warning').length === 0) {
			this.$el.find('.b-map-description__messages').append(`<div class="b-message b-message-warning">
				${L10N.get('trains.seatStatus.PREFERENCES_SEATS')}
			</div>`);
			if (isMobile && this.mobileOfferPreview) this.mobileOfferPreview.renderWarning();
		}

		$container.show();
		const seatsModel = new SeatsModel();
		//UTS-518 по дефолту места не выбраны
		// let upper = _.filter(numbers, (n) => n.position != null && n.position === 'upper').length;

		// if (carriage.carPlaceData != null) {
		// 	const { carPlaceData } = carriage;
		// 	const upperData = _.find(carPlaceData, (c) => c.carPlaceType && c.carPlaceType.uid === 'UPPER');

		// 	if (upperData) {
		// 		const { availableSeats } = upperData;
		// 		upper = _.reduce(numbers, (acc, n) => {
		// 			acc += (availableSeats.includes(n.number) ? 1 : 0);
		// 			return acc;
		// 		}, 0);
		// 	}
		// }

		// seatsModel.set('UPPER', upper > this.seatsAmount ? this.seatsAmount : upper);
		// seatsModel.set('LOWER', upper > this.seatsAmount ? 0 : (this.seatsAmount - upper));

		this.model.set('_seatsRequest', seatsModel);

		const widgetOptions = {
			bindingProperty: '_seatsRequest',
			maxSeats: this.seatsAmount,
			isSapsan: carriage ? carriage.carriageCardId.includes('SAPSAN') : false,
			numbers,
		};
		const widget = new SeatsWidget(widgetOptions);

		widget.applyBinding(this.model);
		$container.html(widget.$el);
		if (isMobile && this.mobileOfferPreview) this.mobileOfferPreview.renderSeatsSelect(widgetOptions, this.model);
	},

	buildSeatsRequest(numbers, noSeatSelection = false, withBed) {
		// TODO: correct data
		if (noSeatSelection) {
			return {
				range: {
					first: null,
					second: null,
				},
				amount: {
					lower: 0,
					upper: 0,
				},
				withBed: true,
				numbers: [],
				oneKupeAllSeats: true,
				passengersTypes: this.passengersTypes,
			};
		}

		const numbersMap = numbers.map(n => parseInt(n.number, 10));
		const lowerByNumbers = numbers.filter((number) => {
			return number.position === 'lower';
		});
		const upperByNumbers = numbers.filter((number) => {
			return number.position === 'upper';
		});
		const seatsModel = this.model.get('_seatsRequest');

		const compartmentModel = this.model.get('_compartmentRequest');
		const coupeSize = numbers[0].coupeSize;

		const seatsRequest = {
			range: {
				first: Math.min(...numbersMap),
				second: Math.max(...numbersMap),
			},
			amount: compartmentModel ? {
				lower: lowerByNumbers && lowerByNumbers.length > 0 ? lowerByNumbers.length : null,
				upper: upperByNumbers && upperByNumbers.length ? upperByNumbers.length : null,
			} : {
				lower: seatsModel.get('LOWER') ? seatsModel.get('LOWER') : null,
				upper: seatsModel.get('UPPER') ? seatsModel.get('UPPER') : null,
			},
			seatsAmount: compartmentModel ? coupeSize : this.seatsAmount,
			passengersTypes: this.passengersTypes,
		};

		if (withBed !== undefined) {
			seatsRequest.withBed = withBed;
		}

		if (this.getSelectedGender() != null) {
			Object.assign(seatsRequest, {
				gender: this.getSelectedGender(),
			});
		}
		return seatsRequest;
	},

	setSelectedGender(value) {
		this.selectedGender = value;
	},

	getSelectedGender() {
		return this.selectedGender;
	},

	addOffer(e, data, totalPrice) {
		const routeInfo = {
			train: this.model.toJSON(),
			carriage: data.carriage,
			seatsRequest: this.buildSeatsRequest(data.numbers, data?.carriage.noSeatSelection),
		};

		if (this.options.parent != null && _.isFunction(this.options.parent.addOffer)) {
			this.options.parent.addOffer.call(this.options.parent, e, {
				routeInfo,
				token: data?.carriage.token,
				totalPrice,
			});
		}
	},

	process(data) {
		const routeInfo = {
			train: this.model.toJSON(),
			carriage: data?.carriage,
			seatsRequest: this.buildSeatsRequest(data.numbers, data?.carriage.noSeatSelection),
		};
		this.parent.process(routeInfo);
	},

	showTrainRoute(e) {
		if (e) {
			e.preventDefault();
		}
		if (this.trainRouteView != null) {
			this.trainRouteView.remove();
		}
		this.trainRouteView = new TrainRouteView({
			train: this.model.toJSON(),
		});
	},

	openCarriagesByDefault(uid, type) {
		const groupByServiceClass = this.model.get('groupByServiceClass');

		this.select(uid, type, groupByServiceClass).then(() => {
			const $currentTarget = $(`[data-uid=${uid}]`).first();
			if (!$currentTarget.hasClass('sold_out')) {
				$currentTarget.addClass('open');
			}
		});
	},

	showMobileOfferPreview(carriages, carriageUid) {
		const lLayout = $('.l-layout');

		if (!lLayout.hasClass('show-offer-preview')) {
			this.mobileOfferPreview = new MobileOfferPreview(Object.assign({}, {
				...this.options,
				parent: this,
				pageView: this.options.parent,
				ticketYPosition: window.pageYOffset,
				carriagesView: carriages.view,
				carriagesContainer: this.ui.seats,
				approvalAllowed: this.options.approvalAllowed,
				issueAllowed: this.options.issueAllowed,
				carriageUid,
			}));
			this.options.parent.ui.sidebar.find('.l-offer-preview-container').empty().append(this.mobileOfferPreview.render().$el);
			lLayout.addClass('show-offer-preview');
			STATE.setIsMobileModalOpened(true);
			this.mobileOfferPreview.showFirstGroup();
		}
	},

});
