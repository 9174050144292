module.exports = function(obj) {
obj || (obj = {});
var __t, __p = '', __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
with (obj) {


    var contactPersonEditDeny = !contactPersonEditGranted || contactPersonDisableFreeTextInput;
	var disabledClass = contactPersonEditDeny ? "disabled" : "";
    var langRegistrationFullName = langRegistrationFullName || {};
    var useTravellerNameFromDocuments = useTravellerNameFromDocuments || false;
    var useLangTwoLocales = useLangTwoLocales || false;

	var l10nPassportNumber = L10N.get("bookingForm.passportNumber");
;
__p += '\r\n\r\n<div>\r\n	<div class="b-traveller" data-traveller="' +
((__t = ( index )) == null ? '' : __t) +
'">\r\n		<header class="b-traveller__title collapse--with-anchor mobile-collapse" data-toggle="collapse" data-target=".toggle-traveller-' +
((__t = ( index )) == null ? '' : __t) +
'">\r\n			<div class="b-traveller-number">\r\n				' +
((__t = ( index + 1 )) == null ? '' : __t) +
'-' +
((__t = ( L10N.get('bookingForm.n') )) == null ? '' : __t) +
' ' +
((__t = ( L10N.get('passengers.passengerTitle') )) == null ? '' : __t) +
'\r\n			</div>\r\n			<div class="b-header-border"></div>\r\n			<div class="b-traveller-tariff selected">\r\n				' +
((__t = ( passengerType.caption )) == null ? '' : __t) +
'\r\n			</div>\r\n\r\n			';
 if (isAdult) { ;
__p += '\r\n				<span class="b-traveller__title-contact">\r\n					<span class="js-widget"  onclick=\'return new Widgets.Checkbox({ bindingProperty: "isContact", classes: "' + disabledClass + '", label: L10N.get("bookingForm.travellerAsContact"), placeholder: L10N.get("bookingForm.activePassengerPlaceholder"), disabled: "' + contactPersonEditDeny + '" })\'></span>\r\n				</span>\r\n			';
 } ;
__p += '\r\n		</header>\r\n		<div class="b-traveller-info toggle-traveller-' +
((__t = ( index )) == null ? '' : __t) +
' show">\r\n			<div class="b-traveller-info_language"></div>\r\n			<div class="l-grid-container">\r\n				<div class="l-grid-layout-33 js-lastName-container"></div>\r\n				<div class="l-grid-layout-33 js-firstName-container"></div>\r\n				<div class="l-grid-layout-33 js-middleName-container"></div>\r\n			</div>\r\n			<div class="l-grid-container">\r\n				<div class="l-grid-layout-33">\r\n					<span class="js-widget"  onclick=\'return new Widgets.Gender({bindingProperty: "passport.gender" })\'></span>\r\n				</div>\r\n				<div class="l-grid-layout-33 p-search__options-date-with-label js-traveller-birthDate" style="display: none">\r\n					<span class="js-widget"  onclick=\'return new Widgets.MaskedDate({ bindingProperty: "passport.birthDate", label: L10N.get("bookingForm.birthDate"), placeholder: L10N.get("bookingForm.birthDatePlaceholder") })\'></span>\r\n				</div>\r\n                <div class="l-grid-layout-33 p-search__options-date-with-label js-traveller-passportBirthDate">\r\n                    <span class="js-widget"  onclick=\'return new Widgets.MaskedDate({ bindingProperty: "passport.birthDate", label: L10N.get("bookingForm.birthDate"), placeholder: L10N.get("bookingForm.birthDatePlaceholder") })\'></span>\r\n                </div>\r\n				<div class="l-grid-layout-33 js-secondName-container"></div>\r\n			</div>\r\n\r\n			<div class="l-line"></div>\r\n\r\n			<span class="b-traveller__block-header collapse--with-anchor mobile-collapse" data-toggle="collapse" data-target=".toggle-documents-' +
((__t = ( index )) == null ? '' : __t) +
'">' +
((__t = ( L10N.get("bookingForm.passengerDocumentHeader"))) == null ? '' : __t) +
'</span>\r\n			<div class="l-grid-container toggle-documents-' +
((__t = ( index )) == null ? '' : __t) +
' show">\r\n				<div class="l-grid-layout-33">\r\n					<label class="b-input">\r\n						<span class="js-widget"  onclick=\'return new Widgets.Select({ bindingProperty: "passport.type", values: "' + passportTypes + '", label: L10N.get("bookingForm.documentType"), placeholder: L10N.get("bookingForm.documentTypePlaceholder") })\'></span>\r\n					</label>\r\n				</div>\r\n                ';
 if (langRegistrationFullName.uid === 'LATIN_AND_CYRILLIC' && useTravellerNameFromDocuments && useLangTwoLocales) { ;
__p += '\r\n                <div class="l-grid-layout-33">\r\n                    <span class="js-widget"  onclick=\'return new Widgets.ToggleButtonDouble({ bindingProperty: "useLang", valueFirst: "CYRILLIC", valueSecond: "LATIN", labelFirst: "RU", labelSecond: "EN", classes: "b-traveller__lang-toggle" })\'></span>\r\n                </div>\r\n                ';
 } ;
__p += '\r\n			</div>\r\n            <div class="l-grid-container toggle-documents-' +
((__t = ( index )) == null ? '' : __t) +
' show">\r\n                <div class="l-grid-layout-33 b-traveller__passport-number">\r\n                    <span class="js-widget"  onclick=\'return new Widgets.Input({bindingProperty: "passport.number", label: "' + l10nPassportNumber + '", placeholder: L10N.get("bookingForm.passportNumberPlaceholder") })\'></span>\r\n                </div>\r\n                <div class="l-grid-layout-33 b-traveller__passport-expired-date">\r\n                    <span class="js-widget"  onclick=\'return new Widgets.MaskedDate({ bindingProperty: "passport.expiredDate", min: "' + parseInt(new Time().format("YYYY")) + '", label: L10N.get("bookingForm.expiredDate"), placeholder: L10N.get("bookingForm.expiredDatePlaceholder") })\'></span>\r\n                </div>\r\n            </div>\r\n			<div class="l-grid-container toggle-documents-' +
((__t = ( index )) == null ? '' : __t) +
' show">\r\n				<div class="l-grid-layout-33">\r\n					<label class="b-input">\r\n						<span class="js-widget"  onclick=\'return new Widgets.Select({ bindingProperty: "passport.citizenship", values: "' + countries + '", label: L10N.get("bookingForm.citizenship")})\'></span>\r\n					</label>\r\n				</div>\r\n				<div class="l-grid-layout-33">\r\n					<label class="b-input">\r\n						<span class="js-widget"  onclick=\'return new Widgets.Select({ bindingProperty: "passport.originCountry", values: "' + countries + '", label: L10N.get("bookingForm.origin"), placeholder: L10N.get("bookingForm.originPlaceholder") })\'></span>\r\n					</label>\r\n				</div>\r\n			</div>\r\n			';
 if (isAdult) { ;
__p += '\r\n				<div class="l-line"></div>\r\n				<span class="b-traveller__block-header collapse--with-anchor mobile-collapse" data-toggle="collapse" data-target=".toggle-passenger-contact-' +
((__t = ( index )) == null ? '' : __t) +
'">' +
((__t = ( L10N.get("bookingForm.passengerContactsHeader"))) == null ? '' : __t) +
'</span>\r\n				<div class="l-grid-container toggle-passenger-contact-' +
((__t = ( index )) == null ? '' : __t) +
' show">\r\n					<div class="l-grid-layout-33">\r\n						<span class="js-widget"  onclick=\'return new Widgets.Phone({ bindingProperty: "phone", label: L10N.get("bookingForm.phone"), placeholder: L10N.get("bookingForm.phonePlaceholder") })\'></span>\r\n					</div>\r\n					<div class="l-grid-layout-33">\r\n						<span class="js-widget"  onclick=\'return new Widgets.Input({ bindingProperty: "email", label: L10N.get("bookingForm.email"), classes: "b-email", placeholder: L10N.get("bookingForm.emailPlaceholder") })\'></span>\r\n					</div>\r\n					<div class="l-grid-layout-33">\r\n\r\n					</div>\r\n				</div>\r\n			';
 } ;
__p += '\r\n			';
 if (bonusCardAvailable) { ;
__p += '\r\n				<div class="l-line"></div>\r\n				<span class="b-traveller__block-header collapse--with-anchor mobile-collapse" data-toggle="collapse" data-target=".toggle-bonus-cards-' +
((__t = ( index )) == null ? '' : __t) +
'">' +
((__t = ( L10N.get("bookingForm.discountHeader"))) == null ? '' : __t) +
'</span>\r\n				<div class="l-grid-container b-traveller__bonus-cards toggle-bonus-cards-' +
((__t = ( index )) == null ? '' : __t) +
' show">\r\n					<div class="l-grid-layout-33">\r\n						<span class="js-widget"  onclick=\'return new Widgets.AirlinesInput({ bindingProperty: "bonusCard.airline", label: L10N.get("bookingForm.bonusCardAirline"), placeholder: L10N.get("bookingForm.bonusCardAirlinePlaceholder") })\'></span>\r\n					</div>\r\n					<div class="l-grid-layout-33">\r\n						<span class="js-widget"  onclick=\'return new Widgets.Input({ bindingProperty: "bonusCard.number", label: L10N.get("bookingForm.cardNumber"), placeholder: L10N.get("bookingForm.cardNumber") })\'></span>\r\n					</div>\r\n					<div class="l-grid-layout-33">\r\n            <span class="js-widget"  onclick=\'return new Widgets.Input({ bindingProperty: "bonusCard.nameOnCard", label: L10N.get("bookingForm.nameOnCardLabel"), placeholder: L10N.get("bookingForm.nameOnCardPlaceholder") })\'></span>\r\n          </div>\r\n				</div>\r\n			';
};
__p += '\r\n			';
 if (!_.isEmpty(organizationCostCodes)) { ;
__p += '\r\n				<div class="l-line"></div>\r\n				<span class="b-traveller__block-header collapse--with-anchor mobile-collapse collapsed" data-toggle="collapse" data-target=".toggle-cost-codes-' +
((__t = ( index )) == null ? '' : __t) +
'">' +
((__t = ( L10N.get('travelPolitics.statInfo') )) == null ? '' : __t) +
'</span>\r\n				<div class="b-traveller-cost-codes toggle-cost-codes-' +
((__t = ( index )) == null ? '' : __t) +
' collapsed">\r\n					<div class="b-traveller-cost-codes__container"></div>\r\n				</div>\r\n			';
 } ;
__p += '\r\n		</div>\r\n	</div>\r\n</div>\r\n';

}
return __p
}