// eslint-disable-next-line banned-modules
'use strict';

import './style.less';

import Widget from '@/widgets/base.widget';
import template from './template.ejs';
import SeatModel from './seat-model';
import validationTemplate from '@/widgets/validationErrorsTemplate.ejs';
import $ from 'jquery';

export default Widget.extend({

	validationTemplate,

	template,

	events: {
		click: 'setActive',
		'click .b-seats__item-minus': 'minus',
		'click .b-seats__item-plus': 'plus',
		'click .b-seats__wrapper': 'open',
	},

	available: {
		UPPER: 0,
		LOWER: 0,
	},

	initialize() {
		const { numbers } = this.options;

		this.calculateUpperLowerPlaces(numbers);

		this.render({
			types: [
				{
					uid: 'LOWER',
					caption: !this.options.isSapsan ?
						L10N.get('Widgets.railways.LOWER') :
						L10N.get('Widgets.railways.SAPSAN_LOWER'),
				},
				{
					uid: 'UPPER',
					caption: !this.options.isSapsan ?
						L10N.get('Widgets.railways.UPPER') :
						L10N.get('Widgets.railways.SAPSAN_UPPER'),
				},
			],
		});
	},

	calculateUpperLowerPlaces(numbers) {
		const {UPPER_TOTAL, LOWER_TOTAL} = numbers.reduce((result, currentPlace) => {
			const position = `${currentPlace.position}`;
			const isUpper = position.includes('upper');
			const isLower = position.includes('lower');
			if (isUpper || isLower) {
				result[isUpper ? 'UPPER_TOTAL' : 'LOWER_TOTAL'] += 1;
			}
			return result;
		}, {
			UPPER_TOTAL: 0,
			LOWER_TOTAL: 0,
		});
		this.available.UPPER = UPPER_TOTAL;
		this.available.LOWER = LOWER_TOTAL;
	},

	setActive() {
		this.isActive = true;
	},

	open() {
		this.$el.toggleClass('b-seats__open');
		this.$('.b-seats__validation').toggleClass('b-seats__validation-visible');
		$('html').on('click.seats', () => {
			if (!this.isActive) {
				this.$el.removeClass('b-seats__open');
				this.$('.b-seats__validation').removeClass('b-seats__validation-visible');
				$('html').off('click.seats');
			}
			this.isActive = false;
		});
	},

	updateLabel() {
		const seats = this.model.toJSON();
		const seatsLabels = _.values(_.mapObject(seats, (val, key) => {
			if (!this.options.isSapsan) {
				return `${val} ${L10N.getPluralForm(`Widgets.railways.seats.${key}`, val)}`;
			}
			return `${val} ${L10N.get(`Widgets.railways.sapsan.${key}`)}`;
		}));

		if (_.isEmpty(seatsLabels)) {
			seatsLabels.push(L10N.get(`Widgets.railways.notSelected`));
		}

		this.$('.b-seats__input').text(seatsLabels.join(', '));
	},

	minus(e) {
		const parent = $(e.currentTarget).parent();
		this.model.minus(parent.attr('data-type'));

		this.$el.find('.b-seats__item-minus').removeClass('tooltiped').tooltip('dispose');
	},

	plus(e) {
		const parent = $(e.currentTarget).parent();

		const dataType = parent.attr('data-type');
		const availableAmount = this.available[dataType] || 0;
		const currentAmount = this.model.get(dataType) || 0;

		if (currentAmount < availableAmount) {
			this.model.plus(dataType);
			this.$el.find('.b-seats__item-plus').removeClass('tooltiped').tooltip('dispose');
		}
	},

	applyBinding(model) {
		if (
			!model.has(this.options.bindingProperty) ||
			!(model.get(this.options.bindingProperty) instanceof SeatModel)
		) {
			model.set(this.options.bindingProperty, new SeatModel());
		}
		this.model = model.get(this.options.bindingProperty);
		this.model.setOptions(this.options);

		_.each(SeatModel.prototype.defaults(), (count, type) => {
			const item = this.$el.find(`[data-type=${type}]`);
			const itemValue = item.find('.b-seats__item-value');
			this.model.on(`change:${type}`, (_, value) => {
				itemValue.text(value);
				this.updateLabel();
			});
			this.model.on(`validationError:${type}`, (errors) => {
				this.showValidation(errors);
			});

			itemValue.text(this.model.get(type));
		});
		this.updateLabel();
	},

});
