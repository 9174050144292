import React, { useContext, useMemo } from "react";
import { ReservationType } from "../../../../../../../../helpers/interfaces/Reservation";
import {
	CloseButtonWrapper,
	Container,
	ReservationDetails,
	ReservationInfo,
	ReservationPartDark,
	ReservationPartLight,
	ReservationParts,
	ReservationPartSeparator,
	ReservationRoute,
	Separator,
	Title,
} from "./styled";
import { TravelSubjectType } from "../../../../../../../../helpers/interfaces/TravelSubject";
import { AviaReservation } from "../../../../../../../../helpers/interfaces/AviaReservation";
import FormattedPrice from "../../../../../../../../components/FormattedPrice";
import ServiceIcon from "./components/ServiceIcon";
import { HotelReservation } from "../../../../../../../../helpers/interfaces/HotelReservation";
import { ReactAppGlobalContext } from "../../../../../../../../../js/utils/react-utils";
import { TransferReservation } from "../../../../../../../../helpers/interfaces/TransferReservation";
import AviaUtils from "../../../../../../../../../js/utils/avia-utils";

interface StatisticsPopupHeaderProps {
	reservation: ReservationType;
	closePopup: () => void;
}

const StatisticsPopupHeader: React.FC<StatisticsPopupHeaderProps> = (props) => {
	const { reservation, closePopup } = props;
	const { travelSubject } = reservation;

	const { L10N } = useContext(ReactAppGlobalContext);

	const isRoundTrip = useMemo(() => {
		const { uid } = travelSubject || {};
		if (uid === TravelSubjectType.AIR) {
			return AviaUtils.isRoundTrip((reservation as AviaReservation).legs);
		}
		return false;
	}, [travelSubject, reservation]);

	const routeParts = useMemo(() => {
		const { uid } = travelSubject || {};
		if (reservation) {
			if (uid === TravelSubjectType.AIR) {
				const res = reservation as AviaReservation;
				const lastLeg = res.legs[res.legs.length - 1];
				const firstSegment = res.legs[0].segments[0];
				const lastSegment = lastLeg.segments[lastLeg.segments.length - 1];

				if (isRoundTrip) {
					return [
						[
							firstSegment.departureCity?.caption,
							firstSegment.departureAirport?.caption,
						],
						[
							lastSegment.departureCity?.caption,
							lastSegment.departureAirport?.caption,
						],
					];
				}

				return [
					[
						firstSegment.departureCity?.caption,
						firstSegment.departureAirport?.caption,
					],
					[
						lastSegment.arrivalCity?.caption,
						lastSegment.arrivalAirport?.caption,
					],
				];
			} else if (uid === TravelSubjectType.HOTEL_RESERVATION) {
				const res = reservation as HotelReservation;
				return [[res?.card?.name, res?.card?.address?.cityInfo?.city?.caption]];
			} else if (uid === TravelSubjectType.TRANSFER) {
				const res = reservation as TransferReservation;
				return [
					[res?.startAddress, ""],
					[res?.endAddress, ""],
				];
			}
		}
		return [];
	}, [travelSubject, reservation, isRoundTrip]);

  const safeFormatPnr = (pnr: any | any[]) => {
    if (!pnr) {
      return '';
    }
    if (Array.isArray(pnr)) {
      return pnr.map((inner) => inner.pnr).filter((pnr) => pnr).join(', ');
    }
    return JSON.stringify(pnr);
  }

  return (
		<Container>
			<ReservationInfo>
				<Title>{L10N.get("cabinet.statistics.title")}</Title>
				<ReservationRoute>
					<ServiceIcon travelSubject={travelSubject} />
					<ReservationParts>
						{routeParts.map(
							(routePart: Array<string | undefined>, routePartIndex) => {
								return (
									<React.Fragment key={routePartIndex}>
										<ReservationPartDark>
											{routePart[0]}
											{routePart[1] && <></>}
										</ReservationPartDark>
										{routePart[1] && (
											<ReservationPartLight>
												{routePart[1]}
											</ReservationPartLight>
										)}
										{routePartIndex !== routeParts.length - 1 && (
											<ReservationPartSeparator>
												{isRoundTrip ? "↔" : "-"}
											</ReservationPartSeparator>
										)}
									</React.Fragment>
								);
							}
						)}
					</ReservationParts>
				</ReservationRoute>
				<ReservationDetails>
					<div>
						{L10N.get("cabinet.statistics.bookingNumber")}:{" "}
						<strong>{safeFormatPnr(reservation.prn)}</strong>
					</div>
					<Separator />
					<div>
						<strong>
							<FormattedPrice price={reservation.totalPrice} />
						</strong>
					</div>
				</ReservationDetails>
			</ReservationInfo>
			<CloseButtonWrapper onClick={closePopup} />
		</Container>
	);
};

export default StatisticsPopupHeader;
