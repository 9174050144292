module.exports = function(obj) {
obj || (obj = {});
var __t, __p = '', __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
with (obj) {

 if (!_.isEmpty(offers)) { ;
__p += '\n	';

		var currencyCode = _.values(offers)[0].offer.price.total.currencyCode;
		var sum = _.reduce(offers, function (v, item) {
			return v + parseFloat(item.offer.price.total.amount) * item.roomsCount;
		}, 0);
		var roomsCount = _.map(offers, function (item) {
			return item.roomsCount;
		}).reduce(function (prev, curr) {
			return prev + curr;
		});
		var active = roomsCount >= roomsNumber;
	;
__p += '\n';
 } ;
__p += '\n';

    function sumTooltip() {
        let result = '';
		const values = _.values(offers);
        result += _.map(values, function (item) { return require('../b-offer-pricing')({ price: item.offer.price, taxInfoList: item.offer.taxInfoList, offer: item.offer, roomsCount: item.roomsCount })}).join('<div class="prices-separator"></div>');
        logger.debug(result);

        if (values.length > 1) {
            result = '<div class="prices-container">'+ result +'</div>';
            result += '<div class="prices-total">'+ L10N.get('pricing.totalPrice') + ': ' + Formatter.formatMoney(sum) + (currencyCode && Formatter.formatCurrency(currencyCode)) +'</div>';
        }
        return result;
    }

    var getTravellersCount = function(travellersInfo) {
      if (travellersInfo) {
        const travellersCount = Object.values(travellersInfo).reduce(function(count, currentCount) {
          return count + currentCount;
        }, 0);
        return travellersCount + " " + L10N.get('hotels.travellersInfo');
      }
      return '';
    }

    var getTotalRoomsCount = function(offersValues) {
      const totalRoomsCount = offersValues.reduce(function(roomsCount, offer) {
          return roomsCount + offer.roomsCount;
      }, 0);
      if (totalRoomsCount) {
        return totalRoomsCount + " " + Formatter.getNumEnding(totalRoomsCount, L10N.get('formatter.endings.room'));
      }
      return 0;
    }

    const offersValues = Object.values(offers);
;
__p += '\n	<div class="b-hotel-offers__summary">\n      \n			<div class="b-hotel-offers__summary-amount">\n        ';
 if (offersValues && offersValues.length) { ;
__p += '\n          ';
 if (offersValues[0].offer && offersValues[0].offer.travellersInfo) { ;
__p += '\n          <div class="b-hotel-offers__summary-amount-travellersInfo-wrapper">\n            <div class="b-hotel-travellers-info">\n              <div class="b-travell-icon"></div>\n              <div class="b-travell-info">' +
((__t = ( getTravellersCount(offersValues[0].offer.travellersInfo) )) == null ? '' : __t) +
'</div>\n            </div>\n            <div class="b-hotel-travellers-rooms-info">\n              ' +
((__t = ( getTotalRoomsCount(offersValues) )) == null ? '' : __t) +
'\n            </div> \n          </div>\n          ';
 } ;
__p += '\n         \n        ';
 } ;
__p += '\n        <div class="b-hotel-offers__summary-amount-sum-wrapper">\n          ';
 if (issueAllowed) { ;
__p += '\n            ' +
((__t = ( L10N.get('hotels.totalSum') )) == null ? '' : __t) +
':\n            <span class="b-hotel-offers__summary-amount-sum" data-toggle="tooltip" data-html="true" data-position="bottom"\n                            data-original-title="<div class=\'summary-tooltip-total\'>' +
((__t = ( _.escape(sumTooltip()) )) == null ? '' : __t) +
'</div>">\n              ' +
((__t = ( Formatter.formatMoney(sum, 0) )) == null ? '' : __t) +
'\n              ' +
((__t = ( currencyCode && Formatter.formatCurrency(currencyCode) )) == null ? '' : __t) +
'\n            </span>\n          ';
 } ;
__p += '\n        </div>\n			</div>\n		';
 if (issueAllowed) { ;
__p += '\n			<div\n					data-placement="top"\n					data-toggle="tooltip"\n					data-html="true"\n					data-original-title="' +
((__t = ( _.escape(L10N.get('hotels.chooseRooms')) )) == null ? '' : __t) +
'"\n					class="b-hotel__summary-select b-hotel__summary-select--select ' +
((__t = ( active ? '' : 'b-hotel__summary-select--disabled disabled' )) == null ? '' : __t) +
'"\n			>\n				' +
((__t = ( L10N.get('trains.continue') )) == null ? '' : __t) +
'\n			</div>\n		';
 } ;
__p += '\n		';
 if (approvalAllowed) { ;
__p += '\n			<div\n					data-placement="top"\n					data-toggle="tooltip"\n					data-original-title="' +
((__t = ( L10N.get('hotels.chooseRooms') )) == null ? '' : __t) +
'"\n					class="b-hotel__summary-select b-hotel__summary-select--offer ' +
((__t = ( active ? '' : 'b-hotel__summary-select--offer--disabled disabled' )) == null ? '' : __t) +
'"\n			>\n				' +
((__t = ( L10N.get('hotels.addToAgree') )) == null ? '' : __t) +
'\n			</div>\n		';
 } ;
__p += '\n	</div>\n\n';

}
return __p
}