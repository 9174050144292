// eslint-disable-next-line banned-modules
'use strict';

import BaseCollection from '@/classes/base.collection';
import ToggleModel from '@/blocks/elements/b-filters/widgets/toggle/model';
import RangeModel from '@/blocks/elements/b-filters/b-filters-hotels/range/model';
import SelectModel from '@/blocks/elements/b-filters/b-filters-hotels/select/model';
import InputModel from '@/blocks/elements/b-filters/widgets/input/model';

export default BaseCollection.extend({

	snapshotName: 'hotels:filter',
	noFirstStepFilterResults: false,

	initialize(models, options) {
		this.results = options.results;
		this.parent = options.parent;
		this.pageView = options.pageView;

		if (_.isEmpty(this.results)) {
			return;
		}

		const siteSettings = STATE.getSettings();
		const { secondStepSettings } = siteSettings || {};
		const {
			filterHotelMeal,
			filterHotelAvailability,
			filterHotelTripartiteContract,
			filterHotelRefundable,
			filterHotelSubProvider,
			filterHotelSpecialRates,
		} = secondStepSettings || {};

		const fields = [
			'searchString',
			'price',
			'distance',
			'travelPolicyCompliances',
			'tripartiteContract',
			'clientTariffCode',
			'class',
			'availability',
			'mealIncluded',
			'hotelType',
			'settlement',
			'services',
			'penalties',
			'providers',
			'subProvider',
		].filter((field) => {
			switch (field) {
				case 'mealIncluded':
					return !!filterHotelMeal;
				case 'availability':
					return !!filterHotelAvailability;
				case 'tripartiteContract':
					return !!filterHotelTripartiteContract;
				case 'penalties':
					return !!filterHotelRefundable;
				case 'subProvider':
					return !!filterHotelSubProvider;
				case 'clientTariffCode':
					return !!filterHotelSpecialRates;
				default:
					return true;
			}
		});
		this.addModels(fields);

		this.collect();
		this.bind();
	},

	addModels(fields) {
		let travelPolicyComplianceFilter;
		let classFilter;
		_.each(fields, (field) => {
			switch (field) {
				case 'searchString':
					this.add(new InputModel({
						placeholder: L10N.get('filters.hotelsNameAddress'),
						field,
					}));
					break;
				case 'price':
					this.add(new RangeModel({
						label: L10N.get('filters.pricePerDay'),
						field,
						sources: ['maxPrice', 'minPrice'],
						formatter: (value) => {
							return `${parseInt(value, 10)} RUB`;
						},
					}, {
						showInputValue: true,
						showRange: false,
						formatter: (value) => parseInt(value, 10),
						toggleLabel: L10N.get('filters.togglePriceWidgetLabel'),
						activateWidgetByToggle: true,
					}));
					break;
				case 'distance':
					this.add(new RangeModel({
						label: L10N.get('filters.toCenter'),
						field,
						formatter: (value) => {
							return `${value} km`;
						},
					}, {
						showInputValue: true,
						showRange: false,
						toggleLabel: L10N.get('filters.toggleDistanceWidgetLabel'),
						activateWidgetByToggle: true,
					}));
					break;
				case 'travelPolicyCompliances':
					travelPolicyComplianceFilter = new SelectModel({
						label: L10N.get('filters.travelPolicy'),
						field,
					}, {selectByDefault: false, disableOnSingularValue: false});
					travelPolicyComplianceFilter.formatter = (values) => {
						values.models = _.sortBy(values.models, (el) => {
							let value = 0;
							switch (el.get('uid')) {
								case 'RECOMMENDED':
									value = -1;
									break;
								case 'VIOLATION':
									value = 1;
									break;
								case 'STRICT_VIOLATION':
									value = 2;
									break;
								case 'AUTHORIZATION_REQUIRED':
									value = 3;
									break;
							}
							return value;
						});
						return values;
					};
					this.add(travelPolicyComplianceFilter);
					break;
				case 'tripartiteContract':
					if (STATE.checkSiteType('B2B')) {
						this.add(new ToggleModel({
							label: L10N.get('filters.tripartite'),
							field,
						}));
					}
					break;
				case 'clientTariffCode':
					if (STATE.checkSiteType('B2B')) {
						this.add(new ToggleModel({
							label: L10N.get('filters.clientTariffCode'),
							field,
						}));
					}
					break;
				case 'class':
					classFilter = new SelectModel({
						label: L10N.get('filters.classStars'),
						field,
					}, {
						snowCount: true,
					});
					classFilter.formatter = (values) => {
						values.models = _.sortBy(values.models, (el) => (el.get('repeat') == null ? 100 : el.get('repeat') * -1));
						return values;
					};
					this.add(classFilter);
					break;
				case 'availability':
					this.add(new SelectModel({
						label: L10N.get('filters.availability'),
						field,
					}));
					break;
				case 'mealIncluded':
					this.add(new SelectModel({
						label: L10N.get('filters.meal'),
						field,
					}));
					break;
				case 'hotelType':
					this.add(new SelectModel({
						label: L10N.get('filters.hotelType'),
						field,
					}, {
						snowCount: true,
					},
					));
					break;
				case 'settlement':
					this.add(new SelectModel({
						label: L10N.get('filters.settlement'),
						field,
					}));
					break;
				case 'services':
					this.add(new SelectModel({
						label: L10N.get('filters.services'),
						field,
					}, {
						limits: 5,
						limitLabel: L10N.get('filters.showAllServices'),
					}));
					break;
				case 'penalties':
					this.add(new SelectModel({
						label: L10N.get('filters.rate'),
						field,
					}));
					break;
				case 'providers':
					if (this.parent.showGdsAccountName) {
						this.add(new SelectModel({
							label: L10N.get('filters.providers'),
							field,
						}, {
							limits: 5,
							limitLabel: L10N.get('filters.showAllProviders'),
						}));
					}
					break;
				case 'subProvider':
					this.add(new SelectModel({
						label: L10N.get('filters.subProvider'),
						field,
					}, {selectByDefault: false, disableOnSingularValue: false}));
					break;
			
				default:
					break;
			}
		});
	},

	bind() {
		_.each(this.models, (model) => {
			model.on('change:value', this.parent.showApplyButton.bind(this.parent));
			if (model instanceof RangeModel) {
				model.on('change:active', this.parent.showApplyButton.bind(this.parent));
			}
		});
	},

	updateModels() {

	},

	collectSnapshot() {
		const snapshot = _.map(this.models, (m) => {
			if (m instanceof SelectModel) {
				return {
					field: m.get('field'),
					values: _.map(m.get('values').models, (v) => {
						return {
							uid: v.get('uid'),
							value: v.get('value'),
						};
					}),
				};
			}

			return {
				active: m.get('active'),
				field: m.get('field'),
				value: m.get('value'),
			};
		});
		STATE.setFormState(this.snapshotName, snapshot);
	},

	applySnapshot() {
		const snapshot = STATE.getFormState(this.snapshotName);
		_.each(snapshot, ({ field, value, values, active }) => {
			const model = _.find(this.models, (m) => m.get('field') === field);
			if (!model) {
				return;
			}

			if (model instanceof SelectModel && _.isArray(values)) {
				const valueModels = model.get('values').models;
				_.each(values, (v) => {
					const valueModel = _.find(valueModels, (vm) => vm.get('uid') === v.uid);
					if (!valueModel) {
						return;
					}

					valueModel.set('value', v.value);
				});
			} else if (model instanceof RangeModel && _.isArray(value)) {
				model.set('active', active);
				model.set('value', value);
			} else {
				model.set('value', value);
			}
		});
	},

	silentFilter(views) {
		_.each(views, (v) => {
			v.model.filtered = _.some(this.models, (model) => model.filter(v.model) !== true);
			v.model.set('visibility', v.model.filtered !== true);
		});
		return _.filter(views, (v) => v.model.get('visibility') === true);
	},

	processFilterResults() {
		/*
			Ищем точное совпадение по полю searchString во время общей фильтрации.
			Т.к. _.some() должен остановиться на первом результате, вернувшем true, то мы полагаем,
			что если InputModel с полем "searchString" вернул true, значит фильтрация отеля закончится на нем.
			Все неотфильтрованные по "searchString" items собираем в Map() и, если у нас было точное совпадение
			по "searchString", скрываем все items без isExactMatch.
		*/
		let hasExactMatchInSearchString = false;
		const searchStringNonFilteredResults = new Map();

		_.each(this.results, (item) => {
			item.filtered = _.some(this.models, (model) => {
				const result = model.filter(item) !== true;
				if (model instanceof InputModel && model.get('field') === 'searchString') {
					const isExactMatch = model.findValue(item, true);
					if (isExactMatch) {
						hasExactMatchInSearchString = true;
					}
					if (!result) {
						searchStringNonFilteredResults.set(`${item.get('name')}_${item.get('number')}`, {
							item,
							isExactMatch,
						});
					}
				}
				return result;
			});
			item._filtered = item.filtered;
		});

		if (hasExactMatchInSearchString) {
			searchStringNonFilteredResults.forEach(val => {
				if (!val.isExactMatch) {
					val.item.filtered = val.item._filtered = true;
				}
			});
		}
		searchStringNonFilteredResults.clear();
	},

	filterResults() {
		this.collectSnapshot();

		this.processFilterResults();		

		this.filteredResult = _.filter(this.results, (item) => item.filtered === true);
		this.visibleResults = _.filter(this.results, (item) => item.filtered !== true);
		this.pageView.filtersApplied = !_.isEmpty(this.filteredResult);
		this.pageView.visibleResultsEmpty = !_.size(this.visibleResults);
	},

	triggerChangeEvent() {
		STATE.getPageView().getPageModel().set('isFilteringResults', true);
		this.trigger('beforeFilterCollection');
		this.filterResults();
		this.trigger('filterCollection');

		_.defer(() => {
			if (STATE.getPageView()) STATE.getPageView().getPageModel().set('isFilteringResults', false);
		});
	},

	collect() {
		if (this.results && this.models) {
			_.each(this.models, (m) => {
				if (m instanceof SelectModel && m.resetCount) m.resetCount();
			});
			_.each(this.results, (item) => {
				this.models.forEach(model => model.collect(item));
			});
			_.each(this.results, (item) => {
				item._filtered = _.some(this.models, (model) => model.filter(item) !== true);
			});
		}

		this.applySnapshot();
	},

});
