// eslint-disable-next-line banned-modules
'use strict';

import BaseView from '@/classes/base.view';
import logger from '@/utils/logger';
import './validation-error.less';

const WidgetView = BaseView.extend({

	el: '<div />',

	preinitialize(options) {
		BaseView.prototype.preinitialize.call(this, options);
	},

	validationTemplate() {
		return '';
	},

	initialize() {
		this.render(this.options);
	},

	applyBinding(model) {
		logger.debug('applyBinding not implementing for widget ', this, model);
	},

	render(parameters) {
		const templateObject = Object.assign(
			{
				classes: '',
				placeholder: '',
			},
			this.options,
			parameters,
		);
		this.setElement(this.template(templateObject));
		this.addWindowEventListeners();
	},

	applyValidating() {

	},

	showValidation(errors) {
		this.$el.addClass('validation-error');
		this.$el.find('.validations-errors__container').remove();
		this.$el.append(this.validationTemplate({errors}));
	},

	hideValidation() {
		this.$el.removeClass('validation-error');
		this.$el.find('.validations-errors__container').remove();
	},

});

/*eslint-disable */
WidgetView.extend = function (protoProps, staticProps) {
	const temp = BaseView.extend.call(WidgetView, protoProps, staticProps);

	const tempValidatingFunc = temp.prototype.applyValidating;
	temp.prototype.applyValidating = function (model) {
		this.listenTo(model, `validationError:${this.options.bindingProperty}`, (errors) => {
			if (errors != null) {
				this.showValidation(errors);
				const offset = this.$el && this.$el.offset();
				if (offset && offset.top > 0) {
					const scrollToValidatedParameters = new CustomEvent('scrollToValidatedParameters', {
						detail: {
							view: this,
							offset,
						},
					});
					window.dispatchEvent(scrollToValidatedParameters);
				}
			}
		});
		this.listenTo(model, `change:${this.options.bindingProperty}`, (thisModel, value) => {
			this.hideValidation();
		});

		if (tempValidatingFunc) {
			tempValidatingFunc.call(this, model);
		}

	};

	if (temp.prototype.applyBinding) {
		const tempFunc = temp.prototype.applyBinding;
		temp.prototype.applyBinding = function (model) {
			if (!this.options || !this.options.bindingProperty) {
				logger.warn('Missing bindingProperty in widget', this);
				return;
			}
			this.model = model;
			this.bindWidgets();
			tempFunc.call(this, model);
			this.applyValidating(model);
		};
	}

	return temp;
};
/*eslint-enable */

export default WidgetView;
