import React, { createContext, createElement, ReactNode } from "react";
import { createRoot, Root } from "react-dom/client";
import L10N from "./L10N";
import { resetAllStores } from "../../react/store";
import ErrorBoundary from "../../react/components/ErrorBoundary/ErrorBoundary";

export interface ReactAppGlobals {
	STATE: any;
	logger: any;
	L10N: any;
}

export const ReactAppGlobalContext = createContext({} as ReactAppGlobals);

const reactAppLogoutFunction = function () {
	resetAllStores();
};

type CleanupReactAppPayload = {
	viewId?: string;
};

const cleanupReactApps = function (
	event: CustomEvent<CleanupReactAppPayload>
) {};

interface RenderReactAppOptions {
	viewId?: string;
	rootHtmlElement: HTMLElement;
	renderAppComponent: React.FC;
	renderAppComponentProps?: { [key: string]: unknown };
}

const UUIDGenerator = (window as any).UUIDGenerator || {};
const defaultUuid = () => Date.now();
const uuid = UUIDGenerator.getInitializeUUID || defaultUuid;

export class ReactUtils {
	static eventsDelegated = false;

	static roots = {} as {
		[key: string]: {
			root: Root;
		};
	};

	static unmountRemovedRoots() {
		const domRoots = document.querySelectorAll("[data-react-app]");
		const domRootsIds = Array.from(domRoots)
			.map((domRoot) => domRoot.getAttribute("data-react-app"))
			.filter((domRootId) => domRootId);
		const memoryRootsIds = Object.keys(this.roots);
		memoryRootsIds.forEach((rootId) => {
			if (!domRootsIds.includes(rootId)) {
				this.roots[rootId].root.unmount();
				delete this.roots[rootId];
				console.log("remove/unmount rootId:", rootId);
			}
		});
	}

	static unmoundRootWithSameId(newRootId: string) {
		const memoryRootsIds = Object.keys(this.roots);
		if (memoryRootsIds.includes(newRootId)) {
			this.roots[newRootId].root.unmount();
			delete this.roots[newRootId];
		}
	}

	static renderReactApp(options: RenderReactAppOptions) {
		const {
			viewId,
			rootHtmlElement,
			renderAppComponent,
			renderAppComponentProps = {},
		} = options;

		if (!rootHtmlElement) {
			throw new Error("React App error, rootHtmlElement must be specified");
		}

		const rootId = viewId || `gen_${Date.now()}`;

		this.unmoundRootWithSameId(rootId);

		// add root id to attributes
		rootHtmlElement.setAttribute("data-react-app", rootId);

		// create root
		const root = createRoot(rootHtmlElement);
		// save link to root
		this.roots[rootId] = { root };

		root.render(
			createElement(ErrorBoundary, {
				children: createElement(
					ReactAppGlobalContext.Provider,
					{
						value: {
							STATE,
							logger,
							L10N,
						},
					},
					createElement(renderAppComponent, { ...renderAppComponentProps })
				),
			})
		);
		// attach react global events
		if (!this.eventsDelegated) {
			this.delegateReactAppGlobalEvents();
		}

		// unmount removed roots
		// this.unmountRemovedRoots();
		console.log(this.roots);
	}

	static delegateReactAppGlobalEvents() {
		window.removeEventListener("react_app_logout", reactAppLogoutFunction);
		window.addEventListener("react_app_logout", reactAppLogoutFunction);

		if (document !== null) {
			document.removeEventListener("react_cleanup_apps", cleanupReactApps);
			document.addEventListener("react_cleanup_apps", cleanupReactApps);
		}

		this.eventsDelegated = true;
	}

	static classNames(classes?: any[]) {
		if (!classes || !Array.isArray(classes)) {
			return "";
		}
		return classes.filter((className: unknown) => !!className).join(" ");
	}

	static modelToParameters(model: unknown) {
		return {
			parameters: model,
		};
	}
}
