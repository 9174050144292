// eslint-disable-next-line banned-modules
'use strict';

import './style.less';
import template from './template.ejs';
import BaseView from '@/classes/base.view';
import HotelsCollection from './collection';

import ToggleModel from '@/blocks/elements/b-filters/widgets/toggle/model';
import RangeModel from '@/blocks/elements/b-filters/b-filters-hotels/range/model';
import SelectModel from '@/blocks/elements/b-filters/b-filters-hotels/select/model';
import InputModel from '@/blocks/elements/b-filters/widgets/input/model';

import GlUl from '@/utils/global-utils';
import $ from 'jquery';

export default BaseView.extend({

	template,

	ui: {
		sorting: '.b-filters-hotels__sorting',
		categories: '.b-filters-hotels__categories',
		widgets: '.b-filters-hotels__widgets',
		count: '.b-filters-hotels__count',
		dropFilters: '.b-filters__drop-filters-button',
	},

	events: {
		'click .b-filters-hotels__apply': 'triggerChangeEvent',
		'click .b-filters-hotels__reset': 'resetFilters',
		'click .b-filters__drop-filters-button': 'resetFilters',
	},

	searchFinished : false,

	preinitialize(options) {
		BaseView.prototype.preinitialize.call(this, options);
		this.windowEventListenerList.push({
			name: 'scroll',
			callback: GlUl.resetButtonOnScroll.bind(this, '.b-filters__drop-apply-filters-buttons'),
		});
	},

	initialize(options) {
		this.parent = options.parent;
		this.showGdsAccountName = this.parent.showGdsAccountName;
		this.hotelsView = options.hotelsView;
		this.collection = new HotelsCollection(null, {
			pageView: this.parent,
			results: options.models,
			parent: this,
		});

		this.render();
		this.addEventListeners();
	},

	addEventListeners() {
		this.listenTo(this.collection, 'filterCollection', () => {
			this.applyFilters();
		});
	},

	delegateEvents(...args) {
		BaseView.prototype.delegateEvents.apply(this, args);
	},

	undelegateEvents(...args) {
		BaseView.prototype.undelegateEvents.apply(this, args);
	},

	render(...args) {
		BaseView.prototype.render.apply(this, args);
		this.renderWidgets();
	},

	renderWidgets() {
		const remove = [];
		const $container = this.ui.widgets;
		_.each(this.collection.models, (model) => {
			if (!model.isValid()) {
				remove.push(model);
				return;
			}
			let content;
			if (model.get('view') != null) {
				if (model instanceof RangeModel && (model.get('__minManualTyping') || model.get('__maxManualTyping'))) {
					model.get('view').updateValues();
					return;
				}
				const oldView = model.get('view');
				content = model.render();
				if (oldView.el.parentNode != null) {
					oldView.el.parentNode.replaceChild(content.el, oldView.el);
				}
				content.$el.addClass(`b-filters-hotels__item b-filters-hotels__${model.get('field')}`);
				return;
			}
			content = model.render();
			if (content != null) {
				$container.append(content.$el.addClass(`b-filters-hotels__item b-filters-hotels__${model.get('field')}`));
			}
		});
		this.collection.modelFieldListToAdd = _.map(remove, (m) => {
			const field = m.get('field');
			if (m.get('view')) {
				m.get('view').$el.remove();
			}
			return field;
		});
		this.collection.remove(remove);
		this.collection.bind();
	},

	updateSelectedFiltersAfterSearch() {
		_.each(this.collection.models, (model) => {
			if (model instanceof SelectModel) {
				const modelView = model.get('view');
				if (modelView && modelView.updateItems) {
					modelView.updateItems(true);
				}
			}
		});
	},

	updateFilters(views) {
		this.collection.collectSnapshot();
		this.collection.results = this.parent.hotelsView.models;
		this.collection.addModels(this.collection.modelFieldListToAdd || ['']);
		this.parent.sortingView.views = this.parent.hotelsView.views;
		this.collection.collect();
		if (this.hotelsView && _.isFunction(this.hotelsView.initializeHotelCounter)) {
			this.hotelsView.initializeHotelCounter();
		}
		this.renderWidgets();
		if (!_.isEmpty(views)) {
			return this.collection.silentFilter(views);
		}
		return this.parent.queueRender;
	},

	addSorting(view) {
		this.ui.sorting.append(view.el);
	},

	addCategories(view) {
		this.ui.categories.append(view.el);
	},

	// EVENTS
	triggerChangeEvent(e) {
		if (e != null) {
			e.preventDefault();
		}

		this.collection.triggerChangeEvent();

		this.parent.applyFilters();
		// this.parent.sortingView.sort();

		if (STATE.checkViewport('(max-width: 768px)')) $('.l-layout').removeClass('show-filters');
		this.$el.find('.b-filters-hotels__apply').hide();
		this.$el.find('.b-filters-hotels__reset').show();
		if (this.hotelsView && _.isFunction(this.hotelsView.initializeHotelCounter)) {
			this.hotelsView.initializeHotelCounter();
		}
	},

	showApplyButton() {
		this.$el.find('.b-filters-hotels__apply').removeClass('btn-disabled').show();
		GlUl.resetButtonOnScroll('.b-filters__drop-apply-filters-buttons');
	},

	applyFilters(e) {
		if (e != null) {
			e.preventDefault();
		}

		_.each(this.collection.visibleResults, (item) => { item.trigger('visibilityChange', item, true); });
		_.each(this.collection.filteredResult, (item) => { item.trigger('visibilityChange', item, false); });
    
		this.parent.sortingView.sort();
	},

	resetFilters(e) {
		if (e != null) {
			e.preventDefault();
		}

		_.each(this.collection.models, (model) => {
			if (model instanceof ToggleModel) {
				model.set('value', false);
			} else if (model instanceof RangeModel) {
				model
					.set('value', [model.get('min'), model.get('max')])
					.set('active', false) // <- сбрасываем свитчер в позицию "off"
					.trigger('reset:range');
			} else if (model instanceof SelectModel) {
				model.get('values').forEach((item) => item.set('value', false));
			} else if (model instanceof InputModel) {
				model.set('value', '');
			}
		});

		_.each(this.collection.visibleResults, (item) => { item.trigger('visibilityChange', item, true); });
		_.each(this.collection.filteredResult, (item) => { item.trigger('visibilityChange', item, false); });

		this.triggerChangeEvent();

		if (STATE.checkViewport('(max-width: 768px)')) $('.l-layout').removeClass('show-filters');
		this.$el.find('.b-filters-hotels__apply').addClass('btn-disabled').show();
		this.$el.find('.b-filters-hotels__reset, .b-filters-hotels__footer-variants').hide();
	},

});
