// eslint-disable-next-line banned-modules
'use strict';

import './style.less';
import BaseView from '@/classes/base.view';
import SelectWidget from '@/widgets/b-select';
import template from './template.ejs';
import GlUl from '@/utils/global-utils';

export default BaseView.extend({

	template,

	ui: {
		expiredDate: '.b-traveller__passport-expired-date',
		passportNumber: '.b-traveller__passport-number',
		cyrillicDataContainer: '.b-passport__cyrillic-data',
		latinDataContainer: '.b-passport__latin-data',
		bonusCardsInfo: '.b-passport__bonus-cards-info',
	},

	events: {
		'click .b-passport__remove': 'remove',
	},

	initialize() {
		this.listenTo(this.model, `change:type`, (thisModel, value) => {
			this.handleChangeDocumentType(thisModel, value);
		});
		this.listenTo(this.model, `change:withoutMiddleName`, (model, value) => {
			this.$el.find('.b-middle-name.b-middle-name__pass .b-middle-name__value').attr('disabled', (value === true));
			this.$el.find('.b-input__label.b-middle-name__without').html(
				value ? L10N.get('bookingForm.withMiddleName') : L10N.get('bookingForm.withoutMiddleName'),
			);
			if (value) {
				model.set('middleName', '');
			}
		});
		this.listenTo(this.model, `change:withoutMiddleNameLat`, (model, value) => {
			this.$el.find('.b-middle-name-lat.b-middle-name__pass .b-middle-name__value').attr('disabled', (value === true));
			this.$el.find('.b-input__label.b-middle-name__without-lat').html(
				value ? L10N.get('bookingForm.withMiddleName') : L10N.get('bookingForm.withoutMiddleName'),
			);
			if (value) {
				model.set('middleNameLat', '');
			}
		});

		this.listenTo(this.model, 'passport:birthDateChange', (model, value) => {
			this.personBirthDate = value;
			if (value == null || this.model.get('type') == null || this.model.get('type').uid !== 'INTERNAL') {
				return;
			}

			this.model.set('expiredDate', this.calculateExpiredDate());
		});
		this.render();
		this.handleChangeDocumentType();
	},

	handleChangeDocumentType(thisModel, value) {
		this.ui.bonusCardsInfo.hide();

		if (this.model.get('type') == null) {
			return;
		}

		// UTS-357
		if (thisModel && value) {
			GlUl.fillCitizenshipByPassportType(thisModel, value);
		}

		const DOCUMENT_TYPE = this.model.get('type').uid;
		switch (DOCUMENT_TYPE) {
			case 'BIRTHDAY_NOTIFICATION':
			case 'INTERNAL':
				// this.ui.latinDataContainer.hide();
				// this.ui.cyrillicDataContainer.show();
				this.ui.latinDataContainer.show();
				this.ui.cyrillicDataContainer.show();
				// this.ui.bonusCardsInfo.show();
				break;
			case 'FOREIGN':
				this.ui.latinDataContainer.show();
				this.ui.cyrillicDataContainer.show();
				break;
			default:
				this.ui.latinDataContainer.show();
				this.ui.cyrillicDataContainer.show();
		}

		this.setDocumentPlaceholder(DOCUMENT_TYPE);
		if (DOCUMENT_TYPE === 'INTERNAL') {
			this.ui.expiredDate.hide();
			this.model.set('expiredDate', this.calculateExpiredDate());
		} else {
			this.ui.expiredDate.show();
		}
	},

	setDocumentPlaceholder(type) {
		this.ui.passportNumber.find('input[type="text"]').attr('placeholder', GlUl.setDocumentPlaceholder(type));
	},

	calculateExpiredDate() {
		if (this.personBirthDate == null) {
			return null;
		}
		return GlUl.calculateExpiredDate(this.personBirthDate);
	},

	remove() {
		this.model.destroy();
	},

	render() {
		const passportTypes = STATE.getSettings().thirdStepSettings.passengerTypeDocument[0].passportTypes;
		const countriesCollection = SelectWidget.dictionaryToCollection(STATE.getCountries());

		this.setElement(this.template(
			Object.assign(
				{},
				this.model.attributes,
				{
					passportTypes: SelectWidget.storeCollection(
						SelectWidget.dictionaryToCollection(passportTypes),
					),
					countries: SelectWidget.storeCollection(countriesCollection),
				},
			),
		));
	},

});
