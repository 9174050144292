// eslint-disable-next-line banned-modules
'use strict';

import './style.less';
import BaseView from '@/classes/base.view';
import template from './template.ejs';
import PriceBlockContainer from './b-offer-price-block.ejs';
import TravelPolicyTemplate from './b-travel-policy.ejs';
import OffersTemplate from './b-offers/template.ejs';
import OffersSummaryTemplate from './b-offers/template.summary.ejs';
import offerEssentialTemplate from './b-offers/template-offer-essential.ejs';
import BaseCollection from '@/classes/base.collection';
import Gmaps from '@/blocks/utils/b-google-maps/index';
import HotelSlider from './b-hotel-slider/index';
import $ from 'jquery';
import axios from 'axios';
import ConfirmationModeHandler from '../../../utils/b-cofirmation-mode-handler';

const DEFAULT_OFFER_PROVIDER_STATE = {
	provider: null,
	subProvider: null,
};

export default BaseView.extend({

	sameOffersProviders: ['OSTROVOK', 'HOTELBOOK'],

	template,

	ui: {
		sliderContainer: '.js-slider',
		slider: '.b-hotel__slider',
		mapContainer: '.b-hotel-extended__description-bottom-map',
		offersContainer: '.b-hotel-offers__wrapper',
		summaryContainer: '.b-hotel-offers__summary-container',
		priceContainer: '.b-hotel-offers-price-block',
		travelPolicyContainer: '.b-hotel__travel-policy',
	},

	events: {
		'click .b-hotel__expand': 'toggleDescription',
		'click .b-hotel__select-offer': 'toggleOffers',
		'click .b-hotel-offer__services-more': 'moreServices',
		'click .b-hotel-offer__select-button': 'selectOffer',
		'click .b-hotel-offer__select-button--offer': 'addOffer',
		'click .b-hotel__providers-item': 'scrollToOffer',
		'click .b-hotel__filtered-link': 'showFilteredOffers',
		'click .b-hotel-offer__additional-prices': 'showGroupedOffers',
		'click .js-show-map': 'showOnMap',
		'click .b-hotel__mobile-close-btn': 'toggleOffers',
		'click .js-getIntentionFormSettings': 'updateOfferCancellationTerms',
	},

	preinitialize(options) {
		BaseView.prototype.preinitialize.call(this, options);
		this.windowEventListenerList.push({
			mediaQuery: '(max-width: 768px)',
			name: 'change',
			callback: this.adjustMobileTemplate.bind(this),
			isMatchMedia: true,
		});
	},

	initialize() {
		this.hotel = this.options.hotel;
		this.model = this.options.model;
		this.showGdsAccountName = this.options.parent.showGdsAccountName;
		this.isFirstToggleOffers = true;
		this.roomsCount = this.options.parent.roomsCount;
		this.approvalAllowed = this.options.parent.approvalAllowed;
		this.issueAllowed = this.options.parent.issueAllowed;
		this.isMapApiAvailable = this.options.parent.isMapApiAvailable;
		this.renderCount = 0;
		this.hotelOffers = this.model.get('hotelOffers');
		this.model.set('providerList', this.getHotelOffersProviderList());
		this.sameOffersSet = new Set(); // <- зависит от this.sameOffersProviders
		this.offerProvider = { ...DEFAULT_OFFER_PROVIDER_STATE };

		this.confirmationModeHandler = new ConfirmationModeHandler();

		// Убрано в рамках IBECORP-6495.
		// if (!_.isEmpty(this.hotelOffers) && _.some(this.hotelOffers, (offer) => !offer.token)) {
		// 	this.hotelOffers = [];
		// }

		_.each(this.hotelOffers, (offer, index) => {
			offer.__index = index;
		});

		this.$el.find('.b-hotel-offers__summary-container').html(OffersSummaryTemplate({
			offers: this.roomsCountOffer || {},
			approvalAllowed: this.approvalAllowed,
			issueAllowed: this.issueAllowed,
		}));

		this.$el.find('[data-toggle="tooltip"]').tooltip({content: 'body'});

		this.setPenalties();
		this.addEventListeners();
	},

	delegateEvents(...args) {
		BaseView.prototype.delegateEvents.apply(this, args);
	},

	undelegateEvents(...args) {
		BaseView.prototype.undelegateEvents.apply(this, args);
	},

	adjustMobileTemplate() {
		clearTimeout(this.timer);
		this.timer = setTimeout(() => {
			this.slider.calculateSizes();
		}, 100);
	},

	addEventListeners() {
		this.listenTo(this.model, 'visibilityChange', (model, value) => {
			this.model.set('visibility', value);

			if (value === true) {
				this.$el.addClass('b-hotel__visible');
			} else {
				this.$el.removeClass('b-hotel__visible');
			}
		});

		this.listenTo(this.model, 'filterOffers', this.filterOffers.bind(this));
		this.listenTo(this.model, 'beforeFilterOffers', this.changeRelativeModel.bind(this));

		if (this.roomsCount > 1) {
			this.roomsCountOffer = {};
			const collection = this.model.set('roomsCountOffer', new BaseCollection());

			let scrollTimeout;
			this.listenTo(collection, 'all', (eventName, model, val) => {
				if (_.isString(eventName) && eventName.startsWith('change:roomsCountOffer')) {
					const __index = +eventName.split('.')[1];

					if (scrollTimeout != null) {
						clearTimeout(scrollTimeout);
					}

					// здесь нужно затереть или добавить глобального поставщика

					if (val) {
						const offer = _.find(this.hotelOffers, (o) => o.__index === __index);

						if (!offer) {
							return;
						}

						this.roomsCountOffer[__index] = {
							offer,
							roomsCount: val,
						};

						// выставляем провайдера и субпровайдера
						if (offer.provider) {
							this.offerProvider.provider = offer.provider;
							if (offer.subProvider) this.offerProvider.subProvider = offer.subProvider;
						}
					} else if (this.roomsCountOffer[__index] != null) {
						delete this.roomsCountOffer[__index];
						if (_.isEmpty(this.roomsCountOffer)) {
							// сбрасываем провайдера, если нет выбранных комнат
							this.offerProvider = { ...DEFAULT_OFFER_PROVIDER_STATE };
						}
					}

					const sum = _.reduce(this.roomsCountOffer, (v, obj) => v + obj.roomsCount, 0) || 0;

					this.ui.summaryContainer.html(OffersSummaryTemplate({
						offers: this.roomsCountOffer,
						approvalAllowed: this.approvalAllowed,
						issueAllowed: this.issueAllowed,
						roomsNumber: this.roomsCount,
					}));
					this.ui.summaryContainer.find('[data-toggle="tooltip"]').tooltip({container: 'body'});

					/*
						Проверяем здесь ДО кол-ва комнат, но не убираем проверку на кол-во комнат.
						В проверке на кол-во комнат учитываем задизейбленные-по-провайдеру инпуты.
					*/

					this.handleNumerablePickersEditableStateByProvider();

					if (sum >= this.roomsCount) {
						/* Это кейс при выборе максимального числа комнат */

						this.$el.find('.b-numerable-picker__item-plus').addClass('disabled');
						this.$el
							.find('.b-numerable-picker')
							.not('.has-value')
							// .not('.disabled')
							.addClass('disabled')
							.tooltip({
								container: 'body',
								title: L10N.get('hotels.maxRoomsLimit'),
							});

						// this.ui.summaryContainer.find('[data-toggle="tooltip"]').tooltip('disable');

						if (this.issueAllowed) {
							this.ui.summaryContainer.find('.b-hotel__summary-select--select').click(this.selectOffer.bind(this));
						}
						if (this.approvalAllowed) {
							this.ui.summaryContainer.find('.b-hotel__summary-select--offer').click(this.addOffer.bind(this));
						}
						scrollTimeout = setTimeout(this.scrollToElement(this.ui.summaryContainer, 50).bind(this), 1000);
					} else {
						this.ui.summaryContainer.find('[data-toggle="tooltip"]').tooltip('enable');

						/*
							Берем не просто все пикеры карточки, а только те, которые не были задизейблены по провайдеру.
						*/
						const $pickers = this.$el.find('.b-numerable-picker').filter((index, el) => {
							if ($(el).data('disabledByProviderMismatch')) return false;
							return true;
						});

						$pickers.find('.b-numerable-picker__item-plus').removeClass('disabled');
						$pickers.removeClass('disabled').tooltip('dispose');
					}
				}
			});
		}
	},

	parseAdditionalDataFromNumerablePicker(el) {
		if (!el.dataset.additional) return null;
		const PAIR_DIVIDER = '++';
		const VALUE_DIVIDER = ':::';
		const pairsArr = el.dataset.additional.split(PAIR_DIVIDER);
		if (pairsArr.length) {
			const output = {};
			for (let i = 0; i < pairsArr.length; i++) {
				const pair = pairsArr[i];
				const rawPairDataArr = pair.split(VALUE_DIVIDER);
				if (rawPairDataArr.length === 2) {
					output[rawPairDataArr[0]] = rawPairDataArr[1];
				}
			}
			return output;
		}
		return null;
	},

	getOfferIdsToDisableNumerablePickers() {
		const offerIdsToDisable = new Set();
		(this.hotelOffers || []).forEach((offer) => {
			if (!offer.id) return;
			if (!offer.provider) {
				offerIdsToDisable.add(offer.id);
				return;
			}
			if (offer.provider.uid !== this.offerProvider.provider.uid) {
				offerIdsToDisable.add(offer.id);
				return;
			}
			if (this.offerProvider.subProvider
				&& offer.subProvider
				&& this.offerProvider.subProvider !== offer.subProvider) {
				offerIdsToDisable.add(offer.id);
			}
		});
		return offerIdsToDisable;
	},

	handleNumerablePickersEditableStateByProvider() {
		const $pickers = this.$el.find('.js-b-numerable-picker-hotels');
		if (this.offerProvider.provider) {
			const offerIdsToDisable = this.getOfferIdsToDisableNumerablePickers();
			if (offerIdsToDisable.size) {
				$pickers.each((index, el) => {
					if (el.dataset.additional) {
						const parsedData = this.parseAdditionalDataFromNumerablePicker(el);
						if (parsedData !== null && 'offerId' in parsedData && offerIdsToDisable.has(parsedData.offerId)) {
							$(el)
								.data('disabledByProviderMismatch', true)
								.addClass('disabled')
								.tooltip({
									container: 'body',
									title: L10N.get('hotels.differentProvidersMsg'),
								});
						}
					}
				});
			}
		} else {
			$pickers.find('.b-numerable-picker__item-plus').removeClass('disabled');
			$pickers.removeData('disabledByProviderMismatch').removeClass('disabled').tooltip('dispose');
		}
	},

	setPenalties() {
		const penaltiesArr = [];
		_.each(this.model.get('hotelOffers'), (offer) => {
			if (offer.offerWithPenalty) {
				penaltiesArr.push({
					uid: 'withPenalty',
					caption: 'Невозвратный тариф',
				});
			}
			if (!offer.offerWithPenalty) {
				penaltiesArr.push({
					uid: 'withoutPenalty',
					caption: 'Возвратный тариф',
				});
			}
		});

		const penaltiesToModel = [];
		penaltiesArr.forEach(penalty => {
			if (penaltiesToModel.length === 0) {
				penaltiesToModel.push(penalty);
			} else {
				if (!penaltiesToModel.some(penaltyToModel => penaltyToModel.uid === penalty.uid)) {
					penaltiesToModel.push(penalty);
				}
			}
		});

		this.model.set('penalties', penaltiesArr);
	},

	getGroupedOffers(offersList) {
		const roomNames = [];
		const result = [];

		for (let i = 0; i < offersList.length; i++) {
			const offer = offersList[i];
			const roomName = offer.roomName;
			const index = roomNames.indexOf(roomName);
	
			if (index > -1) {
				result[index].push(offer);
			} else {
				roomNames.push(roomName);
				result.push([offer]);
			}
		}

		return result;
	},

	createGroupedOffersList(offers = []) {
		// Группируем офферы не только по номеру комнаты, но и по состоянию поля "filtered".
		let {
			nonFilteredOffers,
			filteredOffers,
		} = offers.reduce((acc, offer) => {
			if (offer.filtered) {
				acc.filteredOffers.push(offer);
			} else {
				acc.nonFilteredOffers.push(offer);
			}
			return acc;
		}, {
			nonFilteredOffers: [],
			filteredOffers: [],
		});

		nonFilteredOffers = this.getGroupedOffers(nonFilteredOffers);
		filteredOffers = this.getGroupedOffers(filteredOffers);

		return nonFilteredOffers.concat(filteredOffers);
	},

	groupOffers(offers) {
		if (!offers || !offers.length) {
			return offers;
		}

		offers = this.compareMultiRoomSameOffers(offers);

		const result = this.createGroupedOffersList(offers);

		return this.setGroupLeadOffer(_.filter(result, (el) => el.length));
	},

	compareMultiRoomSameOffers(offers) {
		if (!this.options.parent.manyRooms) return offers;
		let result = [];
		_.each(offers, (offer) => {
			if (this.sameOffersProviders.includes(offer.provider.uid)) {
				if (!offer.id || !offer.token) return;
				const offerId = offer.id;
				const sameOffers = _.filter(offers, (o) => o.id === offerId);
				if (_.size(sameOffers) > 1) {
					const sameOffersInSet = _.filter(Array.from(this.sameOffersSet), (o) => o.id === offerId);
					if (sameOffersInSet.length < this.roomsCount) {
						_.each(sameOffers, (o) => this.sameOffersSet.add(o));
					}
					result.push(sameOffers);
				}
			} else {
				result.push(offer);
			}
		});
		result = _.uniq(_.flatten(result), (o) => o.id);
		return !_.isEmpty(result) ? result : offers;
	},

	setGroupLeadOffer(groups = []) {
		const sorting = this.options.parent.getActiveSorting();
		const {collection} = this.options.parent.parent.filtersView;
		const fsValue = this.getFiltersValue(collection.models, ['tripartiteContract']);
		const groupsWithRecommendedOffer = [];
		let updateModelByOffer = false;

		_.each(groups, (g) => {
			g = _.sortBy(g, (o) => {
				if ((sorting != null && sorting.value === 'recommended' && o.tripartiteContract) || (fsValue.tripartiteContract && o.tripartiteContract)) {
					updateModelByOffer = true;
					groupsWithRecommendedOffer.push(g);
					return -1;
				}
				return 0;
			});
		});

		if (groupsWithRecommendedOffer.length) {
			groups = _.sortBy(groups, (g) => {
				if (groupsWithRecommendedOffer.includes(g)) {
					return -1;
				}
				return 0;
			});
		}
		if (updateModelByOffer) this.changeModelByOffer(groups[0][0]);

		return groups;
	},

	filterOffersProcess(isFilterModelOffers) {
		if (this.options.parent && this.options.parent.parent) {
			const fields = [
				'price', 'travelPolicyCompliances', 'availability', 'mealIncluded', 'services',
				'providers', 'penalties', 'tripartiteContract', 'subProvider',
			];
			const {collection} = this.options.parent.parent.filtersView;

			const values = this.getFiltersValue(collection.models, fields);
			this.filterOffers(values, isFilterModelOffers);
		}
	},

	filterOffers(filtersValue, isFilterModelOffers) {
		if (_.size(this.hotelOffers) === 0 || _.size(filtersValue) === 0) {
			this.$el.removeClass('all-group-opened-filter');
			this.$el.find('.b-hotel__filtered-link').hide();
			this.$el.find('.b-hotel-offer--filtered').removeClass('b-hotel-offer--filtered');

			return this;
		}

		this.$el.removeClass('b-hotel__show-filtered');

		const applyFilter = ($offer, modelOffer) => {
			if ($offer != null) {
				$offer.addClass('b-hotel-offer--filtered');
			}
			if (isFilterModelOffers && modelOffer) {
				modelOffer.filtered = true;
			}
		};
		const filterProcess = ($offer, modelOffer) => {
			if (!isFilterModelOffers && modelOffer.filtered) {
				applyFilter($offer, null);
				return;
			}
			const offer = modelOffer;
			const {
				availability,
				mealIncluded,
				providers,
				travelPolicyCompliances,
				price,
				penalties,
				tripartiteContract,
			} = filtersValue;

			if (!_.isEmpty(availability)) {
				const values = [];

				if (filtersValue.availability.ONLINE === true) {
					values.push(true);
				}

				if (filtersValue.availability.REQUEST === true) {
					values.push(false);
				}

				if (!values.includes(offer.online)) {
					applyFilter($offer, modelOffer);
					return;
				}
			}

			if (!_.isEmpty(mealIncluded)) {
				const hasOptionsMeal = offer.options != null && !_.isEmpty(offer.options.meals);
				const hasMeal = (offer.meal != null && !['-1', 'nomeal'].includes(offer.meal.uid) &&
					!['-', L10N.get('hotels.withoutMeal')].includes(offer.meal.caption));

				/* Тут чекбоксы, т.е. выбрать можно несколько опций. */

				let shouldApplyFilters = false; // <- applyFilters == hide
				const hasSelectedFilters = filtersValue.mealIncluded
					&& typeof filtersValue.mealIncluded === 'object'
					&& !_.isEmpty(filtersValue.mealIncluded);
				const allFiltersSelected = hasSelectedFilters
					&& _.every(_.keys(filtersValue.mealIncluded), (filterValue) => filterValue === true);

				// начинаем доп. проверки только в том случае, если какой-то из фильтров выбран
				if (hasSelectedFilters && !allFiltersSelected) {
					if (filtersValue.mealIncluded.WITHOUT_MEAL && filtersValue.mealIncluded.OPTIONAL_MEAL) {
						// без питания + не включено => скрыть только включено
						if (hasMeal) shouldApplyFilters = true;
					} else if (filtersValue.mealIncluded.WITHOUT_MEAL && filtersValue.mealIncluded.WITH_MEAL) {
						// без питания + включено => скрыть только не включено
						if (hasOptionsMeal && !hasMeal) shouldApplyFilters = true;
					} else if (filtersValue.mealIncluded.WITHOUT_MEAL) {
						// без питания => скрыть все питание (включено и не включено)
						if (hasOptionsMeal || hasMeal) shouldApplyFilters = true;
					} else if (filtersValue.mealIncluded.WITH_MEAL && filtersValue.mealIncluded.OPTIONAL_MEAL) {
						// включено + не включено => скрыть без питания
						if (!hasOptionsMeal && !hasMeal) shouldApplyFilters = true;
					} else if (filtersValue.mealIncluded.WITH_MEAL) {
						// включено => скрыть не включено и без питания
						if (!hasMeal) shouldApplyFilters = true;
					} else if (filtersValue.mealIncluded.OPTIONAL_MEAL) {
						// не включено => скрыть включено и без питания
						if (!hasOptionsMeal) shouldApplyFilters = true;
					}
				}

				if (shouldApplyFilters) {
					applyFilter($offer, modelOffer);
					return;
				}
			}

			if (!_.isEmpty(price)) {
				const min = price[0];
				const max = price[1];

				const p = parseFloat(offer.price.total.amount) / (this.model.get('numberOfNights') || 1);

				if (p < min || p > max) {
					applyFilter($offer, modelOffer);
					return;
				}
			}

			if (!_.isEmpty(providers)) {
				if (!_.keys(providers).includes(offer.provider.uid)) {
					applyFilter($offer, modelOffer);
					return;
				}
			}
			
			if (!_.isEmpty(travelPolicyCompliances)) {
				const rules = _.map(offer.travelPolicyCompliance.rulesApplied, (rule) => rule.action.uid);
				const filterRules = _.keys(travelPolicyCompliances);

				if (!_.some(filterRules, (el) => rules.includes(el))) {
					applyFilter($offer, modelOffer);
					return;
				}
			}

			if (!_.isEmpty(penalties)) {
				const values = [];
				if (penalties.withPenalty === true) {
					values.push(true);
				} else {
					values.push(undefined);
				}
				if (!values.includes(offer.offerWithPenalty)) {
					applyFilter($offer, modelOffer);
					return;
				}
			}

			if (tripartiteContract != null && tripartiteContract === true) {
				if (offer.tripartiteContract !== tripartiteContract) {
					applyFilter($offer, modelOffer);
					return;
				}
			}
			if ($offer) $offer.removeClass('b-hotel-offer--filtered');
		};
		const $offers = this.$el.find('.b-hotel-offer');
		const modelOffers = this.model.get('hotelOffers');

		if (isFilterModelOffers) {
			_.each(modelOffers, (modelOffer) => {
				if (!modelOffer) return;
				modelOffer.filtered = false;
				filterProcess(null, modelOffer);
			});
		} else {
			_.each($offers, (element) => {
				const $offer = $(element);
				const index = parseInt($offer.attr('data-offer-index'));

				const offer = _.find(this.hotelOffers, (o) => o.__index === index);
				if (index == null || offer == null) {
					return;
				}
				const modelOffer = modelOffers.find(el => el.__index === index);

				filterProcess($offer, modelOffer);
			});
		}

		this.model.set('hotelOffers', modelOffers);

		if ($offers.filter('.b-hotel-offer--filtered').length > 0) {
			this.$el.find('.b-hotel__filtered-link').show();
		} else {
			this.$el.find('.b-hotel__filtered-link').hide();
		}

		return this;
	},

	orderOffersByCategory() {
		const {category = {}, $el} = this.options.parent || {};
		const {collection} = this.options.parent.parent.filtersView;
		const filtersValue = this.getFiltersValue(collection.models, ['tripartiteContract']);

		let offers = _.sortBy(this.model.get('hotelOffers'), (o) => {
			const price = parseInt(o.price.total.amount, 10);
			return (price === 0 || isNaN(price)) ? Number.MAX_SAFE_INTEGER : price;
		});

		const sorting = this.options.parent.getActiveSorting();
		if (this.options.parent != null && this.options.parent.hasTravelPolicy === true && sorting != null && sorting.value === 'travel_policy') {
			offers = _.sortBy(offers, (o) => {
				if (o.tripartiteContract === true || o.provider.uid === 'INTERNAL' || ((o.travelPolicyCompliance != null &&
					!_.isEmpty(o.travelPolicyCompliance.rulesApplied) &&
					_.some(o.travelPolicyCompliance.rulesApplied, (el) => el.action.uid === 'RECOMMENDED')))) {
					return -1;
				}

				return 0;
			});
		}

		if ((sorting != null && sorting.value === 'recommended') || filtersValue.tripartiteContract === true) {
			offers = _.sortBy(offers, (o) => {
				if (o.tripartiteContract === true) {
					return -1;
				}
				return 0;
			});
		}

		if (category.value === 'NO_PENALTIES') {
			offers = _.sortBy(this.model.get('hotelOffers'), (o) => o.offerWithPenalty === true);
			this.model.set('hotelOffersWithPenalties', offers);
		} else {
			this.model.set('hotelOffersWithPenalties', []);
		}

		if (category.value === 'ALL_ROOMS' && $el instanceof $ && !$el.hasClass('all-group-opened')) {
			$el.addClass('all-group-opened');
		}

		this.model.set('hotelOffers', offers);
		const byOffer = _.find(offers, (o) => !o.filtered);
		this.changeModelByOffer(byOffer);
	},

	getHotelOffersProviderList(provider = this.model.get('provider')) {
		const offers = this.model.get('hotelOffers');
		let result = [];
		result.push(provider.caption);

		_.each(offers, (o) => {
			if (o && o.provider && !result.includes(o.provider.caption)) {
				result.push(o.provider.caption);
			}
		});

		// Следующие 2 строки убраны в рамках IBECORP-6495.
		// if (result.length === 1) return [];
		// result = _.filter(result, (p) => p !== provider.caption);
		if (result.length > 1) {
			result = [...new Set([...result])];
		}
		
		return result;
	},

	changeRelativeModel(filtersValue) {
		let offers = this.model.get('hotelOffers');

		if (_.isEmpty(offers)) {
			return;
		}

		let sortedOffers = [];
		let firstOffer = offers[0];

		if (!_.isEmpty(filtersValue)) {
			const sortingFuncs = [];
			const {
				availability,
				mealIncluded,
				providers,
				travelPolicyCompliances,
				price,
				tripartiteContract,
			} = filtersValue;

			if (!_.isEmpty(price)) {
				offers = _.filter(offers, (o) => {
					const total = parseFloat(o.price.total.amount) / (this.model.get('numberOfNights') || 1);
					return total >= price[0] && total <= price[1];
				});
			}

			if (!_.isEmpty(availability)) {
				sortingFuncs.push((o) => !(filtersValue.availability.ONLINE ? o.online === true : o.online !== true));
			}

			if (!_.isEmpty(mealIncluded)) {
				sortingFuncs.push((o) => {
					const mealIncludedFlag = o.meal != null && !['-1', 'nomeal'].includes(o.meal.uid) &&
						!['-', L10N.get('hotels.withoutMeal')].includes(o.meal.caption);
					return !(filtersValue.mealIncluded.WITH_MEAL ? mealIncludedFlag : !mealIncludedFlag);
				});
			}

			if (!_.isEmpty(providers)) {
				sortingFuncs.push((o) => !_.keys(providers).includes(o.provider.uid));
			}

			if (!_.isEmpty(travelPolicyCompliances)) {
				sortingFuncs.push((o) => {
					if (o.travelPolicyCompliance == null) {
						return true;
					}

					const rules = _.map(o.travelPolicyCompliance.rulesApplied, (rule) => rule.action.uid);
					const filterRules = _.keys(travelPolicyCompliances);

					return !_.some(filterRules, (el) => rules.includes(el));
				});
			}

			if (tripartiteContract != null && tripartiteContract === true) {
				offers = _.filter(offers, (o) => {
					return o.tripartiteContract === tripartiteContract;
				});
			}

			sortingFuncs.push((o) => o.tripartiteContract === true);

			sortedOffers = (!_.isEmpty(sortingFuncs)) ? _.sortBy(offers, (o) => _.some(_.map(sortingFuncs, (func) => func(o)))) : offers;

			if (!_.isEmpty(sortedOffers)) {
				firstOffer = sortedOffers[0];
			}
		}

		this.changeModelByOffer(firstOffer);
	},

	changeModelByOffer(offer) {
		if (offer == null) {
			return this;
		}

		const minPrice = parseInt(offer.price.total.amount, 10);
		if (minPrice !== this.model.get('price') / (this.model.get('numberOfNights') || 1)) {
			const provider = offer.provider || {};
			const tpc = offer.travelPolicyCompliance || {};

			this.model.set('minPriceObject', offer.price);
			this.model.set('offerDailyAveragePrice', offer.dailyAveragePrice);
			this.model.set('price', minPrice / (this.model.get('numberOfNights') || 1));
			this.model.set('provider', provider);
			this.model.set('providerList', this.getHotelOffersProviderList(provider));
			this.model.set('mintaxInfoList', offer.taxInfoList);
			this.model.set('travelPolicyCompliance', tpc);
			this.model.set('tripartiteContract', offer.tripartiteContract);

			if (_.isEmpty(tpc)) {
				this.model.set('travelPolicyCompliances', {caption: L10N.get('hotels.withoutMeal'), uid: 'COMPLIANCE'});
			} else {
				this.model.set('travelPolicyCompliances', _.map(tpc.rulesApplied, (rule) => rule.action));
			}
		}

		return this;
	},

	filterHotelOfferProviderList(offer) {
		/*
			Смысл этой функции - собрать список поставщиков (основной поставщик + счетчик)
			для блока с ценами на карточке отеля.
			Т.к. в значениях фильтров хранятся только ЗНАЧЕНИЯ, из-за чего мы не можем сопоставить
			поставщиков в оффере с фильтрами, здесь не получится использовать getFiltersValue().
			Поэтому чтобы сопоставить caption поставщика с фильтром, нам нужно достать из моделей фильтров поставщиков
			больше данных (uid и caption).
		*/
		const { collection } = this.options.parent.parent.filtersView;
		const providerModels = _.filter(collection.models, (model) => model && model.get('field') === 'providers');
		const providerSelectedFiltersData = {};
		if (providerModels?.length) {
			const providerModel = providerModels.at(0);
			if (providerModel && providerModel.get('values')) {
				providerModel.get('values').forEach(pModel => {
					const caption = pModel.get('caption');
					if (!providerSelectedFiltersData[caption] && pModel.get('value')) {
						providerSelectedFiltersData[caption] = {
							caption,
							uid: pModel.get('uid'),
						};
					}
				});
			}
		}
		
		const providerList = offer.providerList || this.model.get('providerList');
		
		const noProviderSelectedFiltersData = _.isEmpty(providerSelectedFiltersData);

		return providerList ? providerList.filter(p => {
			if (noProviderSelectedFiltersData) return true;
			return !!providerSelectedFiltersData[p];
		}) : [];
	},

	renderPriceBlockTemplate() {
		this.ui.priceContainer.html(PriceBlockContainer({
			offers: [],
			model: this.model,
			parentView: this,
			showGdsAccountName: this.showGdsAccountName,
		}));
		const travelPolicyCompliance = this.model.get('travelPolicyCompliance');
		const tripartiteContract = this.model.get('tripartiteContract');
		if ((travelPolicyCompliance != null && !_.isEmpty(travelPolicyCompliance.rulesApplied)) || tripartiteContract === true) {
			this.ui.travelPolicyContainer.html(TravelPolicyTemplate({
				travelPolicyCompliance,
				tripartiteContract,
			}));
		}
	},

	renderPriceBlock() {
		this.renderPriceBlockTemplate();
		this.bindWidgets();
	},

	render(...args) {
		BaseView.prototype.render.apply(this, args);
		this.initializeSlider = this.initializeSlider.bind(this);
		_.defer(this.initializeSlider);

		this.renderOffers();
		this.bindWidgetsOnSecondRender();

		if (!this.options.parent.isMapApiAvailable) {
			this.$el.find('.b-hotel__description-show-map').hide();
		}

		return this;
	},

	bindWidgetsOnSecondRender() {
		this.renderCount++;
		if (this.renderCount > 1) {
			this.bindWidgets();
			this.renderCount = 0;
		}
		return this;
	},

	initializeMap(gmaps) {
		if (_.isEmpty(gmaps) || this.mapInit) {
			return;
		}

		this.mapInit = true;
		const {address} = this.hotel;
		const locationHotel = _.pick(address, ['latitude', 'longitude']);
		try {
			const latLng = new window.google.maps.LatLng(locationHotel.latitude, locationHotel.longitude);

			const options = {
				center: latLng,
				zoom: 16,
				disableDefaultUI: true,
				clickableIcons: false,
				styles: [{
					featureType: 'poi.business',
					elementType: 'labels',
					stylers: [
						{visibility: 'off'},
					],
				}],
			};
	
			this.map = new gmaps.maps.Map(this.ui.mapContainer.get(0), options);
			if (gmaps && gmaps.maps && gmaps.maps.mapTypeId) this.map.setMapTypeId(gmaps.maps.mapTypeId.ROADMAP);
	
			this.marker = new window.google.maps.Marker({
				position: latLng,
				map: this.map,
			});
			Gmaps.setMapControls(this.map, {
				isSimplyMap: true,
			});
		} catch (e) {
			throw e;
		}
	},

	initializeSlider(withoutAnimate = false) {
		this.slider = new HotelSlider({
			images: this.model.get('images'),
			withoutAnimate,
		});
		this.ui.sliderContainer.append(this.slider.$el);
	},

	getBreadcrumbsHeight() {
		const $breadcrumbs = $('.b-breadcrumbs');

		if (!$breadcrumbs.parent('.b-header__blocks').hasClass('fixed')) {
			return $breadcrumbs.outerHeight();
		}

		return 0;
	},

	scrollToElement($element, offset = 0) {
		if ($element == null || $element[0] == null) {
			return $.noop;
		}

		return (() => {
			$('html, body').animate({
				scrollTop: $element.offset().top - offset - 20,
			}, 300);
		});
	},

	updateFilterInfo() {
		if (!this.options.parent || !this.options.parent.parent || !this.options.parent.parent.filtersView) {
			return this;
		}

		const filters = this.options.parent.parent.filtersView.collection.models;
		const priceFilter = _.find(filters, (filter) => filter && filter.get('field') === 'price');

		const prices = _.map(_.sortBy(this.hotelOffers, (offer) => offer.price && parseInt(offer.price.total.amount, 10)), (o) => o.price.total.amount);

		const maxPrice = parseInt(prices[prices.length - 1], 10);
		const minPrice = parseInt(prices[0], 10);

		if (!this.model.get('price')) {
			this.model.set('price', minPrice);
		}

		if (!this.model.get('minPrice') || (minPrice < this.model.get('minPrice'))) {
			this.model.set('minPrice', minPrice / (this.model.get('numberOfNights') || 1));
		}

		if (!this.model.get('maxPrice') || (maxPrice > this.model.get('maxPrice'))) {
			this.model.set('maxPrice', maxPrice / (this.model.get('numberOfNights') || 1));
		}

		if (priceFilter != null) {
			if (maxPrice > priceFilter.get('max')) {
				priceFilter.set('max', maxPrice);
				this.model.set('maxPrice', maxPrice / (this.model.get('numberOfNights') || 1));
				this.options.parent.parent.renderFilters();
			}

			if (minPrice < priceFilter.get('min')) {
				priceFilter.set('min', minPrice);
				this.model.set('price', minPrice / (this.model.get('numberOfNights') || 1));
				this.model.set('minPrice', minPrice / (this.model.get('numberOfNights') || 1));

				this.options.parent.parent.renderFilters();
			}

			priceFilter.collect(this.model);
		}

		return this;
	},

	getFiltersValue(collection = [], fields = []) {
		const models = _.filter(collection, (model) => model && fields.includes(model.get('field')));
		const values = {};

		_.each(models, (model) => {
			if (model.get('active') != null && model.get('active') === false) return;
			const field = model.get('field');
			let value = model.get('value');

			if (model.get('values') != null) {
				const data = _.map(_.filter(model.get('values').models, (m) => m.get('value') === true), (el) => ({
					uid: el.get('uid'),
					value: el.get('value'),
				}));

				value = {};
				_.each(data, (d) => {
					value[d.uid] = d.value;
				});
			}

			values[field] = value;

			if (_.isObject(values[field])) {
				if (_.isArray(values[field]) && model.get('min') === values[field][0] && model.get('max') === values[field][1]) {
					// All range for slider
					delete values[field];
				} else if (_.size(values[field]) === _.size(model.get('values') && model.get('values').models)) {
					// Select all variants for select
					delete values[field];
				}
			}

			if (_.isEmpty(values[field]) && typeof values[field] !== 'boolean') {
				delete values[field];
			}
		});

		return values;
	},

	// EVENTS
	showGroupedOffers(e) {
		const $target = $(e.currentTarget);
		const $group = $target.closest('.b-hotel-offers-group');

		$target.toggleClass('close-group');
		$group.toggleClass('opened-group');
		$group.find('.b-hotel-offers-grouped').toggle();
	},

	showFilteredOffers(e) {
		const $target = $(e.currentTarget);

		if (this.$el.hasClass('b-hotel__show-filtered')) {
			this.$el.removeClass('b-hotel__show-filtered');
			$target.find('span').text(L10N.get('hotels.showFiltered'));
		} else {
			this.$el.addClass('b-hotel__show-filtered');
			$target.find('span').text(L10N.get('hotels.hideFiltered'));
		}
	},

	prepareOffer(e) {
		if (e != null) {
			e.preventDefault();
		}

		const $target = $(e.currentTarget);
		const offerIndex = parseInt($target.attr('data-offer-index'), 10);
		const offerId = $target.attr('data-offer-id');
		const sameOffersList = [...this.sameOffersSet];

		$('.tooltip').remove();

		const stars = this.model.get('hotelStars');
		const rating = parseFloat(this.model.get('sabreRating'));

		let offers = [];
		if (offerIndex != null && offerIndex !== '' && !isNaN(offerIndex)) {
			offers = [_.find(this.hotelOffers, (item) => item.__index === offerIndex)];
		} else if (!_.isEmpty(this.roomsCountOffer)) {
			offers = _.flatten(_.map(_.values(this.roomsCountOffer), (item) => Array(item.roomsCount).fill(item.offer)));
		}
		if (!_.isEmpty(sameOffersList) && offerId != null && offerId !== '') {
			offers = _.filter(sameOffersList, (o) => {
				// if (!o.repriced) return o.id === offerId;
				const fields = ['total', 'totalFee', 'totalTaxes', 'totalFare'];
				_.each(fields, (key) => {
					if (!_.isEmpty(o.price[key]) && o.price[key].oldAmount) {
						o.price[key].amount = o.price[key].oldAmount;
						delete o.price[key].oldAmount;
					}
				});

				if (!_.isEmpty(o.price.rates)) {
					_.each(o.price.rates, (rate) => {
						if (!rate.total.oldAmount) return;
						rate.total.amount = rate.total.oldAmount;
						delete rate.total.oldAmount;
					});
				}
				return o.id === offerId;
			});
		}

		if ((stars != null && !isNaN(stars)) || (rating != null && !isNaN(rating))) {
			const sourceHotels = _.uniq(
				_.map(this.options.parent.views, (v) => v.hotel)
					.concat(
						this.options.parent.mergeHotels,
						this.options.parent.notRenderedHotels,
					),
				'number',
			);
			let hotels = [];

			if (_.size(sourceHotels) === 1) {
				hotels = sourceHotels;
			} else {
				hotels = _.filter(sourceHotels, (h) => {
					return stars != null ? (h.hotelStars >= (stars - 1) && (h.hotelStars <= stars + 1)) :
						(parseFloat(h.sabreRating) >= (rating - 1) && (parseFloat(h.sabreRating) <= rating + 1));
				});
			}

			const allOffers = _.reduce(hotels, (acc, el) => acc.concat(el.hotelOffers), []);
			const minClientFare = _.min(allOffers, (el) => parseFloat(el.price.totalFare.amount));

			_.each(offers, (item, idx) => {
				if (!_.isArray(item) && !_.isObject(item)) delete offers[idx];
			});

			if (minClientFare != null && isFinite(minClientFare.price.totalFare.amount)) {
				const value = minClientFare.price.totalFare.amount;
				_.each(offers, (offer) => {
					offer.minClientFare = value;
				});
			}
		}

		return offers;
	},

	async selectOffer(e) {
		const offers = this.prepareOffer(e);
		
		const goToPassengers = () => {
			if (!_.isEmpty(offers)) {
				STORE.remove(STATE.ROUTES.HOTELS_PASSENGERS);
				STORE.set(STATE.ROUTES.HOTELS_PASSENGERS, {
					offers,
					showGdsAccountName: this.showGdsAccountName,
				});
				STATE.navigate(STATE.ROUTES.HOTELS_PASSENGERS);
			}
		};

		STATE.showLoader();

		let confirmationModeChanged = false;

		try {
			const intentionFormSettingsResponse = await axios.post('/midoffice/ibecorp-b2b/hotels/getIntentionFormSettings', {
				parameters: {offers},
			});
			confirmationModeChanged = intentionFormSettingsResponse.data?.result?.confirmationModeChanged === true;
		} catch (error) {
			logger(error);
		}
		STATE.hideLoader();

		if (confirmationModeChanged) {
			this.confirmationModeHandler.popupHandler.showPopup({
				title: L10N.get('confirmationMode.defaultTitle'),
				text: L10N.get('confirmationMode.defaultText'),
				cancelBtnText: L10N.get('confirmationMode.backToSearch'),
				okBtnText: L10N.get('confirmationMode.doBooking'),
				onOk: () => goToPassengers(),
			});
		} else {
			goToPassengers();
		}
	},

	addOffer(e) {
		const _addOffer = () => {
			const offers = this.prepareOffer(e);
			if (!_.isEmpty(offers)) {
				this.options.parent.addOffer.call(this.options.parent, e, {offers, hotel: this.hotel});
			}
		};
		this.updateOfferCancellationTerms(e, _addOffer);
	},

	moreServices(e) {
		if (e != null) {
			e.preventDefault();
		}

		const $target = $(e.currentTarget);
		$target.closest('.b-hotel-offer__services-list').addClass('b-hotel-offer__services-list--full');
	},

	toggleDescription(e) {
		if (e != null) {
			e.preventDefault();
		}

		this.$el.find('.b-hotel__expand').toggleClass('collapsed');
		if (this.$el.hasClass('b-hotel--description-is-open')) {
			this.$el.find('.b-hotel-extended-description').stop().slideUp(400, () => {
				this.$el.removeClass('b-hotel--description-is-open');
			});
		} else {
			const breadcrumbsHeight = this.getBreadcrumbsHeight();
			const callback = this.scrollToElement(this.$el.find('.b-hotel-extended-description'), breadcrumbsHeight).bind(this);

			this.$el.addClass('b-hotel--description-is-open');
			this.$el.find('.b-hotel-extended-description').stop().slideDown(400, callback);
		}

		if (this.isMapApiAvailable) {
			Gmaps.getApiPromise().then(this.initializeMap.bind(this));
		}
	},

	renderOffers(list = []) {
		if (!this.$el.hasClass('b-hotel__visible')) {
			return this;
		}

		const offers = _.isEmpty(list) ? this.model.get('hotelOffers') : list;
		/*
			В filterOffers(), которая будет вызвана в filterOffersProcess(), есть какая-то мутная логика, которая
			работает примерно так: 
			- сначала вызывается filterOffersProcess(true), который взводит нужные нам поля "filtered";
			- затем в рендере вызывается filterOffersProcess() без "true", который просто рендерит результат.
			Чтобы не ломать эту логику, мы при каждом рендере будем сначала взводить "filtered" у офферов с помощью вызова
			this.filterOffersProcess(true) ПЕРЕД рендером, а потом перерендеривать их через filterOffersProcess().
		*/
		this.filterOffersProcess(true);
		if (this.ui.offersContainer instanceof $) {
			this.ui.offersContainer.html(OffersTemplate.call(this, {
				groups: this.groupOffers(offers),
				numberOfNights: this.model.get('numberOfNights'),
				roomsCount: this.roomsCount,
				approvalAllowed: this.approvalAllowed,
				issueAllowed: this.issueAllowed,
				sameOffersSet: this.sameOffersSet,
				showGdsAccountName: this.showGdsAccountName,
				sameOffersProviders: this.sameOffersProviders,
			}));
			this.ui.offersContainer.find('[data-toggle="tooltip"]').tooltip({container: 'body'});
		}
		
		this.filterOffersProcess();

		return this;
	},

	toggleOffers(e, fn) {
		if (e != null) {
			e.preventDefault();
		}

		if (this.isFirstToggleOffers || _.isEmpty(this.hotelOffers)) {
			this.isFirstToggleOffers = false;

			const params = STATE.getSearchParametrs().toJSON();

			this.hotelOffers = [];
			const showNoOffersPopup = () => {
				const noOffersPopup = new Widgets.Popup({
					content: L10N.get('hotels.noOffers'),
					type: 'danger',
					actions: [{
						label: L10N.get('hotels.close'),
						action: () => {
							noOffersPopup.hide();
						},
					}],
					onClose: () => {
						noOffersPopup.hide();
					},
				});
				noOffersPopup.show();
			};

			const searchOffersRequestComplete = () => {
				STATE.hideLoader();
				if (!_.isEmpty(this.hotelOffers)) {
					_.each(this.hotelOffers, (offer, index) => {
						offer.__index = index;
					});

					this.model.set('hotelOffers', this.hotelOffers);
					this.model.set('providerList', this.getHotelOffersProviderList());
					this.updateFilterInfo();
					this.filterOffersProcess(true);
					this.orderOffersByCategory();
					this.renderOffers();
					
					this.renderPriceBlockTemplate(); // this.bindWidgets() будет вызвано строчкой ниже, поэтому нам не нужен тут renderPriceBlock()
					this.bindWidgets();
					this.toggleOffers();
				} else {
					showNoOffersPopup();
					this.hotelOffers = [];
					this.noOffers = true;
				}
			};

			const searchOffersRequest = (token) => {
				if (token != null) {
					params.parameters = _.extend(params.parameters, {
						restHotelToken: token,
					});
				}

				params.parameters = _.extend(params.parameters, {
					hotelInfo: {
						hotelCardNumber: this.model.get('number'),
						hotelCity: this.hotel.address.cityInfo && this.hotel.address.cityInfo.city ? this.hotel.address.cityInfo.city : undefined,
						hotelCountry: this.hotel.address.country,
					},
				});
				delete params.parameters.cityCode;

				this.disableElements(e);
				axios.post('/midoffice/ibecorp-b2b/hotels/searchOffers', params).then((response) => {
					const data = response.data.result;

					const {restHotelToken, searchFinished = true, hotelCards} = data;

					if (!_.isEmpty(hotelCards)) {
						_.each(hotelCards, (hotel) => {
							this.model.attributes = _.extend({}, this.model.attributes, hotel);
							this.hotelOffers = [...this.hotelOffers, ...hotel.hotelOffers];
						});
					}

					if (searchFinished !== true) {
						searchOffersRequest(restHotelToken);
					} else {
						searchOffersRequestComplete();
					}
				}).catch((err) => {
					if (axios.isCancel(err)) {
						logger.error(err);
					} else {
						throw new Error(err);
					}
				});
			};

			STATE.showLoader();

			if (this.noOffers === true) {
				showNoOffersPopup();
			} else {
				searchOffersRequest();
			}

			return this;
		} else {
			if (this.isFirstToggleOffers) {
				this.isFirstToggleOffers = false;
			}
			this.bindWidgets();
		}

		if (this.$el.hasClass('b-hotel--description-is-open')) {
			this.toggleDescription(e);
			this.$el.removeClass('b-hotel--offers-is-open b-hotel--description-is-open');
			this.toggleOffers(e);
			return this;
		}

		if (this.$el.hasClass('b-hotel--offers-is-open')) {
			this.$el.find('.b-hotel-extended-offers').stop().slideUp(400, () => {
				this.$el.removeClass('b-hotel--offers-is-open');
			});
		} else {
			const breadcrumbsHeight = this.getBreadcrumbsHeight();
			const callback = fn || this.scrollToElement(this.$el, breadcrumbsHeight + 30).bind(this);

			this.$el.addClass('b-hotel--offers-is-open');
			this.$el.find('.b-hotel-extended-offers').stop().slideDown(400, () => {
				if (_.isFunction(callback)) {
					callback.call(this);
				}
			});
		}
		if (STATE.checkViewport('(max-width: 768px')) this.toggleMobileHotelView();

		return this;
	},

	toggleMobileHotelView() {
		const isOpen = this.$el.hasClass('show-mobile-view');
		this.$el.toggleClass('show-mobile-view', !isOpen);
		$('.l-layout').toggleClass('show-hotel-offer-preview', !isOpen);

		const $showFullTextBtn = this.$('.b-hotel__mobile-description-show-btn');

		if (isOpen) {
			$showFullTextBtn.off();
		} else {
			$showFullTextBtn.on('click', this.showFullText.bind(this));
		}
	},

	showFullText(e) {
		const $target = $(e.currentTarget);
		const isShown = $target.hasClass('open');
		this.$('.b-hotel__mobile-description-text').toggleClass('open', !isShown);
		$target.toggleClass('open', !isShown);
		$target.html(isShown ? L10N.get('hotels.showFullText') : L10N.get('hotels.hide'));
	},

	toggleMobileSliderView(e) {
		if (e) e.preventDefault();
		const isMobile = STATE.checkViewport('(max-width: 768px)');

		if (isMobile && !this.$el.hasClass('show-mobile-view')) this.toggleMobileHotelView();
		const isOpen = this.slider.isFullscreen;

		if (isOpen && $(e.currentTarget).hasClass('b-hotel__slider-slide')) return;
		if (isOpen && $(e.currentTarget).hasClass('b-hotel__mobile-close-slider-btn')) this.toggleMobileHotelView();
		this.slider.toggleFullscreen();
	},

	scrollToOffer(e) {
		if (e != null) {
			e.preventDefault();
		}
		this.toggleOffers(e);
	},

	showOnMap(e) {
		if (e instanceof Event) {
			e.preventDefault();
		}
		this.options.parent.showHotelOnMap(this.hotel);
	},

	updateOfferCancellationTerms(e, addOffer) {
		if (!e) return;
		e.preventDefault();
		this.disableElements(e);
		STATE.showLoader();
		const $offer = $(e.currentTarget).parents('.b-hotel-offer');
		const index = parseInt($offer.attr('data-offer-index'));
		const offer = _.find(this.hotelOffers, (o) => {
			return o.__index === index;
		});

		function addNRenderOffer(offerToRender = {}) {
			$offer.find('.js-offer-essential-wrapper')
				.html(offerEssentialTemplate({ offer: offerToRender }));
			$offer.find('.js-offer-essential-wrapper').find('[data-toggle="tooltip"]').tooltip({ content: 'body' });
			if (addOffer !== undefined) {
				addOffer();
			}
		}

		if (!offer || !((!offer.freeCancellationTerm && !offer.cancellationPolicyText) || offer.cancellationPolicyNextStep)) {
			STATE.hideLoader();
			addNRenderOffer(offer);
			return;
		}

		/*
			"offersToSend" - здесь могут быть сгруппированные офферы от sameOffersProviders.
			Если таковые не находятся в prepareOffer(e), значит действуеv по старой логике с одним оффером.
		*/

		let offersToSend = this.prepareOffer(e);

		if (_.isEmpty(offersToSend)) {
			offersToSend = [offer];
		}

		axios.post('/midoffice/ibecorp-b2b/hotels/getIntentionFormSettings', {
			parameters: {offers: offersToSend},
		}).then((response) => {
			STATE.hideLoader();
			const {hotelCard = {}} = response.data.result || {};
			this.hotelOffers[index] = _.extend({}, this.hotelOffers[index], {...(hotelCard.hotelOffers || [])[0] || {}});
			addNRenderOffer(this.hotelOffers[index]);
		}).catch((err) => {
			STATE.hideLoader();
			if (axios.isCancel(err)) {
				logger.error(err);
			} else {
				throw new Error(err);
			}
		});
	},

});
