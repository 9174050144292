module.exports = function(obj) {
obj || (obj = {});
var __t, __p = '', __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
with (obj) {
__p += '<div class="b-ticket-info">\r\n	<div class="b-ticket-info__header">\r\n		' +
((__t = (require('@/blocks/elements/b-ticket/flight-service.ejs')({
			carrier: flight.carrier,
            flight: flight,
			services: null
		}) )) == null ? '' : __t) +
'\r\n		<div class="b-ticket-info__header-separator"></div>\r\n		<div class="b-operating-airline">\r\n			';

			var opAirlines = [];
			var opAirlinesObj = {};
			for (var i = 0; i < flight.legs.length; i++ ) {
				var leg = flight.legs[i];
				leg.segments.forEach(function(segment) {
					if (segment.operatingAirlineCompanyName != null) {
						opAirlinesObj[segment.operatingAirlineCompanyName] = true;
					} else if (segment.operatingAirline != null) {
						opAirlinesObj[segment.operatingAirline.caption] = true;
					} else if (segment.airline != null) {
						opAirlinesObj[segment.airline.caption] = true;
					}
				});
			}
			for (var key in opAirlinesObj) {
				opAirlines.push(key);
			}
			;
__p += '\r\n			' +
((__t = ( L10N.get("flightlegs.operatingAirline") )) == null ? '' : __t) +
': ' +
((__t = ( opAirlines.join(', ') )) == null ? '' : __t) +
'\r\n		</div>\r\n	</div>\r\n	<div class="b-ticket-info__legs" style="position: relative;">\r\n    ';
 if (flight.isMultiTicket) { ;
__p += '\r\n      <div\r\n      data-toggle="tooltip"\r\n      data-placement="top"\r\n      data-html="true"\r\n      data-original-title="<div>' +
((__t = ( L10N.get('multiticket_hint') )) == null ? '' : __t) +
'</div>"\r\n      style="position: absolute;\r\n      background: #4A5767;\r\n      color: white;\r\n      width: 50%;\r\n      left: 25%;\r\n      margin: 0 auto;\r\n      border-radius: 3px;\r\n      bottom: 8px;\r\n      z-index: 12;\r\n      text-align: center;">Multi-Ticket</div>\r\n    ';
 } ;
__p += '\r\n		';
 flight.legs.forEach(function(leg, i) { ;
__p += '\r\n			';

			var segmentFirst = leg.segments[0];
			var segmentLast = leg.segments[leg.segments.length-1];
			var techStopInfos = leg.segments.reduce((acc, cur) => {
				if (cur.techStopInfos !== null && cur.techStopInfos.length > 0) {
					_.each(cur.techStopInfos, (stop) => acc.push(stop.location.caption + " (" + stop.location.uid + ") - " + Formatter.formatDurationFromPattern(stop.duration)))
				}
				return acc
			}, [])
			;
__p += '\r\n			<div class="b-ticket-info__legs-leg">\r\n				';
 _.each(leg.segments, function(segment) { ;
__p += '\r\n                    <div class="b-ticket-info__legs-leg-direction">\r\n                        <div class="direction__label">\r\n                            ' +
((__t = ( L10N.get('flightlegs.flight') )) == null ? '' : __t) +
'\r\n                        </div>\r\n                        <div class="direction__value">\r\n                            ' +
((__t = ( segment.departureAirport.uid )) == null ? '' : __t) +
' &rarr; ' +
((__t = ( segment.arrivalAirport.uid )) == null ? '' : __t) +
'\r\n                        </div>\r\n\r\n                        <div class="b-ticket-info__legs-leg-flight-number"><span>' +
((__t = ( L10N.get('flightlegs.flightNumber') )) == null ? '' : __t) +
'</span> <span>' +
((__t = ( segment?.airline?.airlineCode + segment.flightNumber )) == null ? '' : __t) +
'</span></div>\r\n\r\n                        <div class="b-ticket-info__legs-leg-carrier"><span>' +
((__t = ( L10N.get('flightlegs.aircraft') )) == null ? '' : __t) +
'</span> <span>' +
((__t = ( segment.aircraft && segment.aircraft.caption || '-' )) == null ? '' : __t) +
'</span></div>\r\n\r\n                        <div class="b-flight-services__options-wrapper">\r\n                            ';
 if (segment.classOfService) { ;
__p += '\r\n                            <span class="b-flight-services__tariff" ';
 if (segment.servicesDetails && segment.servicesDetails.tariffName) { ;
__p += ' data-toggle="tooltip" data-placement="bottom" title="' +
((__t = ( segment.servicesDetails.tariffName )) == null ? '' : __t) +
'"';
 } ;
__p += '>\r\n								' +
((__t = ( segment.classOfService.caption + (segment.classOfServiceCode != null ? ' (' + segment.classOfServiceCode + ')' : '') )) == null ? '' : __t) +
'\r\n							</span>\r\n                            ';
 } ;
__p += '\r\n                            <ul class="b-flight-services__options">\r\n                                ';

                                    function isFREE(obj) {
                                        return !_.isEmpty(obj) && _.every(obj, function(el) {
                                            return _.isObject(el) && el.nil !== true;
                                        });
                                    }

                                    var cabinLuggageState = 'OFF';
                                    if (isFREE(segment.servicesDetails.freeCabinLuggage) || isFREE(segment.servicesDetails.paidCabinLuggage)) {
                                        cabinLuggageState = 'FREE';
                                    }

                                    var baggageState = 'OFF';
                                    if (isFREE(segment.servicesDetails.freeLuggage) || isFREE(segment.servicesDetails.paidLuggage)) {
                                        baggageState = 'FREE';
                                    }
                                    var cabinLuggageClsName = cabinLuggageState === 'OFF' ?
                                        'g-icon-option-cabin-luggage-grey' : 'g-icon-option-cabin-luggage-' + cabinLuggageState + '-black';
                                    var baggageClsName = 'g-icon-option-baggage-' + baggageState + '-black';
                                ;
__p += '\r\n                                <li\r\n                                    data-toggle="tooltip"\r\n                                    data-placement="bottom"\r\n                                    data-html="true"\r\n                                    data-original-title="' +
((__t = ( _.escape(require('@/blocks/elements/b-ticket/b-ticket-luggage.ejs')({ segments: [segment], type: 'luggage' })) )) == null ? '' : __t) +
'"\r\n                                    class="b-flight-services__option ' +
((__t = ( cabinLuggageClsName )) == null ? '' : __t) +
'"\r\n                                >\r\n                                </li>\r\n                                <li\r\n                                    data-toggle="tooltip"\r\n                                    data-placement="bottom"\r\n                                    data-html="true"\r\n                                    data-original-title="' +
((__t = ( _.escape(require('@/blocks/elements/b-ticket/b-ticket-luggage.ejs')({ segments: [segment], type: 'baggage' })) )) == null ? '' : __t) +
'"\r\n                                    class="b-flight-services__option ' +
((__t = ( baggageClsName )) == null ? '' : __t) +
'"\r\n                                >\r\n                                </li>\r\n                                ';
 if (flight.exchange != null || flight.refund != null) {
                                    var states = _.compact([(flight.exchange || {}).uid, (flight.refund || {}).uid]);
                                    var state = 'FREE';

                                    var hasFreeExchange = false;
                                    _.each(_.values(flight.exchange), function(el) {
                                        if (_.some(_.values(el), function(s) { return s === true; })) {
                                            hasFreeExchange = true;
                                        }
                                    });

                                    var hasFreeRefund = false;
                                    _.each(_.values(flight.refund), function(el) {
                                        if (_.some(_.values(el), function(s) { return s === true; })) {
                                            hasFreeRefund = true;
                                        }
                                    });

                                    if (!hasFreeExchange && !hasFreeRefund) {
                                        state = 'OFF';
                                    }

                                    if ((states[0] === 'OFF' && !states[1]) || (states[0] === 'OFF' && states[0] === states[1])) {
                                        state = 'OFF';
                                    }
                                    var refundClsName = state === 'OFF' ?
                                        'g-icon-option-refund-exchange-grey' : 'g-icon-option-refund-exchange-' + state + '-black';
                                ;
__p += '\r\n                                    <li\r\n                                        data-toggle="tooltip"\r\n                                        data-placement="bottom"\r\n                                        data-html="true"\r\n                                        data-original-title="' +
((__t = ( _.escape(require('@/blocks/elements/b-ticket/b-ticket-refund-exchange.ejs')({ services: {refund: flight.refund, exchange: flight.exchange} })) )) == null ? '' : __t) +
'"\r\n                                        class="b-flight-services__option ' +
((__t = ( refundClsName )) == null ? '' : __t) +
'"\r\n                                    >\r\n                                    </li>\r\n                                ';
 } ;
__p += '\r\n                            </ul>\r\n                        </div>\r\n                    </div>\r\n                ';
 }) ;
__p += '\r\n				<div class="b-ticket-info__legs-leg-info">\r\n					<div class="ticket-info__transplants"></div>\r\n					<div class="ticket-info__description">\r\n						<div class="ticket-info__leg-segment">\r\n							<span class="ticket-info__place">\r\n								<strong>\r\n								';
if (segmentFirst.departureCity) { ;
__p += '\r\n									' +
((__t = ( segmentFirst.departureCity.caption )) == null ? '' : __t) +
',\r\n								';
};
__p += '\r\n								';
if (segmentFirst.departureAirport) { ;
__p += '\r\n									' +
((__t = ( segmentFirst.departureAirport.caption )) == null ? '' : __t) +
'\r\n									<span class="b-ticket__place-iata">(' +
((__t = (segmentFirst.departureAirport.uid )) == null ? '' : __t) +
')</span>\r\n								';
};
__p += '\r\n								</strong>\r\n							</span>\r\n						</div>\r\n						<div>\r\n							';
 var dateDeparture = new Time(segmentFirst.departureDate);  ;
__p += '\r\n							';
 var dateArrival = new Time(segmentLast.arrivalDate);  ;
__p += '\r\n							<span class="ticket-info__date">\r\n								<span class="ticket-info__time">' +
((__t = (dateDeparture.toShortTime())) == null ? '' : __t) +
'</span>\r\n								' +
((__t = ( dateDeparture.toTicketDate() )) == null ? '' : __t) +
'\r\n							</span>\r\n						</div>\r\n\r\n						<div class="ticket-info__leg-travel-duration">\r\n              ';
 if (leg.duration) { ;
__p += '\r\n                ';

                  var legDurationString = L10N.get("flightlegs.legDurationPattern");
                  var legDurationDay = Math.floor(leg.duration / 1440);
                  var legDurationHours = Math.floor((leg.duration % 1440) / 60);
                  var legDurationMin = leg.duration % 60;
                  while (true) {
                    var block = /\[(.*?)\]/.exec(legDurationString);
                    if (!block) break;
                    if (block[1].indexOf("DD") >= 0) {
                      if (legDurationDay === 0) {
                        legDurationString = legDurationString.replace(block[0], "");
                        continue;
                      }
                      legDurationString = legDurationString
                          .replace(block[0], block[1].replace("DD", legDurationDay))
                      continue;
                    }
                    if (block[1].indexOf("HH") >= 0) {
                      if (legDurationDay === 0 && legDurationHours === 0) {
                        legDurationString = legDurationString.replace(block[0], "");
                        continue;
                      }
                      legDurationString = legDurationString
                          .replace(block[0], block[1].replace("HH", legDurationHours))
                      continue;
                    }
                    if (block[1].indexOf("mm") >= 0) {
                      legDurationString = legDurationString
                          .replace(block[0], block[1].replace("mm", legDurationMin))
                      continue;
                    }
                  }
                ;
__p += '\r\n                <span class="travel-duration">' +
((__t = ( legDurationString )) == null ? '' : __t) +
'</span> ' +
((__t = ( L10N.get('flightlegs.travelTime') )) == null ? '' : __t) +
'\r\n              ';
 } ;
__p += '\r\n							';
 if (leg.segments.length > 1) { ;
__p += '\r\n								' +
((__t = ( L10N.get('flightlegs.with') )) == null ? '' : __t) +
' <span class="b-ticket-stops-info">' +
((__t = (leg.segments.length-1 )) == null ? '' : __t) +
' ' +
((__t = (L10N.getPluralForm('flightlegs.stopSecond', leg.segments.length-1))) == null ? '' : __t) +
'</span>\r\n							';
 } ;
__p += '\r\n							';
 if (techStopInfos.length) { 
								if (leg.segments.length > 1) {;
__p += '\r\n									' +
((__t = (', ' )) == null ? '' : __t) +
'\r\n								';
 } ;
__p += '\r\n								' +
((__t = ( L10N.get('flightlegs.with') )) == null ? '' : __t) +
' <span class="b-ticket-stops-info" data-html="true" data-toggle="tooltip" data-placement="bottom" data-original-title="' +
((__t = ( _.map(techStopInfos, function(el) { return '<div class=\'b-leg__segments-duration-stop\'>' + el + '</div>'; }).join('') )) == null ? '' : __t) +
'">' +
((__t = (techStopInfos.length )) == null ? '' : __t) +
' ' +
((__t = (L10N.getPluralForm('flightlegs.techStopSecond', techStopInfos.length))) == null ? '' : __t) +
'</span>\r\n							';
 } ;
__p += '\r\n						</div>\r\n\r\n						<div class="ticket-info__leg-segment">\r\n							<span class="ticket-info__place">\r\n								<strong>\r\n								';
if (segmentLast.arrivalCity) { ;
__p += '\r\n									' +
((__t = ( segmentLast.arrivalCity.caption )) == null ? '' : __t) +
',\r\n								';
};
__p += '\r\n								';
if (segmentLast.arrivalAirport) { ;
__p += '\r\n									' +
((__t = ( segmentLast.arrivalAirport.caption )) == null ? '' : __t) +
'\r\n									<span class="b-ticket__place-iata">(' +
((__t = (segmentLast.arrivalAirport.uid )) == null ? '' : __t) +
')</span>\r\n								';
};
__p += '\r\n								</strong>\r\n							</span>\r\n						</div>\r\n						<div>\r\n							<span class="ticket-info__date">\r\n								<span class="ticket-info__time">' +
((__t = (dateArrival.toShortTime())) == null ? '' : __t) +
'</span>\r\n								' +
((__t = ( dateArrival.toTicketDate() )) == null ? '' : __t) +
'\r\n							</span>\r\n						</div>\r\n					</div>\r\n				</div>\r\n			</div>\r\n		';
 }) ;
__p += '\r\n	</div>\r\n</div>\r\n';

}
return __p
}