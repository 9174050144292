import styled from "styled-components";

export const PopupButton = styled.span`
	text-transform: initial;

  display: none;

  @media only screen and (min-width: 769px) {
    display: inline-block;
  }

`;
