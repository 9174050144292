module.exports = function(obj) {
obj || (obj = {});
var __t, __p = '', __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
with (obj) {


    var passengers = passengers || [];
	var offers = offers || [];
;
__p += '\n<div class="b-offers-avia-modal">\n	<div class="b-offers-avia-modal__title">\n		';
 if (obj.passengers != null) { ;
__p += '\n			' +
((__t = ( L10N.get('ticket.variantsToCoordination') )) == null ? '' : __t) +
' ' +
((__t = ( L10N.get('Common.for') )) == null ? '' : __t) +
': <span class="b-offers-avia-modal__title-person"></span>\n		';
 } else { ;
__p += '\n			' +
((__t = ( L10N.get('ticket.variantsToCoordination') )) == null ? '' : __t) +
'\n		';
 } ;
__p += '\n	</div>\n	<div class="b-offers-avia-modal__list">\n		';
 _.each(obj.offers, function (offer) { ;
__p += '\n		';
 var uid; ;
__p += '\n			';
 if (offer.travelSubject.uid === "AIR") { ;
__p += '\n				';
 uid = offer.uid; ;
__p += '\n				<div class="b-offers-avia-modal__list-item " data-flight-token="' +
((__t = ( offer.flightToken )) == null ? '' : __t) +
'">\n					<div class="b-offers-avia-modal__list-item-drag"></div>\n					<div class="b-offers-avia-modal__list-item-legs">\n						' +
((__t = ( require('../../../../elements/b-ticket/b-ticket-legs/b-ticket-legs.ejs')({
							flight: offer,
							flightToken: ''
						}) )) == null ? '' : __t) +
'\n					</div>\n					<div class="b-offers-avia-modal__list-item-price">\n						<div class="b-offers-avia-modal__list-item-price-container">\n								<span class="b-offers-avia-modal__list-item-price-amount">\n									' +
((__t = ( Formatter.formatMoney(offer.price.total.amount, 0) )) == null ? '' : __t) +
' ' +
((__t = ( Formatter.formatCurrency(offer.price.total.currencyCode) )) == null ? '' : __t) +
'\n								</span>\n							<span>\n									| ' +
((__t = ( L10N.get('Common.behind') )) == null ? '' : __t) +
' <span class="b-offers-avia-modal__title-person-count"></span> ' +
((__t = ( L10N.get('Common.pers') )) == null ? '' : __t) +
'\n								</span>\n						</div>\n					</div>\n					<div class="break"></div>\n				</div>\n				<div class="b-offers-avia-modal__form b-offers-avia-modal__form-control b-offers-avia-modal__list-item-comment">\n					<span class="js-widget"  onclick=\'return new Widgets.Input({bindingProperty: "comments'+ uid +'", placeholder: L10N.get("ticket.comment") })\'></span>\n				</div>\n				<div class="b-offers-avia-modal__list-item-line">\n				</div>\n			';
 } ;
__p += '\n			';
 if (offer.travelSubject.uid === "RAILWAY") {
				uid = offer.uid;
				var carriage = offer.carriages[0];

				var dateDeparture = new Time(carriage.segment.departureDate);
				var dateArrival = new Time(carriage.segment.arrivalDate);

				var dateDepartureLocal = carriage.segment.localDepartureDate ? new Time(carriage.segment.localDepartureDate) : null;
				var dateArrivalLocal = carriage.segment.localArrivalDate ? new Time(carriage.segment.localArrivalDate) : null;

				var totalDurationInMinutes = carriage.segment.durationInMinutes;
			;
__p += '\n				<div class="b-offers-train-modal__list-item" data-token="' +
((__t = ( offer.token )) == null ? '' : __t) +
'">\n					<div class="b-offers-train-modal__list-item-drag "></div>\n					<div class="b-offers-train-modal__list-item-legs">\n						<div class="b-train-ticket">\n							<div class="b-train-ticket__header">\n								<div class="b-train-ticket__info-train b-info-train">\n								<span class="b-info-train__number">\n									№ ' +
((__t = ( carriage.number )) == null ? '' : __t) +
'' +
((__t = ( !carriage.trainBrand && carriage.trainCategory ? ',' : '' )) == null ? '' : __t) +
'\n								</span>\n									';
 if (carriage.trainBrand != null) { ;
__p += '\n										&mdash;\n										<span class="b-info-train__brand">\n										' +
((__t = ( carriage.trainBrand )) == null ? '' : __t) +
'' +
((__t = ( carriage.trainCategory ? ',' : '' )) == null ? '' : __t) +
'\n									</span>\n									';
 } ;
__p += '\n									';
 if (carriage.trainCategory) { ;
__p += '\n										<span class="b-info-train__description">' +
((__t = ( carriage.trainCategory )) == null ? '' : __t) +
'</span>\n									';
 } ;
__p += '\n								</div>\n								';
 if (offer.train.disabled === true) { ;
__p += '\n									<div class="b-train-ticket__info-disabled">\n									<span class="b-train-ticket__info-disabled-icon"\n										  data-toggle="tooltip" data-placement="bottom"\n										  title="' +
((__t = ( STATE.escape(offer.train.disableReason) )) == null ? '' : __t) +
'"></span>\n									</div>\n								';
 } ;
__p += '\n							</div>\n                            <div class="b-train-ticket__mobile">\n                                <div class="b-train-ticket__mobile-dates">\n					                <span class="b-train-ticket__mobile-date-time">\n					                	' +
((__t = ( dateDepartureLocal ? dateDepartureLocal.toShortTime(): '-' )) == null ? '' : __t) +
'\n					                </span>\n                                    <span class="b-train-ticket__mobile-duration">' +
((__t = ( Formatter.formatDurationFromPattern(totalDurationInMinutes) )) == null ? '' : __t) +
'</span>\n                                    <span class="b-train-ticket__mobile-date-time">\n						                ' +
((__t = ( dateArrivalLocal ? dateArrivalLocal.toShortTime() : '-' )) == null ? '' : __t) +
'\n					                </span>\n                                </div>\n                                <div class="b-train-ticket__mobile-route-line"></div>\n                                <div class="b-train-ticket__mobile-route-info">\n                                    <span class="b-train-ticket__mobile-route-location">\n                                        ';
 if (carriage && carriage.segment && carriage.segment.departureLocation) { ;
__p += '\n                                            ' +
((__t = ( carriage.segment.departureLocation.caption.trim() )) == null ? '' : __t) +
'\n                                        ';
 } ;
__p += '\n                                    </span>\n                                    <span class="b-train-ticket__mobile-route-location">\n                                        ';
 if (carriage && carriage.segment && carriage.segment.arrivalLocation) { ;
__p += '\n                                            ' +
((__t = ( carriage.segment.arrivalLocation.caption.trim() )) == null ? '' : __t) +
'\n                                        ';
 } ;
__p += '\n                                    </span>\n                                </div>\n                            </div>\n							<div class="b-train-ticket__route b-route">\n								';

									var dateDepartureTooltip = "<div class='text-align-center'>" + L10N.get('trains.cardLocalInfo');
									if (carriage.segment.departureMoscowTimeZone === true && dateDeparture != null) {
										dateDepartureTooltip += '<br />' + L10N.get('trains.moscowTime') + ' - <strong>' + dateDeparture.toShortTime() + ' ' + dateDeparture.toShortDate() + '</strong>';
									}
									dateDepartureTooltip += '</div>';

									var dateArrivalTooltip = "<div class='text-align-center'>" + L10N.get('trains.cardLocalInfo');
									if (carriage.segment.arrivalMoscowTimeZone === true && dateArrival != null) {
										dateArrivalTooltip += '<br />' + L10N.get('trains.moscowTime') + ' - <strong>' + dateArrival.toShortTime() + ' ' + dateArrival.toShortDate() + '</strong>';
									}
									dateArrivalTooltip += '</div>';
								;
__p += '\n								<div class="b-route__departure">\n									<div class="b-route__date" data-toggle="tooltip" data-html="true" data-placement="bottom" data-original-title="' +
((__t = ( dateDepartureTooltip )) == null ? '' : __t) +
'">\n										<div class="b-route__date-time">' +
((__t = ( (dateDepartureLocal || dateDeparture) != null ? (dateDepartureLocal || dateDeparture).toShortTime() : '-' )) == null ? '' : __t) +
'</div>\n										<div class="b-route__date-info">' +
((__t = ( (dateDepartureLocal || dateDeparture) != null ? (dateDepartureLocal || dateDeparture).toShortDate() : '-' )) == null ? '' : __t) +
'</div>\n									</div>\n									<div class="b-route__terminal">\n										';
 if (carriage && carriage.segment && carriage.segment.departureLocation) { ;
__p += '\n											' +
((__t = ( carriage.segment.departureLocation.caption )) == null ? '' : __t) +
'\n										';
 } ;
__p += '\n									</div>\n								</div>\n								<div class="b-route__info">\n									<div class="b-route__info-duration">' +
((__t = ( Formatter.formatDurationFromPattern(totalDurationInMinutes) )) == null ? '' : __t) +
'</div>\n									<div class="b-route__info-route">\n										<i class="icon-arrow-long"></i>\n									</div>\n								</div>\n								<div class="b-route__arrival">\n									<div class="b-route__date">\n										<div class="b-route__date" data-toggle="tooltip" data-html="true" data-placement="bottom" data-original-title="' +
((__t = ( dateArrivalTooltip )) == null ? '' : __t) +
'">\n											<div class="b-route__date-time">' +
((__t = ( (dateArrivalLocal || dateArrival) != null ? (dateArrivalLocal || dateArrival).toShortTime() : '-' )) == null ? '' : __t) +
'</div>\n											<div class="b-route__date-info">' +
((__t = ( (dateArrivalLocal || dateArrival) != null ? (dateArrivalLocal || dateArrival).toShortDate() : '-' )) == null ? '' : __t) +
'</div>\n										</div>\n										';
 if (carriage.segment.carWithOtherArrivalTimeExists === true) { ;
__p += '\n											<div class="b-route__arrival-notification" data-toggle="tooltip" data-html="true" data-placement="bottom" data-original-title="<div class=\'text-align-center\'>' +
((__t = ( L10N.get('trains.carriageWithDiffTime') )) == null ? '' : __t) +
'</div>"></div>\n										';
 } ;
__p += '\n									</div>\n\n									<div class="b-route__terminal">\n										';
 if (carriage && carriage.segment && carriage.segment.arrivalLocation) { ;
__p += '\n											' +
((__t = ( carriage.segment.arrivalLocation.caption )) == null ? '' : __t) +
'\n										';
 } ;
__p += '\n									</div>\n								</div>\n\n								<div class="b-route__element">\n									' +
((__t = ( L10N.get('trains.carriage') )) == null ? '' : __t) +
': <strong>' +
((__t = ( carriage.number )) == null ? '' : __t) +
'</strong><br />\n\n                  ';
 if (carriage.places) { ;
__p += '\n                    <div>\n                      ';

                      var carPlaceData = carriage.carPlaceData || [];
                      var places = (carriage.places || []).map((place) => parseInt(place, 10));
                      var seatsMap = carPlaceData.reduce(function(seatsMap, carPlace) {
                        carPlace.availableSeats.forEach(function (place) {
                          var currentSeat = parseInt(place, 10);
                          if (currentSeat && places.includes(currentSeat)) {
                            var position = carPlace.carPlaceType.caption || 'common';
                            if (!seatsMap.hasOwnProperty(position)) {
                              seatsMap[position] = [];
                            }
                            seatsMap[position].push(currentSeat);
                          }
                        });
                        
                        return seatsMap;
                      }, {});
  
                      var seatsMapKeys = Object.keys(seatsMap);
                      var seatsMapTooltip = '<div class="b-offer-seats-tooltip-wrapper">';
                        seatsMapKeys.forEach(function (seatsPosition) {
                        seatsMapTooltip += '<div><div class="b-offer-seats-tooltip">' + seatsPosition + '</div><div>' + seatsMap[seatsPosition].join(', ') + '</div></div>';
                      });
                      seatsMapTooltip += '</div>';
                      if (seatsMapKeys.length === 0) {
                        seatsMapTooltip = "";
                      }
                      ;
__p += '\n                      ' +
((__t = ( L10N.get('trains.seats') )) == null ? '' : __t) +
': <span data-toggle="tooltip" data-html="true" data-original-title=\'' +
((__t = ( seatsMapTooltip || carriage.places.join(', ') )) == null ? '' : __t) +
'\'><strong>' +
((__t = ( carriage.places.join(', ') )) == null ? '' : __t) +
'</strong></span><br />\n                    </div>\n                  ';
};
__p += '\n								</div>\n								<div class="b-route__element">\n									<div class="b-train__carriage-service-class">\n                    ';

                    var servicesTooltip = null;
                    var carriageServices = (carriage.services || []);
                    carriageServices.map(function(service) {
                      return service.caption
                    }).forEach(function (service, serviceIndex) {
                      if (!servicesTooltip) {
                        servicesTooltip = '';
                      }
                      servicesTooltip += service + (serviceIndex < carriageServices.length - 1 ? ', <br/>' : '');
                    });
                    ;
__p += '\n\n									<span class="b-train__carriage-service-class-caption" data-toggle="tooltip" data-placement="bottom" title="' +
((__t = ( STATE.escape(servicesTooltip || (carriage.serviceClass && carriage.serviceClass.description)) )) == null ? '' : __t) +
'">\n										' +
((__t = ( carriage.serviceClass && carriage.serviceClass.caption ? carriage.serviceClass.caption : '-' )) == null ? '' : __t) +
'\n									</span>\n									</div>\n								</div>\n							</div>\n\n						</div>\n					</div>\n					<div class="b-offers-train-modal__list-item-price">\n						<div class="b-offers-train-modal__list-item-price-container">\n						<span class="b-offers-train-modal__list-item-price-amount">\n							' +
((__t = ( L10N.get('trains.from') )) == null ? '' : __t) +
' ' +
((__t = ( Formatter.formatMoney(offer.price.total.amount, 0) )) == null ? '' : __t) +
' ' +
((__t = ( Formatter.formatCurrency('RUB') )) == null ? '' : __t) +
'\n						</span>\n							<span>\n							| ' +
((__t = ( L10N.get('trains.for') )) == null ? '' : __t) +
' <span class="b-offers-train-modal__title-person-count"></span> ' +
((__t = ( L10N.get('trains.person') )) == null ? '' : __t) +
'\n						</span>\n						</div>\n					</div>\n				</div>\n				<div class="b-offers-train-modal__form b-offers-train-modal__form-control b-offers-train-modal__list-item-comment">\n					<span class="js-widget"  onclick=\'return new Widgets.Input({bindingProperty: "comments'+ uid +'", placeholder: L10N.get("trains.comment") })\'></span>\n				</div>\n				<div class="b-offers-avia-modal__list-item-line">\n				</div>\n			';
 } ;
__p += '\n			';
 if ((offer.travelSubject.uid === "HOTEL_RESERVATION")) {
				var hotel = offer.hotel;
				var offers = offer.offers; ;
__p += '\n				<div class="b-modal-hotel" data-hotel="' +
((__t = ( hotel.number )) == null ? '' : __t) +
'">\n					<div class="b-modal-hotel__description-container">\n						<div class="b-modal-hotel__description">\n							<div class="b-modal-hotel__description-name">' +
((__t = ( hotel.name )) == null ? '' : __t) +
'</div>\n							';
  var stars = parseInt(hotel.hotelStars, 10);  ;
__p += '\n							';
 if (stars > 0) { ;
__p += '\n								<div class="b-modal-hotel__description-stars b-hotel-stars">\n									';
 for (var i = 0; i < stars; i++) { ;
__p += '\n										<span class="b-hotel-star"></span>\n									';
 } ;
__p += '\n								</div>\n							';
 } ;
__p += '\n						</div>\n						<div class="b-modal-hotel__address">\n							';
 var address;;
__p += '\n							';
 if (hotel.address != null) {
								address = hotel.address;
								var addressString = _.compact([address.address, address.zipCode, (address.cityInfo && address.cityInfo.city || {}).caption, (address.country || {}).caption]).join(', ');
							;
__p += '\n							' +
((__t = ( addressString )) == null ? '' : __t) +
'\n							';
 } ;
__p += '\n							';
 if (!_.isEmpty(hotel.phones)) { ;
__p += '| ' +
((__t = ( hotel.phones.join(', ') )) == null ? '' : __t);
 }

								address = hotel.address;
							if (address != null && address.cityInfo != null) {
								const locationHotel = _.pick(address, ['latitude', 'longitude']);
								const locationCity = _.pick(address.cityInfo, ['latitude', 'longitude']);

							if (!_.isEmpty(locationHotel) && !_.isEmpty(locationCity)) {
									try {
								const distance = window.google.maps ? Math.ceil(window.google.maps.geometry.spherical.computeDistanceBetween(
										new window.google.maps.LatLng(locationHotel.latitude, locationHotel.longitude),
										new window.google.maps.LatLng(locationCity.latitude, locationCity.longitude)
								)) : NaN;

								const distanceValue = parseFloat((distance / 1000).toFixed(2));
							if (!isNaN(distanceValue)) { ;
__p += '\n								| ' +
((__t = ( distanceValue )) == null ? '' : __t) +
' ' +
((__t = ( L10N.get('hotels.distanceFromCenter') )) == null ? '' : __t) +
'\n							';
 }
							} catch (e) {
								throw e;
							}
							}
							} ;
__p += '\n						</div>\n					</div>\n\n					<div class="b-modal-hotel__offers-list">\n						';
 _.each(offers, function (offer) {
								const uid = offer.uid;
								const o = offer.services[0].offer
							;
__p += '\n							<div class="b-offers-hotels-modal__list-item drag-sort-element" data-room-index="' +
((__t = ( o.roomIndex )) == null ? '' : __t) +
'" data-token="' +
((__t = ( o.token )) == null ? '' : __t) +
'">\n								<div class="b-offers-hotels-modal__list-item-drag drag-sort-anchor"></div>\n								<div class="b-offers-hotels-modal__list-item-description b-offer-modal">\n									<div class="b-route__element b-offer-modal__name-container">\n										<div class="b-offer-modal__name" data-toggle="tooltip" data-placement="bottom" data-html="true" data-original-title="<div>' +
((__t = ( _.escape(o.roomName) )) == null ? '' : __t) +
'</div>">' +
((__t = ( o.roomName )) == null ? '' : __t) +
'</div>\n										';
 if (!_.isEmpty(o.essentialInfo)) {
										_.each(o.essentialInfo, function(essential) { ;
__p += '\n											<div class=\'b-offer-modal__essential b-offer-modal__important\' data-toggle="tooltip" data-placement="bottom" data-html="true" data-original-title="<div>' +
((__t = ( _.escape(essential) )) == null ? '' : __t) +
'</div>">' +
((__t = ( essential )) == null ? '' : __t) +
'</div>\n										';
 });
										} ;
__p += '\n									</div>\n									<div class="b-route__element">\n										';
 if (o.cancellationPolicyText || o.offerWithPenalty === true) {
											var cancellationPolicyText = '<div>' +
													((o.offerWithPenalty === true) ? '<div><strong>' + L10N.get('bookingForm.attention') + '</strong>: ' + L10N.get('hotels.refundPenalties') + '</div>' : '') +
													(o.cancellationPolicyText ? (((o.offerWithPenalty === true) ? '<br />' : '') + o.cancellationPolicyText) : '') + '</div>';
										;
__p += '\n										<div>\n											' +
((__t = ( L10N.get('hotels.penalties') )) == null ? '' : __t) +
' <span class="b-hotel-offer__fines ' +
((__t = ( (o.offerWithPenalty === true) ? 'b-hotel-offer__fines-important' : '' )) == null ? '' : __t) +
'" data-toggle="tooltip" data-placement="bottom" data-html="true" data-original-title="' +
((__t = ( _.escape(cancellationPolicyText) )) == null ? '' : __t) +
'">?</span>\n										</div>\n										';
 } ;
__p += '\n										';
 if (o.meal != null) { ;
__p += '\n											<div class="b-hotel-offer__meal" data-toggle="tooltip" data-placement="bottom" title="' +
((__t = ( _.escape(captionMeal) )) == null ? '' : __t) +
'">\n												';
 var captionMeal = (o.meal != null && !['-1', 'nomeal'].includes(o.meal.uid) && !['-', L10N.get('hotels.withoutMeal')].includes(o.meal.caption)) ? o.meal.caption : L10N.get('hotels.withoutMeal'); ;
__p += '\n												' +
((__t = ( captionMeal )) == null ? '' : __t) +
'\n											</div>\n										';
 }
										if (_.some(o.options, function (option) {
													return _.isArray(option) && !_.isEmpty(option);
												})) {
											var optionsHTML = '<div>';
											var options = o.options;
											var checkOptions = [];

											if (!_.isEmpty(options.earlierCheckIn)) {
												checkOptions.push(L10N.get('hotels.earlyCheckIn'));
											}
											if (!_.isEmpty(options.laterCheckOut)) {
												checkOptions.push(L10N.get('hotels.lateCheckout'));
											}

											if (!_.isEmpty(checkOptions)) {
												checkOptions[0] = checkOptions[0].charAt(0).toUpperCase() + checkOptions[0].slice(1);
												optionsHTML += checkOptions.join(', ');
											}

											if (!_.isEmpty(options.meals)) {
												optionsHTML += '<br />' + L10N.get('hotels.meal') + ': <ul class="options-list">';
												optionsHTML += _.map(options.meals, function (m) {
													return '<li>' + m.caption + '</li>';
												}).join('');
												optionsHTML += '</ul>'
											}

											optionsHTML += '</div>'; ;
__p += '\n\n										<div data-toggle="tooltip" data-placement="bottom" data-html="true" data-original-title="' +
((__t = ( _.escape(optionsHTML) )) == null ? '' : __t) +
'">\n											<span class="action-link action-link--color-blue">' +
((__t = ( L10N.get('cabinet.orders.hotelAddServices') )) == null ? '' : __t) +
'</span>\n										</div>\n										';
 } ;
__p += '\n									</div>\n									';
 if (offer.showGdsAccountName) {;
__p += '\n										<div class="b-route__element"> \n											';
 if (o && o.provider) { ;
__p += '\n												' +
((__t = ( o.provider.caption )) == null ? '' : __t) +
'\n											';
 } ;
__p += '\n										</div>\n									';
};
__p += '\n								</div>\n								<div class="b-offers-hotels-modal__list-item-price">\n									<div class="b-offers-hotels-modal__list-item-price-container">\n									<span class="b-offers-train-modal__list-item-price-amount">\n										' +
((__t = ( Formatter.formatMoney(o.price.total.amount, 0) )) == null ? '' : __t) +
'\n										' +
((__t = ( Formatter.formatCurrency(o.price.total.currencyCode) )) == null ? '' : __t) +
'\n									</span>\n										<span>\n										| ' +
((__t = ( L10N.get('hotels.for') )) == null ? '' : __t) +
' <span class="b-offers-hotels-modal__title-person-count"></span> ' +
((__t = ( L10N.get('hotels.person') )) == null ? '' : __t) +
'\n									</span>\n									</div>\n								</div>\n							</div>\n							<div class="b-offers-hotels-modal__form b-offers-hotels-modal__form-control b-offers-hotels-modal__list-item-comment">\n								<span class="js-widget"  onclick=\'return new Widgets.Input({bindingProperty: "comments'+ uid +'", placeholder: L10N.get("hotels.comment") })\'></span>\n							</div>\n						';
 }); ;
__p += '\n					</div>\n\n				</div>\n				<div class="b-offers-hotels-modal__list-item-line">\n				</div>\n			';
 } ;
__p += '\n            ';
 if (offer.travelSubject.uid === "TRANSFER") { ;
__p += '\n            ';
 uid = offer.uid; ;
__p += '\n                <div class="b-offers-transfers-modal__list-item " data-transfer-token="' +
((__t = ( offer.uid )) == null ? '' : __t) +
'">\n                    <div class="b-offers-transfers-modal__list-item-drag"></div>\n                    <div class="b-offers-transfers-modal__list-item-content">\n                        <div class="b-offers-transfers-modal__list-item__photo b-transfer__info-photo" style="background-image: url(\'' +
((__t = ( offer.car && offer.car.imageUrl )) == null ? '' : __t) +
'\')"></div>\n                        <div class="b-offers-transfers-modal__list-item__car-info">\n                            <div class="b-offers-transfers-modal__list-item__car-class">' +
((__t = ( offer.car.carClass && offer.car.carClass.caption || '' )) == null ? '' : __t) +
'</div>\n                            <div class="b-offers-transfers-modal__list-item__models">' +
((__t = ( offer.car.models )) == null ? '' : __t) +
'</div>\n                        </div>\n                        <div class="b-offers-transfers-modal__list-item__restrictions">\n                            <div class="b-transfer__info-capacity">\n                                <span class="b-transfer__info-capacity-icon"></span>\n                                <span class="b-transfer__info-capacity-text">\n                            ' +
((__t = ( L10N.get('Common.until'))) == null ? '' : __t) +
' ' +
((__t = ( offer.car.capacity )) == null ? '' : __t) +
' ' +
((__t = ( L10N.getPluralForm("transfers.capacity", offer.car.capacity) )) == null ? '' : __t) +
'\n                        </span>\n                            </div>\n                            ';
 if (passengers.length > offer.car.capacity) { ;
__p += '\n                                <div class="b-transfer__info-capacity--exceeded">\n                                    <span class="b-transfer__info-capacity--exceeded-icon"></span>\n                                    <span class="b-transfer__info-capacity--exceeded-text">' +
((__t = ( L10N.get("transfers.exceededCapacity") )) == null ? '' : __t) +
'</span>\n                                </div>\n                            ';
 } ;
__p += '\n                        </div>\n                    </div>\n                    <div class="b-offers-transfers-modal__list-item-price">\n                        <div class="b-offers-transfers-modal__list-item-price-container">\n                            <span class="b-offers-transfers-modal__list-item-price-amount">\n                                ' +
((__t = ( Formatter.formatMoney(offer.price.total.amount, Formatter.getDecimal(offer.price.total.amount)) )) == null ? '' : __t) +
' ' +
((__t = ( Formatter.formatCurrency(offer.price.total.currencyCode) )) == null ? '' : __t) +
'\n                            </span>\n                            <span>\n                            | ' +
((__t = ( L10N.get('Common.behind') )) == null ? '' : __t) +
' <span class="b-offers-transfers-modal__title-person-count"></span> ' +
((__t = ( L10N.get('Common.pers') )) == null ? '' : __t) +
'\n                            </span>\n                        </div>\n                    </div>\n                    <div class="break"></div>\n                </div>\n                <div class="b-offers-train-modal__form b-offers-train-modal__form-control b-offers-train-modal__list-item-comment">\n                    <span class="js-widget"  onclick=\'return new Widgets.Input({bindingProperty: "comments'+ uid +'", placeholder: L10N.get("trains.comment") })\'></span>\n                </div>\n                <div class="b-offers-avia-modal__list-item-line">\n            ';
 } ;
__p += '\n		';
 }); ;
__p += '\n	</div>\n\n	<div class="b-offers-avia-modal__no-offers ' +
((__t = ( !_.isEmpty(obj.offers) ? 'dn' : '' )) == null ? '' : __t) +
'">\n		<span>' +
((__t = ( L10N.get('ticket.noOffers') )) == null ? '' : __t) +
'</span>\n	</div>\n\n	<div class="b-offers-avia-modal__emails">\n\n	</div>\n\n	<div class="b-offers-avia-modal__close"></div>\n</div>\n';

}
return __p
}