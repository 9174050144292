module.exports = function(obj) {
obj || (obj = {});
var __t, __p = '', __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
with (obj) {
__p += '<div class="b-ticket-luggage">\n	<div class="b-ticket-luggage-table">\n	';

		var keys = (type === 'luggage') ? ['freeCabinLuggage', 'paidCabinLuggage'] : ['freeLuggage', 'paidLuggage'];
		var settings = STATE.getSettings();
		var passengerTypes = settings && settings.passengerTypes || [];
		var segmentsLuggage = [];

        function connectNounAndNumral(number) {
            var tens = Math.abs(number) % 100;
            var units = number % 10;
            if (tens > 10 && tens < 20) return "other";
            if (units > 1 && units < 5) return "few";
            if (units === 1) return "one";
            return "other";
        }

        function getDescription(val) {
            let output = Formatter.formatLuggageDescription(val);
            /*
            if (val.pieces != null) {
                output += val.pieces + ' x ';
            }
            
            if (val.weight !== undefined && val.weightUnit !== undefined) {
              output += val.weight + ' ' + (val.weightUnit.caption || '');
            }
            
            if (val.width !== undefined && val.height !== undefined && val.length !== undefined) {
              output += ', ' + val.width + 'x' + val.height + 'x' + val.length;
              if (val.dimensionUnit !== undefined) {
                output += ' ' + (val.dimensionUnit.caption || '');
              }
            } else if (val.dimensionUnit !== undefined && val.dimensionsSum !== undefined) {
              output += ', ' + val.dimensionsSum + ' ' + (val.dimensionUnit.caption || '');
            }
            if (val.weight != null) {
                output += ' x ' + val.weight + ' ' + L10N.get("flightlegs.weight");
            } else {
                if (val.unit) {
                    output += ' ' + val.unit;
                } else {
                    output += ' ' + (val.pieces != null ? L10N.get("flightlegs.piecesCount." + connectNounAndNumral(val.pieces)) : L10N.get("flightlegs.pieces"));
                }
            }*/
            return output;
        }

		_.each(segments, function (segment, i) {
			var name = (segment.departureCity && segment.departureCity.uid || '-') + ' → ' + (segment.arrivalCity && segment.arrivalCity.uid || '-');
			var services = segment.servicesDetails;

			segmentsLuggage.push(_.object(keys, [{}, {}]));

			_.each(_.pick(services, keys), function (value, key) {
				_.each(value, function (val, type) {
					var result = { nil: false };
					if (val.nil === true) {
						result.nil = true;
						segmentsLuggage[i][key] = { nil: true };
					} else {
						result.description = getDescription(val),
	
						result.passenger = _.find(passengerTypes, function (el) {
							return el && el.uid === type;
						});
	
						if (val.price != null) {
							result.price = Formatter.formatMoney(val.price.amount) + ' ' + Formatter.formatCurrency(val.price.currencyCode);
						}
	
						if (val.size != null) {
							result.size = L10N.get("avia.upTo") + ' ' + val.size + ' ' + L10N.get("flightlegs.size");
						}
	
						result.paid = ['paidLuggage', 'paidCabinLuggage'].indexOf(key) !== -1;
						segmentsLuggage[i][key][type] = result;
					}

					
				});

				if (_.isEmpty(segmentsLuggage[i][key])) {
					delete segmentsLuggage[i][key];
				}
			});

			if (_.isEmpty(segmentsLuggage[i])) {
				delete segmentsLuggage[i];
			} else {
				segmentsLuggage[i] = _.extend(segmentsLuggage[i], {
					name: name,
				});
			}
		});

		segmentsLuggage = _.compact(segmentsLuggage);

		if (_.size(segmentsLuggage) > 0) {
            let resultForCopy = type === 'baggage' ? L10N.get('ticket.baggage') + ':\n' : L10N.get('ticket.carryOn') + ':\n';
			_.each(segmentsLuggage, function (luggage) {
            resultForCopy += '\n' + luggage.name + ': ';
    ;
__p += '\n				<div class="b-ticket-luggage-row">\n                    <div class="b-ticket-luggage-name mobile-hide">' +
((__t = ( luggage.name )) == null ? '' : __t) +
'</div>\n					<div class="b-ticket-luggage-val">\n						';

						_.each(_.pick(luggage, keys), function (fare, key) { ;
__p += '\n							<div class="b-ticket-luggage-val-block">\n								<div class="b-ticket-luggage-title ' +
((__t = ( fare.paid === true ? 'red' : '' )) == null ? '' : __t) +
'">\n                                    ';

                                        const text = fare.nil === true ? L10N.get('flightlegs.servicesInfo.' + type + '.NIL') : L10N.get('flightlegs.' + key);
										resultForCopy += text + ', ';
                                    ;
__p += '\n									' +
((__t = ( text )) == null ? '' : __t) +
'\n								</div>\n								';
 _.each(fare, function (p, i) {
									const copySep = fare[i + 1] != null ? ', ': '';
                                ;
__p += '\n									<div>\n										';
 if (p.passenger != null) {

                                            resultForCopy += p.passenger.caption + ': '; ;
__p += '\n                                            ' +
((__t = ( p.passenger.caption )) == null ? '' : __t) +
':';
 }
                                            resultForCopy += p.description + (p.size != null ? ' (' + p.size + ')' + copySep : copySep);
                                        ;
__p += '\n										' +
((__t = ( p.description )) == null ? '' : __t) +
' ' +
((__t = ( (p.size != null) ? '(' + p.size + ') ' : '' )) == null ? '' : __t) +
'\n									</div>\n								';
 }); ;
__p += '\n							</div>\n						';
 }); ;
__p += '\n					</div>\n				</div>\n			';
 }); ;
__p += '\n            <div class="js-copy-content-tooltip copy-content">' +
((__t = ( resultForCopy )) == null ? '' : __t) +
'</div>\n		';
 } else {
			var text = L10N.get('flightlegs.servicesInfo.' + type + '.OFF');
        ;
__p += '\n			' +
((__t = ( text )) == null ? '' : __t) +
'\n            <div class="js-copy-content-tooltip copy-content"><span>' +
((__t = ( type === 'baggage' ? L10N.get('ticket.baggage') : L10N.get('ticket.carryOn') )) == null ? '' : __t) +
': ' +
((__t = ( text )) == null ? '' : __t) +
'</span></div>\n		';
 } ;
__p += '\n	</div>\n</div>\n';

}
return __p
}