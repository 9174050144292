import { ReactNode, useCallback, useContext, useEffect, useReducer, useRef } from "react";
import { createPortal } from "react-dom";
import Widgets from "../../../../js/widgets/widgets";
import { ReactAppGlobalContext } from "../../../../js/utils/react-utils";
import React from "react";
import "./style.less";
import { usePopupsStore } from "../../../store/popups";
import { useShallow } from "zustand/react/shallow";

interface ReactPopupProps {
  popupId?: string;
	opened: boolean;
	content: ReactNode;
  onClose?: () => void | Promise<void>;
  className?: string;
}

const ReactPopup: React.FC<ReactPopupProps> = (props) => {
	const { popupId, opened, content, onClose , className = []} = props;

  const currentPopup = usePopupsStore(
		useShallow((state) => state.popups.find((popup) => popup.id === popupId))
	);

	const { L10N } = useContext(ReactAppGlobalContext);

  const [_, forceUpdate] = useReducer(x => x + 1, 0);

	const popupRef = useRef<{
		show: () => void;
		hide: () => void;
		remove: () => void;
		ui: any;
	} | null>(null);

	const contentHolderRef = useRef<any>(null);

  // cleanup on unmount
  useEffect(() => {
    return () => {
      onClose && onClose();
    }
  }, []);

  useEffect(() => {
    if (currentPopup && !currentPopup.opened) {
    }
  }, [currentPopup])

	const initPopupRef = useCallback(() => {
		if (!popupRef.current) {
			popupRef.current = new Widgets.Popup({
				content: "<div class='js-react-content-holder react-content-holder'></div>",
				closeOnlyOnAction: true,
				type: "info",
				actions: [
					{
						label: `<i class="g-icon g-icon-reload"></i> ${L10N.get('Common.close')}`,
						action: () => {
							if (popupRef.current) {
								popupRef.current.remove();
							}
              onClose && onClose();
						},
					},
				],
				classes: ["b-reload-popup"].concat(className).join(' '),
			});
			if (popupRef.current) {
				const contentHolder = popupRef.current?.ui?.content
					?.get(0)
					?.querySelector(".js-react-content-holder");
				contentHolderRef.current = contentHolder;
			}
		}
	}, []);

	useEffect(() => {
		if (opened) {
			if (!popupRef.current) {
				initPopupRef();
			}
			if (popupRef.current) {
				popupRef.current.show();
        forceUpdate();
			}
		} else {
			if (popupRef.current) {
        popupRef.current.remove();
        popupRef.current = null;
        contentHolderRef.current = null;
			}
      onClose && onClose();
		}
	}, [opened]);

	return (
		<>
			{popupRef.current && contentHolderRef.current && (
				<>{createPortal(content, contentHolderRef.current)}</>
			)}
		</>
	);
};

export default ReactPopup;
