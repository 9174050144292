module.exports = function(obj) {
obj || (obj = {});
var __t, __p = '', __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
with (obj) {


    var contactPersonEditDeny = !contactPersonEditGranted || contactPersonDisableFreeTextInput;
	var disabledClass = contactPersonEditDeny ? "disabled" : "";
	var langRegistrationFullName = bookingSettings && bookingSettings.langRegistrationFullName || {};
	var iinEnabled = bookingSettings && bookingSettings.iinEnabled || false;
    var regionalTypeKZ = STATE.getSettings().visualSettings && STATE.getSettings().visualSettings.regionalTypeKZ ;

    var useTravellerNameFromDocuments = useTravellerNameFromDocuments || false;
    var useLangTwoLocales = useLangTwoLocales || false;

	var l10nPassportNumber = L10N.get("bookingForm.passportNumber");
;
__p += '\r\n\r\n<div>\r\n	<div class="b-traveller" data-index="' +
((__t = ( index )) == null ? '' : __t) +
'">\r\n\r\n		<header class="b-traveller__title collapse--with-anchor mobile-collapse" data-toggle="collapse" data-target=".toggle-traveller-' +
((__t = ( index )) == null ? '' : __t) +
'">\r\n			<div class="b-traveller-number">\r\n				' +
((__t = ( index + 1 )) == null ? '' : __t) +
'-' +
((__t = ( L10N.get('bookingForm.n') )) == null ? '' : __t) +
' ' +
((__t = ( L10N.get('passengers.passengerTitle') )) == null ? '' : __t) +
'\r\n			</div>\r\n			<div class="b-traveller-tariff-container">\r\n			    ';
 if (model.get('tariff').uid != 'BABY' && bookingInfoData.passengerTypeChangeEnabled) { ;
__p += '\r\n			    	<div class="b-header-border"></div>\r\n\r\n			    	<div class="b-traveller-tariff-title">' +
((__t = ( L10N.get('bookingForm.tariff') )) == null ? '' : __t) +
': </div>\r\n			    	';
 tariffs[model.get('type').uid].sort(function(t) {
			    		return t.tariffDescr.uid == 'FULL' ? -1 : 0;
			    	}).forEach(function(t) {
			    		var current = (model.get('tariff').uid == t.tariffDescr.uid) ? "current" : "";
			    	;
__p += '\r\n			    	';
 if (t.tariffDescr.uid === 'KUPEK' || t.tariffDescr.uid === 'SINGLE') { ;
__p += '\r\n			    	<div class="b-traveller-tariff" data-toggle="tooltip" data-placement="bottom" data-html="true" data-original-title="<div>' +
((__t = ( L10N.get('trains.compartmentBuyoutDiscount') )) == null ? '' : __t) +
'</div>">\r\n			    		<span class="js-widget"  onclick=\'return new Widgets.Radiobutton({ bindingProperty: "selectedTariff' + index + '", value: "'+ t.tariffDescr.uid +'", label: "'+ t.tariffDescr.caption +'", classes: "b-traveller-select-trariff '+ current +'"})\'></span>\r\n			    		<div class="b-traveller-tariff__hint"></div>\r\n			    	</div>\r\n			    	';
 } else { ;
__p += '\r\n			    	<div class="b-traveller-tariff">\r\n			    		<span class="js-widget"  onclick=\'return new Widgets.Radiobutton({ bindingProperty: "selectedTariff' + index + '", value: "'+ t.tariffDescr.uid +'", label: "'+ t.tariffDescr.caption +'", classes: "b-traveller-select-trariff '+ current +'"})\'></span>\r\n			    	</div>\r\n			    	';
 } ;
__p += '\r\n			    	';

			    	});
			    } else {
			    	;
__p += '\r\n			    	<div class="b-header-border"></div>\r\n			    	<div class="b-traveller-tariff selected">\r\n			    		' +
((__t = ( model.get('tariff').caption )) == null ? '' : __t) +
'\r\n			    	</div>\r\n			    	';

			    }
			    ;
__p += '\r\n			    <div class="b-header-border"></div>\r\n			    <div class="b-header-nonrefundable-tariff ' +
((__t = ( hasNonRefundableTariff ? 'open' : '' )) == null ? '' : __t) +
'">\r\n			    	<span class="js-widget"  onclick=\'return new Widgets.Checkbox({bindingProperty: "nonRefundableTariff"})\'></span> - ' +
((__t = ( L10N.get('trains.nonRefundable') )) == null ? '' : __t) +
'\r\n			    </div>\r\n            </div>\r\n			';
 if (isAdult) { ;
__p += '\r\n				<span class="b-traveller__title-contact">\r\n					<span class="js-widget"  onclick=\'return new Widgets.Checkbox({ bindingProperty: "isContact", classes: "' + disabledClass + '", label: L10N.get("bookingForm.travellerAsContact"), placeholder: L10N.get("bookingForm.activePassengerPlaceholder"), disabled: "' + contactPersonEditDeny + '" })\'></span>\r\n				</span>\r\n			';
 } ;
__p += '\r\n			';
 if (model.get('deletable') === true) { ;
__p += '\r\n				<span class="b-traveller__controls">\r\n					<span class="b-traveller__remove"></span>\r\n				</span>\r\n			';
 } ;
__p += '\r\n		</header>\r\n		<div class="b-traveller-info toggle-traveller-' +
((__t = ( index )) == null ? '' : __t) +
' show">\r\n			<div class="b-traveller-info_language">\r\n				<p>\r\n					<strong>' +
((__t = ( L10N.get('bookingForm.yourAttention') )) == null ? '' : __t) +
'</strong><br />\r\n					' +
((__t = ( L10N.get('bookingForm.yourAttention_text_3') )) == null ? '' : __t) +
'\r\n				</p>\r\n			</div>\r\n            <div class="b-traveller-info__tariff--mobile">\r\n                <div class="b-traveller-info__tariff-title--mobile collapse--with-anchor mobile-collapse" data-toggle="collapse" data-target=".toggle-tariff-' +
((__t = ( index )) == null ? '' : __t) +
'">\r\n                    <div class="b-traveller-info__tariff-text">' +
((__t = ( L10N.get('bookingForm.tariff') )) == null ? '' : __t) +
'</div>\r\n                    <div class="b-traveller-info__tariff-caption">' +
((__t = ( model.get('tariff').caption )) == null ? '' : __t) +
'</div>\r\n                </div>\r\n                <div class="b-traveller-info__tariff-container--mobile toggle-tariff-' +
((__t = ( index )) == null ? '' : __t) +
' collapse"></div>\r\n            </div>\r\n			<div class="l-grid-container">\r\n				<div class="l-grid-layout-33 js-lastName-container"></div>\r\n				<div class="l-grid-layout-33 js-firstName-container"></div>\r\n				<div class="l-grid-layout-33 js-middleName-container"></div>\r\n			</div>\r\n			<div class="l-grid-container">\r\n				<div class="l-grid-layout-33">\r\n					<span class="js-widget"  onclick=\'return new Widgets.Gender({bindingProperty: "passport.gender" })\'></span>\r\n				</div>\r\n				<div class="l-grid-layout-33 p-search__options-date-with-label">\r\n					<span class="js-widget"  onclick=\'return new Widgets.MaskedDate({ bindingProperty: "passport.birthDate", label: L10N.get("bookingForm.birthDate"), placeholder: L10N.get("bookingForm.birthDatePlaceholder") })\'></span>\r\n				</div>\r\n				<div class="l-grid-layout-33 js-secondName-container"></div>\r\n			</div>\r\n\r\n			<div class="l-line"></div>\r\n\r\n			<span class="b-traveller__block-header collapse--with-anchor mobile-collapse" data-toggle="collapse" data-target=".toggle-documents-' +
((__t = ( index )) == null ? '' : __t) +
'">\r\n                ' +
((__t = ( L10N.get("bookingForm.passengerDocumentHeader"))) == null ? '' : __t) +
'\r\n            </span>\r\n			<div class="l-grid-container toggle-documents-' +
((__t = ( index )) == null ? '' : __t) +
' show">\r\n				<div class="l-grid-layout-33">\r\n					<label class="b-input">\r\n						<span class="js-widget"  onclick=\'return new Widgets.Select({ bindingProperty: "passport.type", values: "' + passportTypes + '", label: L10N.get("bookingForm.documentType"), placeholder: L10N.get("bookingForm.documentTypePlaceholder") })\'></span>\r\n					</label>\r\n				</div>\r\n                ';
 if (langRegistrationFullName.uid === 'LATIN_AND_CYRILLIC' && useTravellerNameFromDocuments && useLangTwoLocales) { ;
__p += '\r\n                <div class="l-grid-layout-33">\r\n                    <span class="js-widget"  onclick=\'return new Widgets.ToggleButtonDouble({ bindingProperty: "useLang", valueFirst: "CYRILLIC", valueSecond: "LATIN", labelFirst: "RU", labelSecond: "EN", classes: "b-traveller__lang-toggle" })\'></span>\r\n                </div>\r\n                ';
 } ;
__p += '\r\n			</div>\r\n            <div class="l-grid-container toggle-documents-' +
((__t = ( index )) == null ? '' : __t) +
' show">\r\n                <div class="l-grid-layout-33 b-traveller__passport-number">\r\n                    <span class="js-widget"  onclick=\'return new Widgets.Input({bindingProperty: "passport.number", label: "' + l10nPassportNumber + '", placeholder: L10N.get("bookingForm.passportNumberPlaceholder") })\'></span>\r\n                </div>\r\n                <div class="l-grid-layout-33 b-traveller__passport-expired-date">\r\n                    <span class="js-widget"  onclick=\'return new Widgets.MaskedDate({ bindingProperty: "passport.expiredDate", min: "' + parseInt(new Time().format("YYYY")) + '", label: L10N.get("bookingForm.expiredDate"), placeholder: L10N.get("bookingForm.expiredDatePlaceholder") })\'></span>\r\n                </div>\r\n            </div>\r\n			<div class="l-grid-container toggle-documents-' +
((__t = ( index )) == null ? '' : __t) +
' show">\r\n				<div class="l-grid-layout-33 b-traveller__citizenship">\r\n					<label class="b-input">\r\n						<span class="js-widget"  onclick=\'return new Widgets.Select({ bindingProperty: "passport.citizenship", values: "' + countries + '", label: L10N.get("bookingForm.citizenship")})\'></span>\r\n					</label>\r\n				</div>\r\n				<div class="l-grid-layout-33 b-traveller__origin-country">\r\n					<label class="b-input">\r\n						<span class="js-widget"  onclick=\'return new Widgets.Select({ bindingProperty: "passport.originCountry", values: "' + countries + '", label: L10N.get("bookingForm.origin"), placeholder: L10N.get("bookingForm.originPlaceholder") })\'></span>\r\n					</label>\r\n				</div>\r\n			</div>\r\n			<div class="b-traveller__additional-conditions toggle-documents-' +
((__t = ( index )) == null ? '' : __t) +
' show"></div>\r\n            ';
 if (regionalTypeKZ === true) { ;
__p += '\r\n            <div class="toggle-documents-container toggle-documents-' +
((__t = ( index )) == null ? '' : __t) +
' show">\r\n                <div class="l-line "></div>\r\n                <span class="b-traveller__block-header">' +
((__t = ( L10N.get("bookingForm.kzIINHeader") )) == null ? '' : __t) +
'</span>\r\n                <div class="l-grid-container">\r\n                    <div class="l-grid-layout-33 b-traveller__kz-iin">\r\n                        <label class="b-input">\r\n                            <span class="js-widget"  onclick=\'return new Widgets.Input({ bindingProperty: "IIN", label: L10N.get("bookingForm.kzIINLabel"), disabled: "'+ iinEnabled +'"})\'></span>\r\n                        </label>\r\n                    </div>\r\n                </div>\r\n            </div>\r\n            ';
 } ;
__p += '\r\n			<div class="l-line"></div>\r\n			<span class="b-traveller__block-header collapse--with-anchor mobile-collapse" data-toggle="collapse" data-target=".toggle-passenger-contacts-' +
((__t = ( index )) == null ? '' : __t) +
'">' +
((__t = ( L10N.get("bookingForm.passengerContactsHeader"))) == null ? '' : __t) +
'</span>\r\n			<div class="l-grid-container toggle-passenger-contacts-' +
((__t = ( index )) == null ? '' : __t) +
' show">\r\n				<div class="l-grid-layout-33">\r\n					<span class="js-widget"  onclick=\'return new Widgets.Phone({ bindingProperty: "phone", label: L10N.get("bookingForm.phone"), placeholder: L10N.get("bookingForm.phonePlaceholder") })\'></span>\r\n				</div>\r\n				<div class="l-grid-layout-33">\r\n					<span class="js-widget"  onclick=\'return new Widgets.Input({ bindingProperty: "email", label: L10N.get("bookingForm.email"), classes: "b-email", placeholder: L10N.get("bookingForm.emailPlaceholder") })\'></span>\r\n				</div>\r\n				<div class="l-grid-layout-33">\r\n\r\n				</div>\r\n			</div>\r\n			';
 if (bonusCardsEnabled === true) { ;
__p += '\r\n				<div class="l-line"></div>\r\n				<span class="b-traveller__block-header collapse--with-anchor mobile-collapse" data-toggle="collapse" data-target=".toggle-bonus-cards-' +
((__t = ( index )) == null ? '' : __t) +
'">' +
((__t = ( L10N.get('trains.railwaysBonus') )) == null ? '' : __t) +
'</span>\r\n				<div class="l-grid-container b-traveller__bonus-cards toggle-bonus-cards-' +
((__t = ( index )) == null ? '' : __t) +
' show">\r\n					<div class="l-grid-layout-33">\r\n						<span class="js-widget"  onclick=\'return new Widgets.Input({ bindingProperty: "railBonusCard.number", label: L10N.get("bookingForm.cardNumber"), placeholder: L10N.get("bookingForm.cardNumber") })\'></span>\r\n					</div>\r\n					<div class="l-grid-layout-33"></div>\r\n				</div>\r\n			';
 } ;
__p += '\r\n			';
 if (!_.isEmpty(organizationCostCodes)) { ;
__p += '\r\n				<div class="l-line"></div>\r\n				<span class="b-traveller__block-header collapse--with-anchor mobile-collapse collapsed" data-toggle="collapse" data-target=".toggle-cost-codes-' +
((__t = ( index )) == null ? '' : __t) +
'">' +
((__t = ( L10N.get("travelPolitics.statInfo") )) == null ? '' : __t) +
'</span>\r\n				<div class="b-traveller-cost-codes toggle-cost-codes-' +
((__t = ( index )) == null ? '' : __t) +
' collapsed">\r\n					<div class="b-traveller-cost-codes__container"></div>\r\n				</div>\r\n			';
 } ;
__p += '\r\n		</div>\r\n	</div>\r\n</div>\r\n';

}
return __p
}