// eslint-disable-next-line banned-modules
'use strict';

import './style.less';
import template from './template.ejs';
import travellerInfoTemplate from './travellerInfoTemplate.ejs';
import TravelerModel from './model';

import GlUl from '@/utils/global-utils';
import BaseView from '@/classes/base.view';
import SelectWidget from '@/widgets/b-select/index';
import InputWidget from '@/widgets/b-input/index';
import MiddleNameWidget from '@/widgets/b-middle-name/index';
import PassengerAutocompleteWidget from '@/widgets/autocomplete-input/b-passenger/index';
import TravellerCostCodesAdapter from '../b-cost-codes/b-traveller-cost-codes-adapter';
import BookingAutoCorrectionAdapter from '../../utils/b-booking-auto-correction-adapter';
import { getAutoCorrectionWidgetsLabels } from '../../utils/b-booking-auto-correction-input-labels';

import $ from 'jquery';
import axios from 'axios';

export default BaseView.extend({
	ui: {
		expiredDate: '.b-traveller__passport-expired-date',
		birthDate: '.js-traveller-birthDate',
		passportBirthDate: '.js-traveller-passportBirthDate',
		statisticsContainer: '.b-traveller-cost-codes__container',
		bonusCardsContainer: '.b-traveller__bonus-cards',
		passportNumber: '.b-traveller__passport-number',
		travellerInfoLanguage: '.b-traveller-info_language',
	},

	events: {
		'click .b-traveller__discount-open': 'open',
	},

	initialize(options) {
		this.useLangTwoLocales = false;
		this.bonusCardAvailable = options.bonusCardAvailable;
		this.corporatePassengers = options.parent.options.bookingSettings.corporatePassengers;
		this.organizationCostCodes = options.organizationCostCodes; // при инициализации используем общие (глобальные) кост коды
		this.getFormSettings = this.getBookingFormSettings.bind(this);
		this.bookingFormSettingsToken = options.bookingFormSettingsToken || undefined;
		if (!this.model) {
			this.model = new TravelerModel();
			options.parentModel.add(this.model);
		}
		if (options.isContact != null) this.model.set('isContact', options.isContact);

		if (options.isMiddleNameRequired) {
			this.model.set('isMiddleNameRequired', true);
		}
		({
			index: this.index,
			isMiddleNameRequired: this.isMiddleNameRequired,
			passengerType: this.passengerType,
			international: this.international,
			forbidTravellerUpdate: this.forbidTravellerUpdate,
			contactPersonEditGranted: this.contactPersonEditGranted,
			contactPersonDisableFreeTextInput: this.contactPersonDisableFreeTextInput,
		} = options);

		this.model.set('type', options.passengerType.uid);
		this.isAdult = ['ADULT', 'YOUTH', 'SENIOR', 'PROMO'].includes(
			this.passengerType.uid,
		);

		this.bookingAutoCorrectionAdapter = new BookingAutoCorrectionAdapter({
			routeType: STATE.router.breadcrumbs.routeType,
			transliterationOpts: this.getTransliterationOpts(),
		});

		this.listenTo(
			this.model.get('passport'),
			'change:lastName change:firstName change:middleName change:secondName',
			() => {
				if (this.bookingAutoCorrectionAdapter) {
					this.bookingAutoCorrectionAdapter.updateTransliterationOpts(
						this.getTransliterationOpts(),
					);
				}
			},
		);

		this.setUseLang();
		// специальный слушатель для useLang (вкл/выкл)
		this.listenTo(this.model, `change:useLangTwoLocales`, async (_, value) => {
			if (value !== this.useLangTwoLocales) {
				this.useLangTwoLocales = value;
				const container = this.$el;
				this.$el.empty();

				this.resetNonCommonCostCodes();

				this.render();
				container.append(this.$el);

				this.options.parent.adjustMobileTemplate(
					STATE.checkViewport('(max-width: 768px)'),
				);

				this.trigger('travellerPersonChanged', this);
			} else {
				this.useLangTwoLocales = value;
			}
		});

		// заполняем данные пассажира при первой отрисовке
		GlUl.fillPassengerData({
			international: options.international,
			passengerUid: options.passenger && options.passenger.uid,
			passengerTypeUid: options.passengerType && options.passengerType.uid,
			passengerTypeDocument: options.passengerTypeDocument,
			model: this.model,
			fillOnLoad: true,
			bookingSettings: this.options.parent.options.bookingSettings,
			userSelectedPassenger: this.userSelectedPassenger,
			useCyrillicNameFieldsForInternalPassport: true,
		});

		// LISTENERS
		this.listenTo(
			this.model.get('passport'),
			`change:type`,
			async (thisModel, value) => {
				// UTS-357
				// autofill citizenship
				GlUl.fillCitizenshipByPassportType(thisModel, value);

				// COMMENTED BY IBECORP-6400
				const container = this.$el;

				this.resetNonCommonCostCodes();

				await this.checkRegistrationType(thisModel, value);

				// сбросим uselang до изначального состояния
				this.setUseLang();

				// this.changePassportType(value);

				// await this.checkRegistrationLang();
				// if (value && value.uid === 'INTERNAL') {
				// 	const RU = STATE.get('countries').find((el) => el.uid === 'RU');
				// 	this.model.get('passport').set('citizenship', RU);
				// 	this.model.get('passport').set('originCountry', RU);
				// }
				this.$el.empty();
				this.render();
				// this.changePassportType(value, false);
				container.append(this.$el);
				this.options.parent.adjustMobileTemplate(
					STATE.checkViewport('(max-width: 768px)'),
				);

				if (value == null) {
					this.ui.expiredDate.show();
				} else {
					const passportExists = value.uid.indexOf('.') !== -1;
					const type = passportExists ? value.uid.split('.')[0] : value.uid;

					this.setDocumentPlaceholder(type);
					if (['INTERNAL', 'BIRTHDAY_NOTIFICATION'].includes(type)) {
						this.ui.expiredDate.hide();
					} else {
						this.ui.expiredDate.show();
					}
				}

				GlUl.fillPassengerData({
					international: options.international,
					passengerUid: options.passenger && options.passenger.uid,
					passengerTypeUid: options.passengerType && options.passengerType.uid,
					passengerTypeDocument: options.passengerTypeDocument,
					model: this.model,
					fillOnLoad: false,
					bookingSettings: this.options.parent.options.bookingSettings,
					userSelectedPassportType: value,
					userSelectedPassenger: this.userSelectedPassenger,
					useCyrillicNameFieldsForInternalPassport: true,
				});

				this.trigger('travellerPersonChanged', this);
			},
		);

		this.listenTo(this.model, 'change:useLang', () => {
			// COMMENTED BY IBECORP-6400

			const container = this.$el;
			this.$el.empty();

			this.resetNonCommonCostCodes();
			// this.autoCompletePassport(this.model.get('passport').get('type'), v);
			this.render();
			container.append(this.$el);
			this.options.parent.adjustMobileTemplate(
				STATE.checkViewport('(max-width: 768px)'),
			);

			GlUl.fillPassengerData({
				international: options.international,
				passengerUid: options.passenger && options.passenger.uid,
				passengerTypeUid: options.passengerType && options.passengerType.uid,
				passengerTypeDocument: options.passengerTypeDocument,
				model: this.model,
				fillOnLoad: false,
				bookingSettings: this.options.parent.options.bookingSettings,
				userSelectedPassenger: this.userSelectedPassenger,
				useCyrillicNameFieldsForInternalPassport: true,
			});

			this.trigger('travellerPersonChanged', this);
		});

		this.listenTo(
			this.model.get('passport'),
			`change:citizenship`,
			async (thisModel) => {
				await this.checkRegistrationType(thisModel, thisModel.get('type'));
			},
		);

		this.listenTo(
			this.model.get('passport'),
			`change:originCountry`,
			async () => {
				await this.checkRegistrationLang();
			},
		);

		this.listenTo(this.model, `change:bonusCard`, (model, value) => {
			const bonusCard =
				value || _.object(['number', 'airline', 'nameOnCard'], []);
			_.each(bonusCard, (v, k) => {
				model.trigger(`change:bonusCard.${k}`, model, v);
			});
		});
		this.canShowPopup = true;
		this.listenTo(this.model, 'change:bonusCard.nameOnCard', (model, value) => {
			if (
				value &&
				this.options.carrier &&
				this.options.carrier.uid === 'SU' &&
				this.canShowPopup
			) {
				const _t = this;
				this.canShowPopup = false;
				const popup = new Widgets.Popup({
					content: L10N.get('avia.checkSpellingCorrectness'),
					type: 'info',
					actions: [
						{
							label: L10N.get('hotels.continue'),
							action: () => {
								_t.canShowPopup = true;
								popup.hide();
							},
						},
					],
				});
				popup.show();
			}
		});

		this.listenTo(
			this.model.get('passport'),
			`change:birthDate`,
			(model, value) => {
				const currentDate = new Date();
				if (value === new Time(currentDate).toServerDate()) {
					this.model.set('birthDate', null);
					model.set('birthDate', null);
					return;
				}
				this.model.set('birthDate', value);
			},
		);

		this.listenTo(this.model, `typeahead:passengerSelect`, async (model, value) => {
			this.resetNonCommonCostCodes();

			await this.getBookingFormSettings(); // <- обновляем список костКодов

			const container = this.$el;
			this.options.passenger = _.omit(value, 'uid');
			this.userSelectedPassenger = value;

			/*
				Является сокращенной копией логики из GlUl.fillPassengerData(),
				где определяется тип паспорта для корпоративного клиента при fillOnLoad == true.
			*/
			const getUserSelectedPassengerPassport = (passports = [], international = false) => {
				if (!passports.length) return undefined;
				const currentPassengerPassport = passports.find(
					(pass) => {
						return !international
							? pass.type.uid === 'INTERNAL'
							: pass.type.uid === 'FOREIGN';
					},
				);
				return currentPassengerPassport || passports[0];
			};

			const userSelectedPassengerPassport =
				getUserSelectedPassengerPassport(this.userSelectedPassenger.passports, this.international) || undefined;
			this.userSelectedPassportType = userSelectedPassengerPassport ? {
				...userSelectedPassengerPassport.type,
				originalUid: `${userSelectedPassengerPassport.type.uid}.${userSelectedPassengerPassport.number}`} : undefined;

			if (!this.isMiddleNameRequired) {
				delete this.options.passenger.middleName;
				delete this.options.passenger.middleNameLat;
			}
			this.options.passenger.airlineBonusCards =
				this.options.passenger.bonusCards;

			// this.autoCompleteData(_.clone(this.options.passenger), true);
			GlUl.fillPassengerData({
				international: options.international,
				passengerUid: options.passenger && options.passenger.uid,
				passengerTypeUid: options.passengerType && options.passengerType.uid,
				passengerTypeDocument: options.passengerTypeDocument,
				model: this.model,
				fillOnLoad: false,
				bookingSettings: this.options.parent.options.bookingSettings,
				userSelectedPassenger: {
					...this.userSelectedPassenger,
					isCorporate: !_.isEmpty(this.corporatePassengers),
				},
				userSelectedPassportType: this.userSelectedPassportType,
				useCyrillicNameFieldsForInternalPassport: true,
			});

			this.model.set('bonusCard', null);

			this.$el.empty();
			this.render();
			container.append(this.$el);

			// this.changePassportType(this.model.get('passport').get('type'));
			this.options.parent.adjustMobileTemplate(
				STATE.checkViewport('(max-width: 768px)'),
			);

			this.trigger('travellerPersonChanged', this);

			/*
				HACK IBECORP-7327: т.к. GlUl.fillPassengerData() будет что-то обновлять
				асинхронно через setTimeout (например, originCountry и citizenship), получается, что на момент
				первого запроса this.getBookingFormSettings() у нас еще не будет всех заполненных данных, влияющих
				на локаль. setUseLang() тоже дергать рано, т.к. это приведет к багу в переключалке языка.
				Поэтому мы помещаем костыль сюда - ждем, когда отработают все model.set() по таймауту и после этого
				снова запрашиваем this.getBookingFormSettings() со всеми актуальными данными.
			*/
			setTimeout(async () => {
				await this.getBookingFormSettings();
				this.setUseLang();
				GlUl.fillPassengerData({
					international: options.international,
					passengerUid: options.passenger && options.passenger.uid,
					passengerTypeUid: options.passengerType && options.passengerType.uid,
					passengerTypeDocument: options.passengerTypeDocument,
					model: this.model,
					fillOnLoad: false,
					bookingSettings: this.options.parent.options.bookingSettings,
					userSelectedPassenger: this.userSelectedPassenger,
					nameFieldsToSkip: ['originCountry', 'citizenship'],
					nameFieldsToTriggerByTimeout: ['originCountry', 'citizenship'],
					useCyrillicNameFieldsForInternalPassport: true,
				});
			}, 0);
		});

		this.render();
		// COMMENTED BY IBECORP-6400
		// this.autoCompleteData(_.clone(this.options.passenger), true);
		// this.changePassportType(this.model.get('passport').get('type'));
	},

	getTransliterationOpts() {
		const opts = {
			traveller: {
				...this.model.toJSON(),
				langRegistrationFullName: this.options.langRegistrationFullName,
			},
			gds: this.options.gds,
		};
		if (
			opts.traveller.passport &&
			opts.traveller.passport.type &&
			opts.traveller.passport.type.uid.indexOf('.') !== -1
		) {
			opts.traveller.passport.type.uid = opts.traveller.passport.type.uid.split('.')[0];
		}
		return opts;
	},

	getBookingFormSettings() {
		const traveller = this.model.toJSON();
		if (
			traveller.passport &&
			traveller.passport.type &&
			traveller.passport.type.uid.indexOf('.') !== -1
		) {
			traveller.passport.type.uid = traveller.passport.type.uid.split('.')[0];
		}
		const params = {
			parameters: {
				traveller,
				token: this.options.token,
				bookingFormSettingsToken: this.bookingFormSettingsToken,
			},
		};
		return axios
			.post(
				'/midoffice/ibecorp-b2b/avia/getBookingFormSettings',
				params,
				this.model,
			)
			.then((res) => {
				this.organizationCostCodes = res.data.result?.organizationCostCodes || [];
				return res.data.result;
			});
	},

	async checkRegistrationLang(renderTravellerInfo = false) {
		// clearTimeout(this.timer);
		// this.timer = setTimeout(async () => {
		const formSettings = await this.getBookingFormSettings();
		if (
			formSettings.langRegistrationFullName.uid !==
			this.options.langRegistrationFullName.uid
		) {
			this.langRegistrationFullName = formSettings.langRegistrationFullName;
			this.options.langRegistrationFullName = this.langRegistrationFullName;
			// this.setUseLang();
			// this.autoCompleteData(_.clone(this.options.passenger));
		}
		if (renderTravellerInfo) this.renderTravellerInfoLanguage();
		// }, 200);
	},

	filterUnavailablePassports(passenger) {
		const { passengerTypeDocument } = this.options;
		let passportTypes =
			(
				passengerTypeDocument.find(
					(el) => el.passengerType.uid === this.passengerType.uid,
				) || {}
			).passportTypes || [];
		passportTypes = _.map([...passportTypes], (el) => el && el.uid);

		passenger.passports = _.filter(
			passenger.passports,
			(el) => el.type != null && passportTypes.includes(el.type.uid),
		);
	},

	// changePassportType(value, doAutocomplete = true) {
	// COMMENTED BY IBECORP-6400

	// if (value == null) {
	// 	this.ui.expiredDate.show();
	// } else {
	// 	const passportExists = value.uid.indexOf('.') !== -1;
	// 	const type = passportExists ? value.uid.split('.')[0] : value.uid;

	// 	this.setDocumentPlaceholder(type);
	// 	if (['INTERNAL', 'BIRTHDAY_NOTIFICATION'].includes(type)) {
	// 		this.ui.expiredDate.hide();
	// 	} else {
	// 		this.ui.expiredDate.show();
	// 	}
	// }

	// if (doAutocomplete) this.autoCompletePassport(value, this.model.get('useLang'));
	// },

	async checkRegistrationType(passport, type) {
		// const passenger = _.clone(this.options.passenger);
		if (type == null) {
			// this.autoCompleteData(passenger, false);
			this.renderTravellerInfoLanguage();
			return;
		}
		await this.checkRegistrationLang(true);
	},

	setDocumentPlaceholder(type) {
		this.ui.passportNumber
			.find('input[type="text"]')
			.attr('placeholder', GlUl.setDocumentPlaceholder(type));
	},

	// autoCompleteData(passenger, isFirstCall) {
	// COMMENTED BY IBECORP-6400

	// if (!_.isObject(passenger)) {
	// 	return;
	// }

	// this.filterUnavailablePassports(passenger);

	// if (!_.isEmpty(passenger.passports) && isFirstCall) {
	// 	const internalOrForeign = passenger.passports.find((passport) => {
	// 		if (!passport.type) return null;
	// 		return !this.international
	// 			? passport.type.uid === 'INTERNAL'
	// 			: passport.type.uid === 'FOREIGN';
	// 	});

	// 	this.model.set(
	// 		`passport.type`,
	// 		internalOrForeign ? internalOrForeign.type : passenger.passports[0].type,
	// 	);
	// }

	// const langRegistrationFullName =
	// 	this.langRegistrationFullName ||
	// 	this.options.langRegistrationFullName ||
	// 	{};
	// const nameFields = ['firstName', 'secondName', 'lastName', 'middleName'];
	// const passengerFields = [
	// 	..._.map(nameFields, (el) => {
	// 		switch (langRegistrationFullName.uid) {
	// 			case 'LATIN':
	// 				return `${el}Lat`;
	// 			case 'CYRILLIC':
	// 				return el;
	// 			case 'LATIN_AND_CYRILLIC':
	// 				return [`${el}Lat`, el];
	// 			default:
	// 				return el;
	// 		}
	// 	}),
	// 	'gender',
	// ];
	// GlUl.autocompletePassengerFields.call(
	// 	this,
	// 	passenger,
	// 	nameFields,
	// 	langRegistrationFullName,
	// 	passengerFields,
	// );

	// if (!_.isEmpty(passenger.passports)) {
	// 	const firstPassportType = passenger.passports[0].type;
	// 	if (!firstPassportType) {
	// 		this.model.set(`passport.type`, firstPassportType);
	// 	}
	// 	this.changePassportType(firstPassportType, true);
	// }
	// },

	// autoCompletePassport(passport, useLang) {
	// COMMENTED BY IBECORP-6400

	// const { passenger } = this.options;
	// const passportFields = [
	// 	'citizenship',
	// 	'originCountry',
	// 	'number',
	// 	'birthDate',
	// 	'expiredDate',
	// ];

	// GlUl.autocompletePassport.call(
	// 	this,
	// 	passport,
	// 	passenger,
	// 	passportFields,
	// 	useLang,
	// );
	// 	return this;
	// },

	renderTravellerInfoLanguage() {
		this.ui.travellerInfoLanguage.empty();
		this.ui.travellerInfoLanguage.html(
			travellerInfoTemplate({
				options: this.options,
			}),
		);
	},

	adjustMobileTemplate(matches) {
		if (_.isObject(matches)) matches = matches.matches;

		const $travellerContainer = this.$('.b-traveller');
		const $travellerInfo = this.$('.b-traveller-info');
		const $travellerHeader = $travellerContainer.find('.b-traveller__title');
		const $travellerTitleContent = this.$('.b-traveller__title-contact');
		const $tooltips = this.$('[data-toggle="tooltip"]');

		if (matches) {
			$travellerContainer.find('.b-traveller-tariff').hide();
			this.ui.travellerInfoLanguage.insertBefore($travellerHeader);
			$travellerInfo.prepend($travellerTitleContent);
			_.each($tooltips, (el) => el && this.$(el).tooltip('disable'));
		} else {
			$travellerContainer.find('.b-traveller-tariff').show();
			$travellerInfo.prepend(this.ui.travellerInfoLanguage);
			$travellerHeader.append($travellerTitleContent);
			_.each($tooltips, (el) => el && this.$(el).tooltip('enable'));
		}
	},

	setUseLang() {
		const { langRegistrationFullName } = this.options;
		// if (!this.model.get('useLang')) {
		if (langRegistrationFullName.uid === 'LATIN') {
			this.model.set('useLang', 'LATIN', { silent: true });
		} else if (
			langRegistrationFullName.uid === 'CYRILLIC' ||
			langRegistrationFullName.uid === 'LATIN_AND_CYRILLIC'
		) {
			this.model.set('useLang', 'CYRILLIC', { silent: true });
		}
		// }
	},

	render() {
		const {
			passenger,
			passengerTypeDocument,
			transliterationOn,
			langRegistrationFullName,
		} = this.options;
		const countriesCollection = SelectWidget.dictionaryToCollection(
			STATE.getCountries(),
		);

		let passportTypes =
			(
				passengerTypeDocument.find(
					(el) => el.passengerType.uid === this.passengerType.uid,
				) || {}
			).passportTypes || [];
		passportTypes = _.map([...passportTypes], (el) => _.extend({}, el));

		const originalPassportTypes = _.map([...passportTypes], (el) => _.extend({}, el),
		);
		if (passenger != null && !_.isEmpty(passenger.passports)) {
			_.each(passenger.passports, (p) => {
				let findedPassport = _.find(
					passportTypes,
					(el) => el.uid === p.type.uid,
				);
				let passportExists = false;

				if (findedPassport == null) {
					findedPassport = _.extend(
						{},
						_.find(originalPassportTypes, (el) => el.uid === p.type.uid),
					);
					passportExists = !_.isEmpty(findedPassport);
				}

				findedPassport.uid += `.${p.number}`;
				findedPassport.caption += ` <span style="display: none">(${p.number})</span>`;
				findedPassport.originalUid = findedPassport.uid;

				if (passportExists) {
					const index = _.sortedIndex(
						_.map(passportTypes, (el) => el && el.uid),
						findedPassport.uid,
					);
					passportTypes.splice(index, 0, findedPassport);
				}
			});
		}

		// this.setUseLang();

		this.setElement(
			template({
				index: this.index,
				passengerType: this.passengerType,
				cid: this.model.cid,
				passportTypes: SelectWidget.storeCollection(
					SelectWidget.dictionaryToCollection(passportTypes),
				),
				passport: this.model.get('passport'),
				transliterationOn,
				langRegistrationFullName,
				countries: SelectWidget.storeCollection(countriesCollection),
				forbidTravellerUpdate: this.forbidTravellerUpdate,
				contactPersonEditGranted: this.contactPersonEditGranted,
				contactPersonDisableFreeTextInput:
					this.contactPersonDisableFreeTextInput,
				isAdult: this.isAdult,
				bonusCardAvailable: this.bonusCardAvailable,
				isMiddleNameRequired: this.isMiddleNameRequired,
				options: this.options,
				organizationCostCodes: this.organizationCostCodes,
				useTravellerNameFromDocuments:
					this.options.parent.options.bookingSettings
						.useTravellerNameFromDocuments,
				useLangTwoLocales: this.useLangTwoLocales,
			}),
		);

		this.renderAutoCorrectionWidgets();

		this.adjustMobileTemplate(STATE.checkViewport('(max-width: 768px)'));
		this.renderTravellerInfoLanguage();

		/* Statistic container */
		this.renderCostCodesView();

		if (passenger != null && !_.isEmpty(passenger.airlineBonusCards)) {
			this.$('.b-traveller__bonus-cards-select').show();

			const getApplicableBonusCards = (cards, carrier) => {
				if (!_.isObject(carrier)) {
					return null;
				}
				const applicableBonusCard = _.find(cards, (el) => {
					if (el.airline && el.airline.uid) {
						if (carrier.uid === 'ДР' && el.airline.uid === 'SU') {
							return true;
						} else if (el.airline.uid === this.options.carrier.uid) {
							return true;
						}
					}
					return false;
				});
				if (applicableBonusCard) {
					return {
						uid: `${applicableBonusCard.airline.uid}.${applicableBonusCard.number}`,
						caption: `${applicableBonusCard.airline.caption} (${applicableBonusCard.number})`,
					};
				}
				return null;
			};

			const defaultBonusCard = getApplicableBonusCards(passenger.airlineBonusCards, this.options.carrier);

			if (_.size(passenger.airlineBonusCards) > 1) {
				const $container = $(
					'<div class="l-grid-container b-traveller__bonus-cards-select"></div>',
				);
				const widget = new SelectWidget({
					bindingProperty: '_bonusCard',
					label: L10N.get('bookingForm.bonusCards'),
					values: SelectWidget.storeCollection(
						SelectWidget.dictionaryToCollection(
							_.map(passenger.airlineBonusCards, (el) => ({
								uid: `${el.airline.uid}.${el.number}`,
								caption: `${el.airline.caption} (${el.number})`,
							})),
						),
					),
				});
				widget.applyBinding(this.model);
				$container.append(
					$('<div class="l-grid-layout-33"></div>').append(widget.$el),
					$('<div class="l-grid-layout-33"></div>'),
				);
				this.ui.bonusCardsContainer.before($container);

				this.listenTo(this.model, 'change:_bonusCard', (model, value) => {
					const bonusCard = _.find(
						passenger.airlineBonusCards,
						(el) => (value.uid.includes('.')
							? `${el.airline.uid}.${el.number}`
							: el.airline.uid) === value.uid,
					);

					if (bonusCard != null) {
						this.model.set('bonusCard', bonusCard);
					}
				});

				if (defaultBonusCard != null) {
					this.model.set('_bonusCard', defaultBonusCard);
				}
			} else if (defaultBonusCard != null) {
				this.model.set('bonusCard', passenger.airlineBonusCards[0]);
			}
		} else {
			this.$('.b-traveller__bonus-cards-select').hide();
		}
	},

	/* Cost Codes */

	resetNonCommonCostCodes() {
		if (this.costCodesAdapter) this.costCodesAdapter.resetNonCommonCostCodes();
	},

	getCommonCostCodesRenderParams() {
		return {
			renderContainer: this.ui.statisticsContainer,
			passenger: this.options.passenger,
			organizationCostCodes: this.organizationCostCodes,
		};
	},

	renderCostCodesView() {
		if (!_.isEmpty(this.organizationCostCodes)) {
			if (!this.costCodesAdapter) {
				this.costCodesAdapter = new TravellerCostCodesAdapter({
					parentView: this,
				});
			} else {
				this.costCodesAdapter.removeCostCodesView();
			}
			this.costCodesAdapter.renderCostCodesView({
				...this.getCommonCostCodesRenderParams(),
			});
		}
	},

	removeCostCodesAdapter() {
		if (this.costCodesAdapter) {
			this.costCodesAdapter.removeCostCodesView();
			this.costCodesAdapter = null;
		}
	},

	/* EOF Cost Codes */

	renderAutoCorrectionWidgets() {
		this.clearBookingAutoCorrectionAdapter();

		const {
			l10nLastNamePlaceholder,
			l10nFirstNamePlaceholder,
			l10nMiddleNamePlaceholder,
			l10nSecondNamePlaceholder,
		} = getAutoCorrectionWidgetsLabels({
			langRegistrationFullNameUid: this.options?.langRegistrationFullName?.uid,
			forbidTravellerUpdate: this.forbidTravellerUpdate,
		});

		if (STATE.getLoggedInUser() && !this.forbidTravellerUpdate) {
			const widget = new PassengerAutocompleteWidget({
				bindingProperty: 'passport.lastName',
				transliterationOn: true,
				label: L10N.get('bookingForm.lastName'),
				langRegistrationFullName: this.options?.langRegistrationFullName?.uid,
				placeholder: l10nLastNamePlaceholder,
				autoCorrectionAdapter: this.bookingAutoCorrectionAdapter,
				corporatePassengers: this.corporatePassengers,
			});
			widget.applyBinding(this.model);
			this.$el.find('.js-lastName-container').append(widget.$el);
			this.bookingAutoCorrectionAdapter.addWidget(widget);
		} else {
			const widget = new InputWidget({
				bindingProperty: 'passport.lastName',
				transliterationOn: true,
				runTransliterationOnFirstRender: true,
				label: L10N.get('bookingForm.lastName'),
				placeholder: l10nLastNamePlaceholder,
				disabled: this.forbidTravellerUpdate,
				autoCorrectionAdapter: this.bookingAutoCorrectionAdapter,
			});
			widget.applyBinding(this.model);
			this.$el.find('.js-lastName-container').append(widget.$el);
			this.bookingAutoCorrectionAdapter.addWidget(widget);
		}

		{
			const widget = new InputWidget({
				bindingProperty: 'passport.firstName',
				transliterationOn: true,
				runTransliterationOnFirstRender: true,
				label: L10N.get('bookingForm.firstName'),
				placeholder: l10nFirstNamePlaceholder,
				disabled: this.forbidTravellerUpdate,
				autoCorrectionAdapter: this.bookingAutoCorrectionAdapter,
			});
			widget.applyBinding(this.model);
			this.$el.find('.js-firstName-container').append(widget.$el);
			this.bookingAutoCorrectionAdapter.addWidget(widget);
		}

		if (this.isMiddleNameRequired) {
			const widget = new MiddleNameWidget({
				bindingProperty: 'passport.middleName',
				label: L10N.get('bookingForm.middleName'),
				transliterationOn: true,
				runTransliterationOnFirstRender: true,
				placeholder: l10nMiddleNamePlaceholder,
				disabled: this.forbidTravellerUpdate,
				forbidEdit: this.forbidTravellerUpdate,
				withoutMiddleName: true,
				autoCorrectionAdapter: this.bookingAutoCorrectionAdapter,
			});
			widget.applyBinding(this.model);
			this.$el.find('.js-middleName-container').append(widget.$el);
			this.bookingAutoCorrectionAdapter.addWidget(widget);
		}

		{
			const widget = new InputWidget({
				bindingProperty: 'passport.secondName',
				transliterationOn: !!this.options?.langRegistrationFullName?.uid,
				label:  L10N.get('bookingForm.secondName'),
				placeholder: l10nSecondNamePlaceholder,
				runTransliterationOnFirstRender: true,
				disabled: this.forbidTravellerUpdate,
				autoCorrectionAdapter: this.bookingAutoCorrectionAdapter,
			});
			widget.applyBinding(this.model);
			this.$el.find('.js-secondName-container').append(widget.$el);
			this.bookingAutoCorrectionAdapter.addWidget(widget);
		}
	},

	open(e) {
		const $target = $(e.currentTarget);
		if ($target.hasClass('b-traveller__discount-is-closed')) {
			$target.removeClass('b-traveller__discount-is-closed');
			$target.next().slideDown();
		} else {
			$target.addClass('b-traveller__discount-is-closed');
			$target.next().slideUp();
		}
	},

	clearBookingAutoCorrectionAdapter() {
		if (this.bookingAutoCorrectionAdapter) {
			this.bookingAutoCorrectionAdapter.clearRunningRequest();
			this.bookingAutoCorrectionAdapter.removeAllWidgets();
		}
	},

	remove() {
		this.clearBookingAutoCorrectionAdapter();
		this.removeCostCodesAdapter();
		BaseView.prototype.remove.call(this);
	},
	
});
