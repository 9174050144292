module.exports = function(obj) {
obj || (obj = {});
var __t, __p = '', __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
with (obj) {
__p += '<div class="b-route-info_railways">\n	';
  _.each(reservation.carriages, function (c, index) {
		var dateDeparture = new Time(c.segment.departureDate);
		var dateArrival = new Time(c.segment.arrivalDate);

		var dateDepartureLocal = c.segment.localDepartureDate ? new Time(c.segment.localDepartureDate) : null;
		var dateArrivalLocal = c.segment.localArrivalDate ? new Time(c.segment.localArrivalDate) : null;

		var totalDurationInMinutes = c.segment.durationInMinutes; ;
__p += '\n\n	<div class="b-train-ticket__wrapper b-train-ticket__visible" data-carriage-id="' +
((__t = ( index )) == null ? '' : __t) +
'">\n		<div class="b-train-ticket">\n			';
 if (_.size(reservation.carriages) > 1) { ;
__p += '\n			<div class="b-train-ticket__direction">\n				' +
((__t = ( index === 0 ? L10N.get("trains.there") : L10N.get("trains.backward") )) == null ? '' : __t) +
'</div>\n            <span class="js-copy-content copy-content">&#10;&#13;---- ' +
((__t = ( index === 0 ? L10N.get("trains.there") : L10N.get("trains.backward") )) == null ? '' : __t) +
' ----&#10;&#13;</span>\n			';
 } ;
__p += '\n            <span class="js-copy-content copy-content">' +
((__t = ( L10N.get('trains.train') )) == null ? '' : __t) +
': <span data-replace-newline=" " data-copy-content-from="[data-carriage-id=\'' +
((__t = ( index )) == null ? '' : __t) +
'\'] .b-info-train"></span></span>\n            <span class="js-copy-content copy-content">' +
((__t = ( L10N.get('trains.wagon') )) == null ? '' : __t) +
': ' +
((__t = ( c.number )) == null ? '' : __t) +
'</span>\n            <span class="js-copy-content copy-content">' +
((__t = ( L10N.get('trains.seats') )) == null ? '' : __t) +
': ' +
((__t = ( c.places.join(', ') )) == null ? '' : __t) +
'</span>\n            <span class="js-copy-content copy-content">' +
((__t = ( L10N.get('trains.arrivalLocation') )) == null ? '' : __t) +
': <span data-replace-newline=" " data-copy-content-from="[data-carriage-id=\'' +
((__t = ( index )) == null ? '' : __t) +
'\'] .b-route__departure .b-route__terminal"></span> - ' +
((__t = ( L10N.get('trains.arrivalLocation') )) == null ? '' : __t) +
': <span data-replace-newline=" " data-copy-content-from="[data-carriage-id=\'' +
((__t = ( index )) == null ? '' : __t) +
'\'] .b-route__arrival .b-route__terminal"></span></span>\n            <span class="js-copy-content copy-content">' +
((__t = ( L10N.get('transfers.departureDate') )) == null ? '' : __t) +
': <span data-replace-newline=" " data-copy-content-from="[data-carriage-id=\'' +
((__t = ( index )) == null ? '' : __t) +
'\'] .b-route__departure .b-route__date"></span> ' +
((__t = ( L10N.get('trains.localTimeInfo') )) == null ? '' : __t) +
' ';
 if (c.segment && c.segment.departureMoscowTimeZone === true) { ;
__p += '(' +
((__t = ( L10N.get('trains.moscowTime') )) == null ? '' : __t) +
' - ' +
((__t = ( dateDeparture.toShortTime() )) == null ? '' : __t) +
' ' +
((__t = ( dateDeparture.toShortDate() )) == null ? '' : __t) +
') ';
 } ;
__p += '</span>\n            <span class="js-copy-content copy-content">' +
((__t = ( L10N.get('transfers.arrivalDate') )) == null ? '' : __t) +
': <span data-replace-newline=" " data-copy-content-from="[data-carriage-id=\'' +
((__t = ( index )) == null ? '' : __t) +
'\'] .b-route__arrival .b-route__date"></span> ' +
((__t = ( L10N.get('trains.localTimeInfo') )) == null ? '' : __t) +
' ';
 if (c.segment && c.segment.arrivalMoscowTimeZone === true) { ;
__p += '(' +
((__t = ( L10N.get('trains.moscowTime') )) == null ? '' : __t) +
' - ' +
((__t = ( dateArrival.toShortTime() )) == null ? '' : __t) +
' ' +
((__t = ( dateArrival.toShortDate() )) == null ? '' : __t) +
') ';
 } ;
__p += '</span>\n            <span class="js-copy-content copy-content" data-replace-newline=" ">' +
((__t = ( L10N.get('filters.travelTime') )) == null ? '' : __t) +
': <span data-copy-content-from=".b-route__info-duration"></span></span>\n            <span class="js-copy-content copy-content">' +
((__t = ( L10N.get('trains.class') )) == null ? '' : __t) +
': ' +
((__t = (  c.type && c.type.caption ? c.type.caption : '-' )) == null ? '' : __t) +
', ' +
((__t = ( c.serviceClass && c.serviceClass.caption ? c.serviceClass.caption : '-' )) == null ? '' : __t) +
'</span>\n			<div class="b-train-ticket__header">\n				<div class="b-train-ticket__info-train b-info-train">\n					<span class="b-info-train__number">\n						№ ' +
((__t = ( c.segment.trainNumber )) == null ? '' : __t) +
'' +
((__t = ( !c.trainBrand && c.trainCategory ? ',' : '' )) == null ? '' : __t) +
'\n					</span>\n\n					';
 if (c.trainBrand != null) { ;
__p += '\n					&mdash;\n					<span class="b-info-train__brand">\n						' +
((__t = ( c.trainBrand )) == null ? '' : __t) +
'' +
((__t = ( c.trainCategory ? ',' : '' )) == null ? '' : __t) +
'\n					</span>\n					';
 } ;
__p += '\n					';
 if (c.trainCategory) { ;
__p += '\n					<span class="b-info-train__description">' +
((__t = ( c.trainCategory )) == null ? '' : __t) +
'</span>\n					';
 } ;
__p += '\n				</div>\n				';
 if (c.disabled === true) { ;
__p += '\n				<div class="b-train-ticket__info-disabled">\n					<span class="b-train-ticket__info-disabled-icon" data-toggle="tooltip" data-placement="bottom"\n						title="' +
((__t = ( STATE.escape(c.disableReason) )) == null ? '' : __t) +
'"></span>\n				</div>\n				';
 } ;
__p += '\n				<div class="b-train-ticket__info-common">\n					';
 if (c.eRegEnabled === true) { ;
__p += '\n					<span class="b-train-ticket__info-common-tags b-train-ticket__tags">\n						<span class="red-tag" data-toggle="tooltip" data-html="true" data-placement="bottom"\n							data-original-title="<div class=\'tooltip-left\'>' +
((__t = ( L10N.get('trains.eRegister') )) == null ? '' : __t) +
'</div>">' +
((__t = ( L10N.get('trains.eRegisterShort') )) == null ? '' : __t) +
'</span>\n					</span>\n					';
 } ;
__p += '\n					';
 if (c.hasNonRefundableTariff === true) { ;
__p += '\n					<div class="b-train__carriage-refundable g-icon-option-refund-exchange-OFF-black"\n						data-toggle="tooltip" data-placement="bottom" title="' +
((__t = ( L10N.get('trains.nonRefundable') )) == null ? '' : __t) +
'">\n                        <span class="js-copy-content copy-content">' +
((__t = ( L10N.get('trains.nonRefundable') )) == null ? '' : __t) +
'</span>\n					</div>\n					';
 } ;
__p += '\n				</div>\n				<div class="b-train-ticket__info-services train-services-gray">\n                    ';
 const copyContent = [];
__p += '\n					';
 _.each(_.sortBy(c.services, function (s) {
							return s.uid;
						}), function(service) {
						const value = STATE.escape(service.caption)
                        copyContent.push(value) ;
__p += '\n					<div class="train-service__img train-service__img--color-gray train-service__' +
((__t = ( service.uid )) == null ? '' : __t) +
'"\n						data-toggle="tooltip" data-placement="bottom" title="' +
((__t = ( value )) == null ? '' : __t) +
'">\n					</div>\n					';
 }); ;
__p += '\n                    <span class="js-copy-content copy-content">' +
((__t = ( copyContent.join(', ') )) == null ? '' : __t) +
'</span>\n				</div>\n			</div>\n			<div class="b-train-ticket__route-map">\n                <div class="b-train-ticket__mobile desktop-hide">\n                    <div class="b-train-ticket__mobile-dates">\n					    <span class="b-train-ticket__mobile-date-time">\n					    	' +
((__t = ( (dateDepartureLocal || dateDeparture).toShortTime() )) == null ? '' : __t) +
'\n					    </span>\n                        <span class="b-train-ticket__mobile-duration">' +
((__t = ( Formatter.formatDurationFromPattern(totalDurationInMinutes) )) == null ? '' : __t) +
'</span>\n                        <span class="b-train-ticket__mobile-date-time">\n						    ' +
((__t = ( (dateArrivalLocal || dateArrival).toShortTime() )) == null ? '' : __t) +
'\n					    </span>\n                    </div>\n                    <div class="b-train-ticket__mobile-route-line"></div>\n                    <div class="b-train-ticket__mobile-route-info">\n                        <span class="b-train-ticket__mobile-route-location">\n                            ';
 if (c.segment && c.segment.departureLocation && c.segment.departureLocation.caption) { ;
__p += '\n                                ' +
((__t = ( c.segment.departureLocation.caption.trim() )) == null ? '' : __t) +
'\n                            ';
 } ;
__p += '\n                        </span>\n                        <span class="b-train-ticket__mobile-route-location">\n                            ';
 if (c.segment && c.segment.arrivalLocation && c.segment.arrivalLocation.caption) { ;
__p += '\n                                ' +
((__t = ( c.segment.arrivalLocation.caption.trim() )) == null ? '' : __t) +
'\n                            ';
 } ;
__p += '\n                        </span>\n                    </div>\n                    <div class="b-train-ticket__mobile-reservation-info">\n                        <div class="b-train-ticket__mobile-row">\n                            <span class="info-caption">' +
((__t = ( L10N.get('trains.train'))) == null ? '' : __t) +
'</span> <span class="info-value">' +
((__t = ( c.segment.trainNumber )) == null ? '' : __t) +
'</span>\n                        </div>\n                        <div class="b-train-ticket__mobile-row train-info">\n                            <span>\n                                <span class="info-caption">' +
((__t = ( L10N.get('trains.wagon') )) == null ? '' : __t) +
'</span>\n                                <span class="info-value">' +
((__t = ( c.number )) == null ? '' : __t) +
'</span>\n                            </span>\n                            <span>\n                                <span class="info-caption">' +
((__t = ( L10N.get('trains.seats') )) == null ? '' : __t) +
'</span>\n                                <span class="info-value">' +
((__t = ( c.places.join(', ') )) == null ? '' : __t) +
'</span>\n                            </span>\n                            <span>\n                                <span class="info-caption">' +
((__t = ( L10N.get('trains.class') )) == null ? '' : __t) +
'</span>\n                                <span class="info-value">' +
((__t = ( c.type && c.type.caption )) == null ? '' : __t) +
'</span>\n                            </span>\n                        </div>\n                    </div>\n                </div>\n				<div class="b-train-ticket__route b-route">\n					';

							var dateDepartureTooltip = "<div class='text-align-center' style='white-space: pre-wrap'>" + L10N.getUnescaped('trains.cardLocalTime');
							if (c.segment.departureMoscowTimeZone === true) {
								dateDepartureTooltip += '<br />\n' + L10N.getUnescaped('trains.moscowTime') + ' - <strong>' + dateDeparture.toShortTime() + ' ' + dateDeparture.toShortDate() + '</strong>';
							}
							dateDepartureTooltip += '</div>';

							var dateArrivalTooltip = "<div class='text-align-center' style='white-space: pre-wrap'>" + L10N.getUnescaped('trains.cardLocalTime');
							if (c.segment.arrivalMoscowTimeZone === true) {
								dateArrivalTooltip += '<br />\n' + L10N.getUnescaped('trains.moscowTime') + ' - <strong>' + dateArrival.toShortTime() + ' ' + dateArrival.toShortDate() + '</strong>';
							}
							dateArrivalTooltip += '</div>';
						;
__p += '\n					<div class="b-route__path">\n						<div class="b-route__departure">\n							<div class="b-route__date" data-toggle="tooltip" data-html="true" data-placement="bottom"\n								data-original-title="' +
((__t = ( dateDepartureTooltip )) == null ? '' : __t) +
'">\n								<div class="b-route__date-time">\n									' +
((__t = ( (dateDepartureLocal || dateDeparture).toShortTime() )) == null ? '' : __t) +
'</div>\n								<div class="b-route__date-info">\n									' +
((__t = ( (dateDepartureLocal || dateDeparture).toShortDate() )) == null ? '' : __t) +
'</div>\n							</div>\n							<div class="b-route__terminal" data-toggle="tooltip" data-placement="bottom"\n								title="' +
((__t = ( _.escape(c.segment.departureLocation.caption) )) == null ? '' : __t) +
'">\n								' +
((__t = ( c.segment.departureLocation && c.segment.departureLocation.caption )) == null ? '' : __t) +
'</div>\n						</div>\n						<div class="b-route__info">\n							';
 if (totalDurationInMinutes) { ;
__p += '\n								<div class="b-route__info-duration">' +
((__t = ( Formatter.formatDurationFromPattern(totalDurationInMinutes) )) == null ? '' : __t) +
'</div>\n							';
 } ;
__p += '\n							';
 if (c.train.isRouteAvialible) {;
__p += '\n							<div class="b-route__info-route">\n								<span class="b-route__info-route-link"\n									data-index="' +
((__t = ( index )) == null ? '' : __t) +
'">' +
((__t = ( L10N.get('trains.routeDetails') )) == null ? '' : __t) +
'</span>\n							</div>\n							';
} ;
__p += '\n						</div>\n						<div class="b-route__arrival">\n							<div>\n								<div class="b-route__date" data-toggle="tooltip" data-html="true"\n									data-placement="bottom" data-original-title="' +
((__t = ( dateArrivalTooltip )) == null ? '' : __t) +
'">\n									<div class="b-route__date-time">\n										' +
((__t = ( (dateArrivalLocal || dateArrival).toShortTime() )) == null ? '' : __t) +
'</div>\n									<div class="b-route__date-info">\n										' +
((__t = ( (dateArrivalLocal || dateArrival).toShortDate() )) == null ? '' : __t) +
'</div>\n								</div>\n							</div>\n\n							<div class="b-route__terminal" data-toggle="tooltip" data-placement="bottom"\n								title="' +
((__t = ( _.escape(c.segment.arrivalLocation.caption) )) == null ? '' : __t) +
'">\n								' +
((__t = ( c.segment.arrivalLocation && c.segment.arrivalLocation.caption )) == null ? '' : __t) +
'</div>\n						</div>\n					</div>\n					<div class="b-route__carriage">\n\n            ';

            var carPlaceData = c.carPlaceData || [];
            var places = (c.places || []).map((place) => parseInt(place, 10));
            var seatsMap = carPlaceData.reduce(function(seatsMap, carPlace) {
              carPlace.availableSeats.forEach(function (place) {
                var currentSeat = parseInt(place, 10);
                if (currentSeat && places.includes(currentSeat)) {
                  var position = carPlace.carPlaceType.caption || 'common';
                  if (!seatsMap.hasOwnProperty(position)) {
                    seatsMap[position] = [];
                  }
                  seatsMap[position].push(currentSeat);
                }
              });
              
              return seatsMap;
            }, {});

            var seatsMapKeys = Object.keys(seatsMap);
            var seatsMapTooltip = '<div class="b-offer-seats-tooltip-wrapper">';
              seatsMapKeys.forEach(function (seatsPosition) {
              seatsMapTooltip += '<div><div class="b-offer-seats-tooltip">' + seatsPosition + '</div><div>' + seatsMap[seatsPosition].join(', ') + '</div></div>';
            });
            seatsMapTooltip += '</div>';
            if (seatsMapKeys.length === 0) {
              seatsMapTooltip = "";
            }

            var servicesTooltip = null;
            var carriageServices = (c.services || []);
            carriageServices.map(function(service) {
              return service.caption
            }).forEach(function (service, serviceIndex) {
              if (!servicesTooltip) {
                servicesTooltip = '';
              }
              servicesTooltip += service + (serviceIndex < carriageServices.length - 1 ? ', <br/>' : '');
            });
            ;
__p += '\n						';
 if (c.trainBrand != null) { ;
__p += '\n						<div class="b-route__carriage-row">\n							<span>' +
((__t = ( L10N.get('trains.train') )) == null ? '' : __t) +
': ' +
((__t = ( c.trainBrand )) == null ? '' : __t) +
'</span>\n                            <span>' +
((__t = ( L10N.get('trains.wagon') )) == null ? '' : __t) +
': ' +
((__t = ( c.number )) == null ? '' : __t) +
'</span>\n							<span>' +
((__t = ( L10N.get('trains.seats') )) == null ? '' : __t) +
': <span data-toggle="tooltip" data-html="true" data-original-title=\'' +
((__t = ( seatsMapTooltip || c.places.join(', ') )) == null ? '' : __t) +
'\'>' +
((__t = ( c.places.join(', ') )) == null ? '' : __t) +
'</span></span>\n						</div>\n						<div class="b-route__carriage-row">\n							<span>' +
((__t = ( L10N.get('trains.class') )) == null ? '' : __t) +
': <span class="b-train__carriage-service-class-caption b-tooltip-like-link" data-toggle="tooltip" data-placement="bottom" title="' +
((__t = ( STATE.escape(servicesTooltip || (c.serviceClass && c.serviceClass.description)) )) == null ? '' : __t) +
'">' +
((__t = ( c.type && c.type.caption ? c.type.caption : '-'  )) == null ? '' : __t) +
',\n                ' +
((__t = ( c.serviceClass && c.serviceClass.caption ? c.serviceClass.caption : '-' )) == null ? '' : __t) +
'</span>\n              </span>\n							';
 if (c.segment.carWithOtherArrivalTimeExists === true) { ;
__p += '\n							<span>\n								<div class="b-route__arrival-notification" data-toggle="tooltip" data-html="true"\n									data-placement="bottom"\n									data-original-title="<div><strong>' +
((__t = ( L10N.get('trains.attention') )) == null ? '' : __t) +
'!</strong><br/><br/> ' +
((__t = ( L10N.get('trains.wagonArrivalTime') )) == null ? '' : __t) +
' ' +
((__t = ( c.number )) == null ? '' : __t) +
' ' +
((__t = ( L10N.get('trains.timeDiffersFromTrainTime') )) == null ? '' : __t) +
' ' +
((__t = ( c.segment.trainNumber )) == null ? '' : __t) +
'</div>">\n								</div>\n							</span>\n							<span>\n								' +
((__t = ( L10N.get('trains.arrive') )) == null ? '' : __t) +
':\n								<strong data-toggle="tooltip" data-html="true" data-placement="bottom"\n									data-original-title="' +
((__t = ( dateArrivalTooltip )) == null ? '' : __t) +
'">\n									' +
((__t = ( (dateArrivalLocal || dateArrival).toShortTime() )) == null ? '' : __t) +
'\n									' +
((__t = ( (dateArrivalLocal || dateArrival).toShortDate() )) == null ? '' : __t) +
'\n								</strong>\n							</span>\n							';
 } ;
__p += '\n						</div>\n						';
 } else { ;
__p += '\n						<div class="b-route__carriage-row">\n							<span>' +
((__t = ( L10N.get('trains.wagon') )) == null ? '' : __t) +
': ' +
((__t = ( c.number )) == null ? '' : __t) +
'</span>\n							<span>' +
((__t = ( L10N.get('trains.seats') )) == null ? '' : __t) +
': <span data-toggle="tooltip" data-html="true" data-original-title=\'' +
((__t = ( seatsMapTooltip || c.places.join(', ') )) == null ? '' : __t) +
'\'>' +
((__t = ( c.places.join(', ') )) == null ? '' : __t) +
'</span></span>\n							<span>' +
((__t = ( L10N.get('trains.class') )) == null ? '' : __t) +
': <span class="b-train__carriage-service-class-caption b-tooltip-like-link" data-toggle="tooltip" data-placement="bottom" title="' +
((__t = ( STATE.escape(servicesTooltip || (c.serviceClass && c.serviceClass.description)) )) == null ? '' : __t) +
'">' +
((__t = ( c.type && c.type.caption ? c.type.caption : '-' )) == null ? '' : __t) +
',\n								' +
((__t = ( c.serviceClass && c.serviceClass.caption ? c.serviceClass.caption : '-' )) == null ? '' : __t) +
'</span>\n              </span>\n						</div>\n						<div class="b-route__carriage-row">\n							<span></span>\n							';
 if (c.segment.carWithOtherArrivalTimeExists === true) { ;
__p += '\n							<span>\n								<div class="b-route__arrival-notification" data-toggle="tooltip" data-html="true"\n									data-placement="bottom"\n									data-original-title="<div><strong>' +
((__t = ( L10N.get('trains.attention') )) == null ? '' : __t) +
'!</strong><br/><br/> ' +
((__t = ( L10N.get('trains.wagonArrivalTime') )) == null ? '' : __t) +
' ' +
((__t = ( c.number )) == null ? '' : __t) +
' ' +
((__t = ( L10N.get('trains.timeDiffersFromTrainTime') )) == null ? '' : __t) +
' ' +
((__t = ( c.segment.trainNumber )) == null ? '' : __t) +
'</div>">\n								</div>\n							</span>\n							<span>\n								' +
((__t = ( L10N.get('trains.arrive') )) == null ? '' : __t) +
':\n								<strong data-toggle="tooltip" data-html="true" data-placement="bottom"\n									data-original-title="' +
((__t = ( dateArrivalTooltip )) == null ? '' : __t) +
'">\n									' +
((__t = ( (dateArrivalLocal || dateArrival).toShortTime() )) == null ? '' : __t) +
'\n									' +
((__t = ( (dateArrivalLocal || dateArrival).toShortDate() )) == null ? '' : __t) +
'\n								</strong>\n							</span>\n							';
 } ;
__p += '\n						</div>\n						';
 } ;
__p += '\n					</div>\n				</div>\n				<div class="b-train-ticket__map"></div>\n			</div>\n		</div>\n	</div>\n	';
 }); ;
__p += '\n</div>\n';

}
return __p
}