// eslint-disable-next-line banned-modules
'use strict';

import './style.less';
import BaseView from '@/classes/base.view';
import template from './template.ejs';
import TravelerModel from './model';
import SelectWidget from '@/widgets/b-select/index';
import CheckBoxWidget from '@/widgets/b-checkbox/';
import InputWidget from '@/widgets/b-input/index';
import MiddleNameWidget from '@/widgets/b-middle-name/index';
import PassengerAutocompleteWidget from '@/widgets/autocomplete-input/b-passenger/index';
import BookingAutoCorrectionAdapter from '../../../utils/b-booking-auto-correction-adapter';
import { getAutoCorrectionWidgetsLabels } from '../../../utils/b-booking-auto-correction-input-labels';
import TravellerCostCodesAdapter from '../../b-cost-codes/b-traveller-cost-codes-adapter';
import GlUl from '@/utils/global-utils';
import $ from 'jquery';
import axios from 'axios';

export default BaseView.extend({

	ui: {
		expiredDate: '.b-traveller__passport-expired-date',
		statisticsContainer: '.b-traveller-cost-codes__container',
		bonusCardsContainer: '.b-traveller__bonus-cards',
		passportNumber: '.b-traveller__passport-number',
		additionalConditions: '.b-traveller__additional-conditions',
		citizenshipField: '.b-traveller__citizenship',
		originCountryField: '.b-traveller__origin-country',
	},

	events: {
		'click .b-traveller__discount-open': 'open',
		'click .b-traveller__remove': 'removeTraveller',
	},

	initialize(options) {
		this.useLangTwoLocales = false;
		this.isInternalRU = STORE.get('trains/passengers').routeInfo.train.isInternalRU;
		const settings = STATE.getSettings();
		this.bookingSettingsParams = options.bookingSettingsParams;
		this.corporatePassengers = options.bookingSettings.corporatePassengers;
		this.forbidTravellerUpdate = this.options.bookingSettings.forbidTravellerUpdate;
		this.regionalTypeKZ = settings && settings.visualSettings && settings.visualSettings.regionalTypeKZ;
		this.sortingType = this.isInternalRU ? 'INTERNAL' : 'FOREIGN';
		this.bonusCardsEnabled = options.bookingSettings.bonusCardAvailable;
		this.bookingInfoData = options.bookingInfoData;
		this.contactPersonEditGranted = options.contactPersonEditGranted;
		this.contactPersonDisableFreeTextInput = options.contactPersonDisableFreeTextInput;
		this.tariffs = options.tariffs;
		this.index = options.index;
		this.organizationCostCodes = options?.bookingSettings?.organizationCostCodes; // при инициализации используем общие (глобальные) кост коды
		this.getFormSettings = this.getBookingFormSettings.bind(this);
		this.bookingFormSettingsToken = options.bookingFormSettingsToken || undefined;

		if (!this.model) {
			this.model = new TravelerModel();
			options.parentModel.add(this.model);
		}

		if (options.isContact != null) this.model.set('isContact', options.isContact);

		this.isMiddleNameRequired = true;
		this.isAdult = this.model.get('type').uid === 'ADULT';

		// Sort passports before set in fields
		if (this.options.passenger && this.options.passenger.passports) {
			this.sortPassports(this.options.passenger.passports, this.sortingType);
		}

		this.bookingAutoCorrectionAdapter = new BookingAutoCorrectionAdapter(
			{
				routeType: STATE.router.breadcrumbs.routeType,
				transliterationOpts: this.getTransliterationOpts(),
			},
		);

		this.listenTo(
			this.model.get('passport'),
			'change:lastName change:firstName change:middleName change:secondName',
			() => {
				if (this.bookingAutoCorrectionAdapter) {
					this.bookingAutoCorrectionAdapter.updateTransliterationOpts(
						this.getTransliterationOpts(),
					);
				}
			},
		);

		this.setUseLang();

		// специальный слушатель для useLang (вкл/выкл)
		this.listenTo(
			this.model,
			`change:useLangTwoLocales`,
			async (_, value) => {
				if (value !== this.useLangTwoLocales) {
					this.useLangTwoLocales = value;
					const container = this.$el;
					this.$el.empty();

					this.resetNonCommonCostCodes();

					this.render();
					container.append(this.$el);
  
					this.options.parent.adjustMobileTemplate(
						STATE.checkViewport('(max-width: 768px)'),
					);

					this.trigger('travellerPersonChanged', this);
				} else {
					this.useLangTwoLocales = value;
				}
			});

		// заполняем данные пассажира при первой отрисовке
		
		GlUl.fillPassengerData({
			passengerUid: options.passenger && options.passenger.uid,
			passengerTypeUid: this.model.get('type') && this.model.get('type').uid,
			passengerTypeDocument: this.options.parent.options.bookingSettings.passengerTypeDocument,
			model: this.model,
			fillOnLoad: true,
			bookingSettings: this.options.parent.options.bookingSettings,
			userSelectedPassenger: this.userSelectedPassenger,
		});

		// LISTENERS
		this.listenTo(this.model.get('passport'), `change:type`, async (thisModel, value) => {
			const container = this.$el;
			const selectedValue = {...value};

			// UTS-357
			// autofill citizenship
			GlUl.fillCitizenshipByPassportType(thisModel, value);

			// GlUl.fillPassengerData({
			// 	passengerUid: options.passenger && options.passenger.uid,
			// 	passengerTypeUid: this.model.get('type') && this.model.get('type').uid,
			// 	passengerTypeDocument: this.options.parent.options.bookingSettings.passengerTypeDocument,
			// 	model: this.model,
			// 	fillOnLoad: false,
			// 	bookingSettings: this.options.parent.options.bookingSettings,
			// 	userSelectedPassportType: value,
			// 	userSelectedPassenger: this.userSelectedPassenger,
			// });
			this.resetNonCommonCostCodes();

			await this.checkRegistrationLang();
			this.setUseLang();
			// if (value && value.uid === 'INTERNAL') {
			// 	const RU = STATE.get('countries').find(el => el.uid === 'RU');
			// 	this.model.get('passport').set('citizenship', RU);
			// 	this.model.get('passport').set('originCountry', RU);
			// }

			if (value == null) {
				this.ui.expiredDate.show();
			} else {
				const passportExists = (value.uid.indexOf('.') !== -1);
				const type = (passportExists) ? value.uid.split('.')[0] : value.uid;
  
				this.setDocumentPlaceholder(type);
  
				if (['INTERNAL', 'BIRTHDAY_NOTIFICATION'].includes(type)) {
					this.ui.expiredDate.hide().find('input').attr('readonly', 'readonly');
				} else {
					this.ui.expiredDate.show().find('input').removeAttr('readonly');
				}
			}
			GlUl.fillPassengerData({
				passengerUid: options.passenger && options.passenger.uid,
				passengerTypeUid: this.model.get('type') && this.model.get('type').uid,
				passengerTypeDocument: this.options.parent.options.bookingSettings.passengerTypeDocument,
				model: this.model,
				fillOnLoad: false,
				bookingSettings: this.options.parent.options.bookingSettings,
				userSelectedPassportType: selectedValue,
				userSelectedPassenger: this.userSelectedPassenger,
			});
		
			if (this.regionalTypeKZ && this.options?.passenger?.IIN != null) {
				this.model.set('IIN', this.options.passenger.IIN);
			}

			this.$el.empty();
			this.render();
			// this.changePassportType(value, false);
			container.append(this.$el);
			this.options.parent.adjustMobileTemplate(STATE.checkViewport('(max-width: 768px)'));

			this.trigger('travellerPersonChanged', this);
		});

		if (this.model.get('tariff') != null) {
			this.model.set(`selectedTariff${this.index}`, this.model.get('tariff').uid);
		}
		this.listenTo(this.model, `change:selectedTariff${this.index}`, (model, value) => {
			if (value != null) this.changeTariff(value);
		});

		this.updateWithoutMiddleName(!!this.model.get('withoutMiddleName'));

		this.listenTo(this.model, 'change:useLang', () => {
			const container = this.$el;
			this.$el.empty();

			this.resetNonCommonCostCodes();

			// this.autoCompletePassport(this.model.get('passport').get('type'), v);
			this.render();
			container.append(this.$el);
			this.options.parent.adjustMobileTemplate(STATE.checkViewport('(max-width: 768px)'));

			GlUl.fillPassengerData({
				passengerUid: options.passenger && options.passenger.uid,
				passengerTypeUid: this.model.get('type') && this.model.get('type').uid,
				passengerTypeDocument: this.options.parent.options.bookingSettings.passengerTypeDocument,
				model: this.model,
				fillOnLoad: false,
				bookingSettings: this.options.parent.options.bookingSettings,
				userSelectedPassenger: this.userSelectedPassenger,
			});

			this.trigger('travellerPersonChanged', this);
		});

		this.listenTo(this.model.get('passport'), `change:originCountry`, async () => {
			await this.checkRegistrationLang();
		});
		this.listenTo(this.model.get('passport'), 'change:citizenship', async (model, value) => {
			await this.checkRegistrationLang();
			const $input = this.$el.find('.b-traveller__kz-iin .b-input__value');
			if (value && value.uid === 'KZ') {
				$input.removeAttr('disabled');
			} else {
				$input.attr('disabled', 'disabled');
			}
		});

		this.listenTo(this.model.get('passport'), `change:expiredDate change:birthDate`, this.checkExpiredDate.bind(this));

		this.listenTo(this.model, `change:birthDate`, (model, value) => {
			// BUGFIX! IBECORP-3338
			// Controll widget sets current date instead of empty
			// Fix this
			const currentDate = new Date();
			if (value === new Time(currentDate).toServerDate()) {
				this.model.set('passport.birthDate', null);
				this.model.set('birthDate', null);
				return;
			}
			this.model.set('passport.birthDate', value);
		});

		this.listenTo(this.model, `change:railBonusCard`, (model, value) => {
			const railBonusCard = _.extend({number: null}, value);
			_.each(railBonusCard, (v, k) => {
				model.trigger(`change:railBonusCard.${k}`, model, v);
			});
		});

		this.listenTo(this.model, `change:withoutMiddleName`, (model, value) => {
			this.updateWithoutMiddleName(value);
		});

		this.listenTo(this.model, `typeahead:passengerSelect`, async (model, value) => {
			this.resetNonCommonCostCodes();
			await this.getBookingFormSettings(); // <- обновляем список костКодов

			const container = this.$el;
			this.options.passenger = _.omit(value, 'uid');
			this.userSelectedPassenger = value;
			const userSelectedPassengerPassport = !_.isEmpty(this.userSelectedPassenger.passports) ? this.userSelectedPassenger.passports[0] : undefined;
			this.userSelectedPassportType = userSelectedPassengerPassport ? {
				...userSelectedPassengerPassport.type,
				originalUid: `${userSelectedPassengerPassport.type.uid}.${userSelectedPassengerPassport.number}`} : undefined;
			//
			// this.autoCompleteData(this.options.passenger, true);
			GlUl.fillPassengerData({
				passengerUid: options.passenger && options.passenger.uid,
				passengerTypeUid: this.model.get('type') && this.model.get('type').uid,
				passengerTypeDocument: this.options.parent.options.bookingSettings.passengerTypeDocument,
				model: this.model,
				fillOnLoad: false,
				bookingSettings: this.options.parent.options.bookingSettings,
				userSelectedPassenger: {
					...this.userSelectedPassenger,
					isCorporate: !_.isEmpty(this.corporatePassengers),
				},
				userSelectedPassportType: this.userSelectedPassportType,
			});
			this.model.set('railBonusCard', null);

			this.$el.empty();
			this.render(true);
			container.append(this.$el);

			// this.changePassportType(this.model.get('passport').get('type'));
			this.options.parent.adjustMobileTemplate(STATE.checkViewport('(max-width: 768px)'));

			if (this.regionalTypeKZ && this.options.passenger.IIN != null) {
				this.model.set('IIN', this.options.passenger.IIN);
			}

			this.trigger('travellerPersonChanged', this);

			/*
				HACK IBECORP-7327: т.к. GlUl.fillPassengerData() будет что-то обновлять
				асинхронно через setTimeout (например, originCountry и citizenship), получается, что на момент
				первого запроса this.getBookingFormSettings() у нас еще не будет всех заполненных данных, влияющих
				на локаль. setUseLang() тоже дергать рано, т.к. это приведет к багу в переключалке языка.
				Поэтому мы помещаем костыль сюда - ждем, когда отработают все model.set() по таймауту и после этого
				снова запрашиваем this.getBookingFormSettings() со всеми актуальными данными.
			*/
			setTimeout(async () => {
				await this.getBookingFormSettings();
				this.setUseLang();
				GlUl.fillPassengerData({
					passengerUid: options.passenger && options.passenger.uid,
					passengerTypeUid: this.model.get('type') && this.model.get('type').uid,
					passengerTypeDocument: this.options.parent.options.bookingSettings.passengerTypeDocument,
					model: this.model,
					fillOnLoad: false,
					bookingSettings: this.options.parent.options.bookingSettings,
					userSelectedPassenger: this.userSelectedPassenger,
					nameFieldsToSkip: ['originCountry', 'citizenship'],
					nameFieldsToTriggerByTimeout: ['originCountry', 'citizenship'],
				});
			}, 0);
		});

		this.render();
		// this.autoCompleteData(this.options.passenger, true);
		// this.changePassportType(this.model.get('passport').get('type'));
	},

	getTransliterationOpts() {
		const opts = {
			traveller: {
				...this.model.toJSON(),
				langRegistrationFullName: this.options.langRegistrationFullName,
			},
			gds: this.options.parent.bookingInfo.routeInfo.train.gds,
		};
		if (
			opts.traveller.passport &&
			opts.traveller.passport.type &&
			opts.traveller.passport.type.uid.indexOf('.') !== -1
		) {
			opts.traveller.passport.type.uid = opts.traveller.passport.type.uid.split('.')[0];
		}
		return opts;
	},

	updateWithoutMiddleName(value) {
		if (value === false) this.$('.b-middle-name__without').html(L10N.get('bookingForm.withMiddleName'));
	},

	hasNonRefundableTariff() {
		const {carriage = {}, seatsRequest = {}} = this.options.parent.bookingInfo.routeInfo || {};
		const {first, second} = seatsRequest.range || {};
		let hasNonRefundableTariff = false;
		_.each(carriage.carPlaceData, (el) => {
			if (_.some(el.availableSeats, (s) => parseInt(s) >= first && parseInt(s) <= second) && el.hasNonRefundableTariff) {
				hasNonRefundableTariff = true;
			}
		});
		return hasNonRefundableTariff;
	},

	async checkRegistrationLang() {
		// clearTimeout(this.timer);
		// this.timer = setTimeout(async () => {
			
		// }, 200);
		
		const {langRegistrationFullName = {}} = this.options.bookingSettings || {};
		this.resetNonCommonCostCodes();
		const formSettings = await this.getBookingFormSettings();
		this.options.bookingSettings = formSettings;
		this.options.parent.options.bookingSettings = formSettings;
		if (formSettings.langRegistrationFullName.uid !== langRegistrationFullName.uid) {
			this.langRegistrationFullName = formSettings.langRegistrationFullName;
			const container = this.$el;
			this.$el.empty();
			this.render();
			container.append(this.$el);

			this.trigger('travellerPersonChanged', this);
			// this.autoCompleteData(_.clone(this.options.passenger));
		}
	},

	getBookingFormSettings() {
		const params = {
			parameters: {
				traveller: this.model.toJSON(),
				...this.bookingSettingsParams,
				bookingFormSettingsToken: this.bookingFormSettingsToken,
			},
		};
		return axios.post('/midoffice/ibecorp-b2b/rail/getBookingFormSettings', params).then((res) => {
			this.organizationCostCodes = res.data.result?.organizationCostCodes || [];
			return res.data.result;
		});
	},

	adjustMobileTemplate(matches) {
		const $travellerContainer = this.$('.b-traveller');
		const $travellerInfo = this.$('.b-traveller-info');
		const $setAsContactEl = this.$('.b-traveller__title-contact');
		const $infoLanguage = this.$('.b-traveller-info_language');
		const $tariffContainer = this.$('.b-traveller-tariff-container');
		const $tariffContainerM = this.$('.b-traveller-info__tariff-container--mobile');
		const $travellerHeader = this.$('.b-traveller__title');
		const $tooltips = this.$('[data-toggle="tooltip"]');

		if (matches) {
			$travellerContainer.prepend($infoLanguage);
			$tariffContainerM.prepend($tariffContainer);
			$travellerInfo.prepend($setAsContactEl);
			_.each($tooltips, (el) => el && this.$(el).tooltip('disable'));
		} else {
			$travellerInfo.prepend($infoLanguage);
			$travellerHeader.append($tariffContainer);
			$travellerHeader.append($setAsContactEl);
			_.each($tooltips, (el) => el && this.$(el).tooltip('enable'));
		}
	},

	changeTariff(value) {
		if (typeof (value) !== 'string') value = value.uid;
		const selectedTariff = this.tariffs[this.model.get('type').uid].find(t => {
			return t.tariffDescr.uid === value;
		});

		const $currentTarget = $(_.find(this.$el.find('.b-traveller-select-trariff'), (el) => $(el).find('input').val() === value));

		const hasNonRefundableTariff = this.options.bookingSettings.tariffDescriptions[selectedTariff.tariffDescr.uid]
			.hasNonRefundableTariff;

		if (selectedTariff.tariffDescr.uid === 'SCHOOL') {
			this.model.set('schoolTariffConfirmation', false);

			const checkbox = new CheckBoxWidget({
				bindingProperty: 'schoolTariffConfirmation',
				label: L10N.get('trains.confirmSchoolTariff'),
			});
			checkbox.applyBinding(this.model);

			this.ui.additionalConditions.html(checkbox.$el);
		} else {
			this.ui.additionalConditions.empty();
		}

		if (hasNonRefundableTariff) {
			this.$el.find('.b-header-nonrefundable-tariff').addClass('open');
		} else {
			this.$el.find('.b-header-nonrefundable-tariff').removeClass('open');
			if (this.model.get('nonRefundableTariff')) {
				this.model.set('nonRefundableTariff', false);
			}
		}

		if (STATE.checkViewport('(max-width: 768px)')) {
			this.$('.b-traveller-info__tariff-caption').html(selectedTariff.tariffDescr && selectedTariff.tariffDescr.caption);
			this.$('.b-traveller-info__tariff-container--mobile').removeClass('show');
		}

		this.model.set('tariff', selectedTariff.tariffDescr);
		this.$el.find('.b-traveller-select-trariff').removeClass('current');
		$currentTarget.addClass('current');
	},

	filterUnavailablePassports(passenger) {
		const {passengerTypeDocument} = this.options.bookingSettings;
		let passportTypes = (passengerTypeDocument.find((el) => el.passengerType.uid === this.model.get('type').uid) || {}).passportTypes || [];
		passportTypes = _.map([...passportTypes], (el) => el && el.uid);

		passenger.passports = _.filter(passenger.passports, (el) => el.type != null && passportTypes.includes(el.type.uid));
	},

	// changePassportType(value) {
	// 	if (value == null) {
	// 		this.ui.expiredDate.show();
	// 	} else {
	// 		const passportExists = (value.uid.indexOf('.') !== -1);
	// 		const type = (passportExists) ? value.uid.split('.')[0] : value.uid;

	// 		this.setDocumentPlaceholder(type);

	// 		if (['INTERNAL', 'BIRTHDAY_NOTIFICATION'].includes(type)) {
	// 			this.ui.expiredDate.hide().find('input').attr('readonly', 'readonly');
	// 		} else {
	// 			this.ui.expiredDate.show().find('input').removeAttr('readonly');
	// 		}
	// 	}

	// 	this.autoCompletePassport(value);
	// },

	setDocumentPlaceholder(type) {
		let placeholder = '123456789';

		switch (type) {
			case 'INTERNAL':
			case 'BACKTOUSSRCERTIFICATE':
				placeholder = '1234567890';
				break;
			case 'PASSPORT':
				placeholder = 'N12345678';
				break;
			case 'BIRTHDAY_NOTIFICATION':
				placeholder = '';
				break;
			case 'MILITARYID':
				placeholder = 'ВБАА123456';
				break;
			case 'SEAMANSID':
				placeholder = '1234567';
				break;
			case 'IDENTITYCARD':
				break;
			default:
				placeholder = '123456789';
				break;
		}

		this.ui.passportNumber.find('input[type="text"]').attr('placeholder', placeholder);
	},

	checkExpiredDate() {
		const type = this.model.get('passport.type');
		const expired = this.model.get('passport.expiredDate');
		if (!expired || !type || !type.uid.includes('INTERNAL')) {
			return;
		}
		const expiredDate = new Time(expired).moment;
		const now = new Time().moment;

		if (expiredDate.isBefore(now)) {
			if (this.model.get('passport.birthDate')) {
				this.model.set('passport.expiredDate', this.calculateExpiredDate(this.model.get('passport.birthDate')));
			} else {
				this.model.set('passport.expiredDate', now.add(10, 'years').add(40, 'days').format('YYYY-MM-DD'));
			}
		}
	},

	calculateExpiredDate(personBirthDate) {
		return GlUl.calculateExpiredDate(personBirthDate);
	},

	// autoCompleteData(passenger, isFirstCall = false) {
	// 	if (!_.isObject(passenger)) {
	// 		return;
	// 	}

	// 	this.filterUnavailablePassports(passenger);

	// 	if (!_.isEmpty(passenger.passports) && isFirstCall) {
	// 		const internal = passenger.passports.find(passport => passport.type && passport.type.uid === 'INTERNAL');

	// 		this.model.set(`passport.type`, internal ? internal.type : passenger.passports[0].type);
	// 	}

	// 	const langRegistrationFullName = this.langRegistrationFullName || this.options.bookingSettings.langRegistrationFullName || {};
	// 	const nameFields = ['firstName', 'secondName', 'lastName', 'middleName'];
	// 	const passengerFields = [..._.map(nameFields, (el) => {
	// 		switch (langRegistrationFullName.uid) {
	// 			case 'LATIN':
	// 				return `${el}Lat`;
	// 			case 'CYRILLIC':
	// 				return el;
	// 			case 'LATIN_AND_CYRILLIC':
	// 				return [`${el}Lat`, el];
	// 			default:
	// 				return el;
	// 		}
	// 	}), 'gender'];
	// 	GlUl.autocompletePassengerFields.call(this, passenger, nameFields, langRegistrationFullName, passengerFields);
	// 	if (this.regionalTypeKZ && passenger.IIN != null) {
	// 		this.model.set('IIN', passenger.IIN);
	// 	}

	// 	if (!_.isEmpty(passenger.passports)) {
	// 		const firstPassportType = passenger.passports[0].type;
	// 		if (!firstPassportType) {
	// 			this.model.set(`passport.type`, firstPassportType);
	// 		}
	// 		this.changePassportType(firstPassportType);
	// 	}
	// },

	// autoCompletePassport(passport, useLangParam) {
	// 	const {passenger} = this.options;
	// 	const passportFields = ['citizenship', 'originCountry', 'number', 'birthDate', 'expiredDate'];

	// 	const useLangFromModel = this.model.get('useLang');
	// 	const useLang = useLangParam || useLangFromModel;

	// 	let data = {};
	// 	const nameFieldsFromPassport = [];
	// 	if (useLang === 'LATIN') {
	// 		nameFieldsFromPassport.push('firstNameLat', 'lastNameLat', 'middleNameLat');
	// 	} else if (useLang === 'CYRILLIC') {
	// 		nameFieldsFromPassport.push('firstName', 'lastName', 'middleName');
	// 	}
	// 	if (passport != null && passenger != null && !_.isEmpty(passenger.passports)) {
	// 		const passportExists = (passport.uid.indexOf('.') !== -1);
	// 		const passportData = _.find(passenger.passports,
	// 			(el) => el.type.uid + (passportExists ? `.${el.number}` : '') === passport.uid) || {};

	// 		data = _.pick(passportData, [...passportFields, ...nameFieldsFromPassport]);

	// 		const langRegistrationDocument = this.options.bookingSettings.langRegistrationDocument;
	// 		const fields = ['firstName', 'lastName', 'middleName'];
	// 		_.each(fields, (key) => {
	// 			let value;
	// 			switch (langRegistrationDocument.uid) {
	// 				case 'LATIN':
	// 					value = passportData[`${key}Lat`] || passenger[`${key}Lat`];
	// 					break;
	// 				case 'CYRILLIC':
	// 					value = passportData[key] !== passenger[key] ? passenger[key] : passportData[key];
	// 					break;
	// 				case 'LATIN_AND_CYRILLIC':
	// 					value = passportData[`${key}Lat`] || passenger[`${key}Lat`] || passportData[key] || passenger[key];
	// 					break;
	// 				default:
	// 					break;
	// 			}
	// 			if (value) {
	// 				this.model.set(`passport.${key}`, value);
	// 			}
	// 		}); 
	// 		// UTS-277 todo вернуть эту логику после добавления признака "заполнять данные из пасспорта"
	// 		if (data.withoutMiddleName === false) this.$('.b-middle-name__without').html(L10N.get('bookingForm.withMiddleName'));
	// 	}

	// 	data = _.defaults(data, _.omit(_.object([...passportFields, ...nameFieldsFromPassport], []), 'birthDate'));
	// 	_.each(data, (value, key) => {
	// 		this.model.set(`passport.${key}`, value);
	// 	});

	// 	if (passport != null) {
	// 		const [type] = passport.uid.split('.');

	// 		if (type === 'INTERNAL' || type === 'FOREIGN') {
	// 			const RU = STATE.get('countries').find(el => el.uid === 'RU');

	// 			if (!data.originCountry) {
	// 				this.model.set('passport.originCountry', RU);
	// 			}
	// 			if (!data.citizenship) {
	// 				this.model.set('passport.citizenship', RU);
	// 			}
	// 		}
	// 	}

	// 	return this;
	// },

	render() {
		const { passengerTypeDocument } = this.options.bookingSettings;
		const { passenger } = this.options;

		const countriesCollection = SelectWidget.dictionaryToCollection(STATE.getCountries());

		let passportTypes = (passengerTypeDocument.find((el) => el.passengerType.uid === this.model.get('type').uid) || {}).passportTypes || [];
		passportTypes = _.map([...passportTypes], (el) => _.extend({}, el));

		const originalPassportTypes = _.map([...passportTypes], (el) => _.extend({}, el));
		if (passenger != null && !_.isEmpty(passenger.passports)) {
			_.each(passenger.passports, (p) => {
				let findedPassport = _.find(passportTypes, (el) => el.uid === p.type.uid);
				let passportExists = false;

				if (findedPassport == null) {
					findedPassport = _.extend({}, _.find(originalPassportTypes, (el) => el.uid === p.type.uid));
					passportExists = !_.isEmpty(findedPassport);
				}

				findedPassport.uid += `.${p.number}`;
				findedPassport.caption += ` <span style="display: none">(${p.number})</span>`;
				findedPassport.originalUid = findedPassport.uid;

				if (passportExists) {
					const index = _.sortedIndex(_.map(passportTypes, (el) => el && el.uid), findedPassport.uid);
					passportTypes.splice(index, 0, findedPassport);
				}
			});
		}

		// if (!this.model.get('useLang')) {
		// 	if (langRegistrationDocument.uid === 'LATIN') {
		// 		this.model.set('useLang', 'LATIN', {silent: true});
		// 	} else if (langRegistrationDocument.uid === 'CYRILLIC' || langRegistrationDocument.uid === 'LATIN_AND_CYRILLIC') {
		// 		this.model.set('useLang', 'CYRILLIC', {silent: true});
		// 	}
		// }

		// this.setUseLang();
		
		this.setElement(template({
			index: this.index,
			model: this.model,
			tariffs: this.tariffs,
			hasNonRefundableTariff: this.hasNonRefundableTariff(),
			tariffDescriptions: this.options.bookingSettings.tariffDescriptions,
			bookingSettings: this.options.bookingSettings,
			bookingInfoData: this.bookingInfoData,
			contactPersonEditGranted: this.contactPersonEditGranted,
			contactPersonDisableFreeTextInput: this.contactPersonDisableFreeTextInput,
			forbidTravellerUpdate: this.forbidTravellerUpdate,
			cid: this.model.cid,
			passportTypes: SelectWidget.storeCollection(
				SelectWidget.dictionaryToCollection(passportTypes),
			),
			countries: SelectWidget.storeCollection(countriesCollection),
			isAdult: this.isAdult,
			isMiddleNameRequired: this.isMiddleNameRequired,
			bonusCardsEnabled: this.bonusCardsEnabled,
			organizationCostCodes: this.organizationCostCodes,
			useTravellerNameFromDocuments: this.options.parent.options.bookingSettings.useTravellerNameFromDocuments,
			useLangTwoLocales: this.useLangTwoLocales,
		}));

		this.renderAutoCorrectionWidgets();

		/* Statistic container */
		this.renderCostCodesView();

		if (this.bonusCardsEnabled === true && passenger != null && !_.isEmpty(passenger.railwayBonusCards)) {
			this.$('.b-traveller__bonus-cards-select').show();

			if (_.size(passenger.railwayBonusCards) > 1) {
				const $container = $('<div class="l-grid-container b-traveller__bonus-cards-select"></div>');
				const widget = new SelectWidget({
					bindingProperty: '_bonusCard',
					label: L10N.get('bookingForm.bonusCards'),
					values: SelectWidget.storeCollection(
						SelectWidget.dictionaryToCollection(_.map(passenger.railwayBonusCards, (el) => ({
							uid: el.number,
							caption: el.number,
						}))),
					),
				});
				widget.applyBinding(this.model);
				$container.append($('<div class="l-grid-layout-33"></div>').append(widget.$el), $('<div class="l-grid-layout-33"></div>'));
				this.ui.bonusCardsContainer.before($container);

				this.listenTo(this.model, 'change:_bonusCard', (model, value) => {
					const railBonusCard = _.find(passenger.railwayBonusCards, (el) => el.number === value.uid);

					if (railBonusCard != null) {
						this.model.set('railBonusCard', railBonusCard);
					}
				});

				this.model.set('_bonusCard', {
					uid: passenger.railwayBonusCards[0].number,
					caption: passenger.railwayBonusCards[0].number,
				});
			} else {
				this.model.set('railBonusCard', passenger.railwayBonusCards[0]);
			}
		} else {
			this.$('.b-traveller__bonus-cards-select').hide();
		}
	},

	/* Cost Codes */

	resetNonCommonCostCodes() {
		if (this.costCodesAdapter) this.costCodesAdapter.resetNonCommonCostCodes();
	},

	getCommonCostCodesRenderParams() {
		return {
			renderContainer: this.ui.statisticsContainer,
			passenger: this.options.passenger,
			organizationCostCodes: this.organizationCostCodes,
		};
	},

	renderCostCodesView() {
		if (!_.isEmpty(this.organizationCostCodes)) {
			if (!this.costCodesAdapter) {
				this.costCodesAdapter = new TravellerCostCodesAdapter({
					parentView: this,
				});
			} else {
				this.costCodesAdapter.removeCostCodesView();
			}
			this.costCodesAdapter.renderCostCodesView({
				...this.getCommonCostCodesRenderParams(),
			});
		}
	},

	removeCostCodesAdapter() {
		if (this.costCodesAdapter) {
			this.costCodesAdapter.removeCostCodesView();
			this.costCodesAdapter = null;
		}
	},

	/* EOF Cost Codes */
  
	setUseLang() {
		const { langRegistrationFullName } = this.options.bookingSettings || {};
		// if (!this.model.get('useLang')) {
		if (langRegistrationFullName.uid === 'LATIN') {
			this.model.set('useLang', 'LATIN', { silent: true });
		} else if (
			langRegistrationFullName.uid === 'CYRILLIC' ||
				langRegistrationFullName.uid === 'LATIN_AND_CYRILLIC'
		) {
			this.model.set('useLang', 'CYRILLIC', { silent: true });
		}
		// }
	},

	// EVENTS
	open(e) {
		const $target = $(e.currentTarget);
		if ($target.hasClass('b-traveller__discount-is-closed')) {
			$target.removeClass('b-traveller__discount-is-closed');
			$target.next().slideDown();
		} else {
			$target.addClass('b-traveller__discount-is-closed');
			$target.next().slideUp();
		}
	},

	removeTraveller() {
		this.trigger('travellerPersonRemoved', this);
		this.model.destroy();
		this.destroy();
	},

	sortPassports(passports, type) {
		passports.sort((a) => {
			if (a.type.uid === type) {
				return -1;
			}
			if (a.type.uid !== type) {
				return 1;
			}
			return 0;
		});
	},

	renderAutoCorrectionWidgets() {
		this.clearBookingAutoCorrectionAdapter();

		const {
			l10nLastNamePlaceholder,
			l10nFirstNamePlaceholder,
			l10nMiddleNamePlaceholder,
			l10nSecondNamePlaceholder,
		} = getAutoCorrectionWidgetsLabels({
			langRegistrationFullNameUid: this.options?.langRegistrationFullName?.uid,
			forbidTravellerUpdate: this.forbidTravellerUpdate,
		});

		if (STATE.getLoggedInUser() && !this.forbidTravellerUpdate) {
			const widget = new PassengerAutocompleteWidget({
				bindingProperty: 'passport.lastName',
				transliterationOn: true,
				label: L10N.get('bookingForm.lastName'),
				langRegistrationFullName: this.options?.langRegistrationFullName?.uid,
				placeholder: l10nLastNamePlaceholder,
				autoCorrectionAdapter: this.bookingAutoCorrectionAdapter,
				corporatePassengers: this.corporatePassengers,
			});
			widget.applyBinding(this.model);
			this.$el.find('.js-lastName-container').append(widget.$el);
			this.bookingAutoCorrectionAdapter.addWidget(widget);
		} else {
			const widget = new InputWidget({
				bindingProperty: 'passport.lastName',
				transliterationOn: true,
				runTransliterationOnFirstRender: true,
				label: L10N.get('bookingForm.lastName'),
				placeholder: l10nLastNamePlaceholder,
				disabled: this.forbidTravellerUpdate,
				autoCorrectionAdapter: this.bookingAutoCorrectionAdapter,
			});
			widget.applyBinding(this.model);
			this.$el.find('.js-lastName-container').append(widget.$el);
			this.bookingAutoCorrectionAdapter.addWidget(widget);
		}

		{
			const widget = new InputWidget({
				bindingProperty: 'passport.firstName',
				transliterationOn: true,
				runTransliterationOnFirstRender: true,
				label: L10N.get('bookingForm.firstName'),
				placeholder: l10nFirstNamePlaceholder,
				disabled: this.forbidTravellerUpdate,
				autoCorrectionAdapter: this.bookingAutoCorrectionAdapter,
			});
			widget.applyBinding(this.model);
			this.$el.find('.js-firstName-container').append(widget.$el);
			this.bookingAutoCorrectionAdapter.addWidget(widget);
		}

		if (this.isMiddleNameRequired) {
			const widget = new MiddleNameWidget({
				bindingProperty: 'passport.middleName',
				label: L10N.get('bookingForm.middleName'),
				transliterationOn: true,
				runTransliterationOnFirstRender: true,
				placeholder: l10nMiddleNamePlaceholder,
				disabled: this.forbidTravellerUpdate,
				forbidEdit: this.forbidTravellerUpdate,
				withoutMiddleName: true,
				autoCorrectionAdapter: this.bookingAutoCorrectionAdapter,
			});
			widget.applyBinding(this.model);
			this.$el.find('.js-middleName-container').append(widget.$el);
			this.bookingAutoCorrectionAdapter.addWidget(widget);
		}

		{
			const widget = new InputWidget({
				bindingProperty: 'passport.secondName',
				transliterationOn: !!this.options?.langRegistrationFullName?.uid,
				label:  L10N.get('bookingForm.secondName'),
				placeholder: l10nSecondNamePlaceholder,
				runTransliterationOnFirstRender: true,
				disabled: this.forbidTravellerUpdate,
				autoCorrectionAdapter: this.bookingAutoCorrectionAdapter,
			});
			widget.applyBinding(this.model);
			this.$el.find('.js-secondName-container').append(widget.$el);
			this.bookingAutoCorrectionAdapter.addWidget(widget);
		}
	},

	clearBookingAutoCorrectionAdapter() {
		if (this.bookingAutoCorrectionAdapter) {
			this.bookingAutoCorrectionAdapter.clearRunningRequest();
			this.bookingAutoCorrectionAdapter.removeAllWidgets();
		}
	},

	remove() {
		this.clearBookingAutoCorrectionAdapter();
		this.removeCostCodesAdapter();
		BaseView.prototype.remove.call(this);
	},

});
