module.exports = function(obj) {
obj || (obj = {});
var __t, __p = '', __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
with (obj) {


	var airlines = [];

	_.each(flightFares, function (el) {
		airlines = airlines.concat(el.airlines);
	});

	airlines = _.without(_.uniq(airlines), flight.carrier.caption);
    var settings = STATE.getSettings();
	var passengerTypes = settings && settings.passengerTypes || [];
	function getLuggage (type, services) {
		var keys = (type === 'luggage') ? ['freeCabinLuggage', 'paidCabinLuggage'] : ['freeLuggage', 'paidLuggage'];
		segmentsLuggage = _.object(keys, [{}, {}]);

		_.each(_.pick(services, keys), function (value, key) {
			_.each(value, function (val, type) {
				if (val.nil === true) {
					return false;
				}

				var connectNounAndNumral = function (number) {
					var tens = Math.abs(number) % 100;
					var units = number % 10;
					if (tens > 10 && tens < 20) {
						return "other";
					}
					if (units > 1 && units < 5) {
						return "few";
					}
					if (units === 1) {
						return "one";
					}
						return "other";
				}

				var result = {
          description: Formatter.formatLuggageDescription(val)
					// description: (val.pieces ? val.pieces : '') + (val.pieces && val.weight != null ? ' x ' : '') + ((val.weight != null) ? ( + val.weight + ' ' + L10N.get("flightlegs.weight")) : val.pieces ? ' ' + (val.unit || L10N.get("flightlegs.piecesCount." + connectNounAndNumral(val.pieces))) : ''),
				};

				result.passenger = _.find(passengerTypes, function (el) {
					return el && el.uid === type;
				});

				if (val.price != null) {
					result.price = Formatter.formatMoney(val.price.amount) + ' ' + Formatter.formatCurrency(val.price.currencyCode);
				}

				if (val.size != null) {
					result.size = val.size != 0 ? L10N.get("avia.upTo") + ' ' + val.size + ' ' + L10N.get("flightlegs.size") : null;
				}



				result.paid = ['paidLuggage', 'paidCabinLuggage'].indexOf(key) !== -1;
				segmentsLuggage[key][type] = result;
			});

			if (_.isEmpty(segmentsLuggage[key])) {
				delete segmentsLuggage[key];
			}
		});

		return segmentsLuggage;
	};

	var issueAllowed = issueAllowed || false;
	var approvalAllowed = approvalAllowed || false;

	var companyLogoInOtherTariffs = flight.carrier && flight.carrier.codeSystem !== 'IATA' ? flight.carrier.airlineCode + '-' + flight.carrier.codeSystem : flight.carrier && flight.carrier.airlineCode || 'noCarrierField';

	var isHasAdditionalInfo = flightFares.some(function (fare) {
		return fare.servicesDetails.additionalDetails
	});
;
__p += '\n\n';
 if (_.size(flightFares) > 0) { ;
__p += '\n	<div class="b-ticket-branded-fares b-branded-fares">\n		<div class="b-branded-fares__controls">\n			<div class="b-branded-fares__control b-branded-fares__slide-left g-icon-arrow-left"></div>\n			<div class="b-branded-fares__control b-branded-fares__slide-right g-icon-arrow-right"></div>\n		</div>\n\n		<div class="b-branded-fares__container">\n			<div class="b-branded-fares__column b-branded-fares__column--type-caption">\n				<div class="b-branded-fares__row">\n					<div class="b-branded-fares__company-logo g-icon" style="background-image: url(\'/assets/img/logos/' +
((__t = ( companyLogoInOtherTariffs )) == null ? '' : __t) +
'-black.svg\')" data-toggle="tooltip" data-placement="bottom"></div>\n					';
 if (_.size(airlines) > 0) { ;
__p += '\n						<div class="b-branded-fares__perfom">\n							' +
((__t = ( L10N.get('ticket.flightBy') )) == null ? '' : __t) +
': <span class="b-branded-fares__perfom-airlines">' +
((__t = ( airlines.join(', ') )) == null ? '' : __t) +
'</span>\n						</div>\n					';
 } ;
__p += '\n				</div>\n				<div class="b-branded-fares__row"><span class="b-branded-fares__row-caption">' +
((__t = ( L10N.get('ticket.baggage') )) == null ? '' : __t) +
'</span></div>\n				<div class="b-branded-fares__row"><span class="b-branded-fares__row-caption">' +
((__t = ( L10N.get('ticket.carryOn') )) == null ? '' : __t) +
'</span></div>\n				<div class="b-branded-fares__row"><span class="b-branded-fares__row-caption">' +
((__t = ( L10N.get('ticket.exchange') )) == null ? '' : __t) +
'</span></div>\n				<div class="b-branded-fares__row"><span class="b-branded-fares__row-caption">' +
((__t = ( L10N.get('ticket.refund') )) == null ? '' : __t) +
'</span></div>\n				';
 if (isHasAdditionalInfo) { ;
__p += '\n					<div class="b-branded-fares__row"></div>\n				';
 } ;
__p += '\n				<div class="b-branded-fares__row">\n                    <div class="btn b-branded-fares__select--offer--select-all js-select-all-offers mobile-hide" data-toggle="tooltip" data-html="true" data-placement="bottom" data-original-title="<div>' +
((__t = ( L10N.get('ticket.selectAll') )) == null ? '' : __t) +
'</div>"></div>\n                </div>\n			</div>\n			';

			const checkPaid = function(fare) {
				return Object.keys(fare).reduce(function(prev, cur) {
					if (fare[cur].paid) {
						prev.paid = true;
					}
					return prev;
				}, {paid: false});
			}
			;
__p += '\n			<div class="action-columns">\n				';
 _.each(flightFares, function (flightFare, i) {
					const additionalDetails = flightFare.servicesDetails.additionalDetails;
                    const showTravelPolicyBlock = hasTravelPolicy === true && (flightFare.travelPolicyCompliance != null && !_.isEmpty(flightFare.travelPolicyCompliance.rulesApplied)) || flightFare.isTripartiteContractDiscountApplied;
                    const showAvailableSeats = flightFare.availableSeats != null;
                    const showExtraInfoBlock = showAvailableSeats || showTravelPolicyBlock;
				;
__p += '\n					<div class="b-branded-fares__column">\n						<div class="b-branded-fares__row">\n							<div class="b-branded-fares__name">' +
((__t = ( flightFare.tariffs.length !== 0 ? flightFare.tariffs.join(', ') : '&nbsp;')) == null ? '' : __t) +
'</div>\n							<!-- 2 -->\n              ';
 if (!_.isEmpty(flightFare.servicesDetails.fareBasis) && !_.isEmpty(flightFare.fareBasis)) { ;
__p += '\n                ';
 flightFare.fareBasis.forEach(function(fareBasis, fareBasisIndex) { ;
__p += '\n                  ';
 if (Array.isArray(fareBasis)) { ;
__p += ' \n                    ';
 fareBasis.forEach(function(fb, fbIndex) { ;
__p += '\n                      ';
 var fareRule = flightFare.fareRules && flightFare.fareRules[fareBasisIndex] && flightFare.fareRules[fareBasisIndex][fbIndex] ? btoa(flightFare.fareRules[fareBasisIndex][fbIndex]) : "N"; ;
__p += '\n                      <div class="b-branded-fares__basis action-link b-fare-rules__rule-carrier-open" data-token="' +
((__t = ( flightFare.flightToken )) == null ? '' : __t) +
'" data-fare-rules="' +
((__t = ( fareRule )) == null ? '' : __t) +
'">\n                        ' +
((__t = ( fb )) == null ? '' : __t) +
'\n                      </div>\n                    ';
});
__p += '\n                  ';
 } ;
__p += '\n                  ';
}) ;
__p += '\n							';
 } ;
__p += '\n						</div>\n						<div class="b-branded-fares__row">\n							';
 var baggages = getLuggage('baggage', flightFare.servicesDetails); ;
__p += '\n							';
 if (!_.isEmpty(baggages)) { ;
__p += '\n								';
 _.each(baggages, function (fare) { ;
__p += '\n									<div class="b-ticket-luggage-val-block">\n										';
 var paidInfo = checkPaid(fare); ;
__p += '\n										<div class="b-ticket-luggage-title ' +
((__t = ( paidInfo.paid === true ? 'red' : '' )) == null ? '' : __t) +
'">\n											' +
((__t = ( L10N.get('flightlegs.' + (paidInfo.paid === true ? 'paid' : 'free')) )) == null ? '' : __t) +
':\n										</div>\n										';
 _.each(fare, function (p) { ;
__p += '\n											<div>\n												';
 if (p.passenger != null && _.size(fare) > 1) { ;
__p +=
((__t = ( p.passenger.caption )) == null ? '' : __t) +
':';
 } ;
__p += '\n												' +
((__t = ( p.description )) == null ? '' : __t) +
' ' +
((__t = ( (p.size != null) ? '(' + p.size + ')' : '' )) == null ? '' : __t) +
'\n											</div>\n										';
 }); ;
__p += '\n									</div>\n								';
 }); ;
__p += '\n							';
 } else { ;
__p += '\n								<span class="b-branded-fares__feature b-feature b-feature--type-negative">' +
((__t = ( L10N.get('Common.no') )) == null ? '' : __t) +
'</span>\n							';
 } ;
__p += '\n						</div>\n						<div class="b-branded-fares__row">\n							';
 var luggage = getLuggage('luggage', flightFare.servicesDetails); ;
__p += '\n							';
 if (!_.isEmpty(luggage)) { ;
__p += '\n								';
 _.each(luggage, function (fare, key) { ;
__p += '\n									';
 var paidInfo = checkPaid(fare); ;
__p += '\n									<div class="b-ticket-luggage-val-block">\n										<div class="b-ticket-luggage-title ' +
((__t = ( paidInfo.paid === true ? 'red' : '' )) == null ? '' : __t) +
'">\n											' +
((__t = ( L10N.get('flightlegs.' + ((paidInfo.paid === true) ? 'paid' : 'free')) )) == null ? '' : __t) +
':\n										</div>\n										';
 _.each(fare, function (p) { ;
__p += '\n											<div>\n												';
 if (p.passenger != null && _.size(fare) > 1) { ;
__p +=
((__t = ( p.passenger.caption )) == null ? '' : __t) +
':';
 } ;
__p += '\n												' +
((__t = ( p.description )) == null ? '' : __t) +
' ' +
((__t = ( (p.size != null) ? (p.description != 0) ? '(' + p.size + ')' : p.size : '' )) == null ? '' : __t) +
'\n											</div>\n										';
 }); ;
__p += '\n									</div>\n								';
 }); ;
__p += '\n							';
 } ;
__p += '\n						</div>\n						<div class="b-branded-fares__row">\n							' +
((__t = ( require('./b-ticket-refund-exchange.ejs')({ services: {exchange: flightFare.exchange }, flightToken: flightFare.flightToken, isList: true, airlineCode:flight.carrier.airlineCode}) )) == null ? '' : __t) +
'\n						</div>\n						<div class="b-branded-fares__row">\n							' +
((__t = ( require('./b-ticket-refund-exchange.ejs')({ services: {refund: flightFare.refund }, flightToken: flightFare.flightToken, isList: true, airlineCode:flight.carrier.airlineCode }) )) == null ? '' : __t) +
'\n						</div>\n						';
 if (additionalDetails) { ;
__p += '\n							<div class="b-branded-fares__row">\n								<a class="action-link b-branded-fares__additionalDetails" data-additional-details-id="' +
((__t = ( i )) == null ? '' : __t) +
'" >Дополнительные опции</a>\n							</div>\n						';
 } else if (isHasAdditionalInfo) { ;
__p += '\n							<div class="b-branded-fares__row"></div>\n						';
 } ;
__p += '\n						<div class="b-branded-fares__row">\n                            ';
 if (showExtraInfoBlock) { ;
__p += '\n                                <div class="b-branded-fares__extra-info-container">\n                                    ';
 if (showAvailableSeats) { ;
__p += '\n                                        ' +
((__t = ( require('./b-ticket-available-seats.ejs')({
                                            availableSeats: flightFare.availableSeats,
                                        }) )) == null ? '' : __t) +
'\n                                    ';
 } ;
__p += '\n                                    ';
 if (showTravelPolicyBlock) { ;
__p += '\n                                        <div class="b-branded-fares__travel-policy b-travel-policy">\n                                            ' +
((__t = ( require('./b-travel-policy.ejs')({
                                                flight: flightFare,
                                            }) )) == null ? '' : __t) +
'\n                                        </div>\n                                    ';
 } ;
__p += '\n                                </div>\n                            ';
 } ;
__p += '\n                            ';
 if (issueAllowed) { ;
__p += '\n                                <div class="btn btn-dark b-branded-fares__select" data-index="' +
((__t = ( flightFare._flightIndex )) == null ? '' : __t) +
'" data-toggle="tooltip" data-placement="bottom" data-html="true" data-original-title="' +
((__t = ( _.escape(require('./b-ticket-pricing.ejs')({ price: flightFare.price })) )) == null ? '' : __t) +
'">\n                                    ' +
((__t = ( Formatter.formatMoney(flightFare.totalPrice.amount, 0) )) == null ? '' : __t) +
'\n                                    ' +
((__t = ( Formatter.formatCurrency(flightFare.totalPrice.currencyCode) )) == null ? '' : __t) +
'\n                                </div>\n                            ';
 } ;
__p += '\n                            ';
 if (approvalAllowed) { ;
__p += '\n                                <div class="b-branded-fares__select-container">\n                                    ';
 if (!issueAllowed) { ;
__p += '\n                                        <div class="b-branded-fares__select-price" data-toggle="tooltip" data-placement="bottom" data-html="true" data-original-title="' +
((__t = ( _.escape(require('./b-ticket-pricing.ejs')({ price: flightFare.price })) )) == null ? '' : __t) +
'">\n                                            ' +
((__t = ( Formatter.formatMoney(flightFare.totalPrice.amount, 0) )) == null ? '' : __t) +
'\n                                            ' +
((__t = ( Formatter.formatCurrency(flightFare.totalPrice.currencyCode) )) == null ? '' : __t) +
'\n                                        </div>\n                                    ';
 } ;
__p += '\n                                    ';
 if (STATE.checkSiteType('B2B')) { ;
__p += '\n                                        <div class="btn b-branded-fares__select--offer" data-index="' +
((__t = ( flightFare._flightIndex )) == null ? '' : __t) +
'">' +
((__t = ( L10N.get('ticket.addToCoordination') )) == null ? '' : __t) +
'</div>\n                                    ';
 } ;
__p += '\n                                </div>\n                            ';
 } ;
__p += '\n                            ';
 if (!_.isEmpty(flightFare.vendorCommissionsData)) { ;
__p += '\n                                <div class="b-branded-fares__commission-section mobile-hide">\n                                    ';
_.each(_.keys(flightFare.vendorCommissionsData), function(key) {
                                        const passengerTypeDataList = flightFare.vendorCommissionsData[key];
                                    _.each(passengerTypeDataList, function(item) {
                                        const value = !_.isEmpty(item.amount) ? item.amount.amount + Formatter.formatCurrency(item.amount.currencyCode) : item.rate + '%';
                                        const commissionRowContent = L10N.get('pricing.commission') + ' ' + key + ': ' + value + ' (' + item.ruleNumber + ') ';
                                    ;
__p += '\n                                    <div class="b-branded-fares__commission-row">\n                                        <span>' +
((__t = ( commissionRowContent )) == null ? '' : __t) +
'</span>\n                                        <i class="g-icon-attention" data-toggle="tooltip" data-html="true" data-position="bottom" data-original-title="<div>' +
((__t = ( _.escape(item.contractCaption) )) == null ? '' : __t) +
'</div>"></i>\n                                    </div>\n                                    ';
 });
                                    });
__p += '\n                                </div>\n                            ';
 } ;
__p += '\n						</div>\n					</div>\n				';
 }); ;
__p += '\n			</div>\n		</div>\n	</div>\n';
 } ;
__p += '\n';

}
return __p
}